$hf-green: #1a8643;
$hf-brown: #764d24;

@import "_fonts.scss";
@import "_bootstrap.scss";  
@import "_bootstrap-responsive.scss"; 
@import "_settings.scss"; 
@import "_responsive.scss"; 
@import "_glyphicons.scss"; 
@import "_works.scss";  
@import "_shortcodes.scss"; 
@import "_faq.scss";
@import "_header.scss";
@import "_mbtr-badge.scss";

body {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
}

body.boxed {
  padding: 0 20px;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}
ul.normal-list {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 4px;
  li {
    list-style: disc;
  }
}

.control-label {
  font-weight: 700;
}

#container {
  background: #fff;
}

body.boxed #container {
  max-width: 980px;
  margin: auto;
  box-shadow: 0px 0px 20px 2px #d0d0d0;
}

/*-------------------------------------------------*/
/* =  FancyBox - v2.0.6
/*-------------------------------------------------*/
.fancybox-tmp iframe, .fancybox-tmp object {
  vertical-align: top;
  padding: 0;
  margin: 0;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  padding: 0;
  margin: 0;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
     -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  padding: 0;
  margin: 0;
  position: relative;
  outline: none;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 10px;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url('../images/fancybox/fancybox_sprite.png');
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8020;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('../images/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  background: transparent url('../images/fancybox/blank.gif'); /* helps IE */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 20px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 20px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  padding: 0;
  overflow: visible;
  visibility: hidden;
}

/* Overlay helper */
#fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: #000;
}

#fancybox-overlay.overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

/* Title helper */

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8030;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  margin-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, .8);
}

/* Extras */
.fancybox-type-inline .fancybox-skin {
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
}

.fancybox-type-inline .fancybox-outer {
  background: #fff !important;
}

.fancybox-type-inline .fancybox-inner {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.fancybox-type-inline .fancybox-next {
  right: 15px !important;
}

.fancybox-type-inline .fancybox-prev {
  left: 15px !important;
}

/*-------------------------------------------------*/
/* =  Social Icons
/*-------------------------------------------------*/
.social-icons {
  margin: 0;
  float: right;
}

.social-icons2 {
  margin: 0;
  float: left;
}

.social-icons li {
  display: inline;
  list-style: none;
  text-indent: -9999px;
  margin-left: 5px;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons2 li {
  display: inline;
  list-style: none;
  text-indent: -9999px;
  margin-right: 5px;
  float: right;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons li a {
  background-repeat: no-repeat;
  background-position: 0 0;
  display: block;
  height: 28px;
  width: 28px;
}

.social-icons2 li a {
  background-repeat: no-repeat;
  background-position: 0 -38px;
  display: block;
  height: 28px;
  width: 28px;
}

.social-icons li:hover, .social-icons2 li:hover {background-position: 0 -38px;}

/* Social Widget Icons */
#social a {
  width: 28px;
  height: 28px;
  margin: 0 6px 6px 0;
  display: block;
  float: left;
  text-indent: -9999px;
  background-position: 0 -38px;
}

#social a img{
  border: none;
}

/* Icon List */
.amazon {
  background: url('../images/icons/social/amazon.png') no-repeat;
}
.behance {
  background: url('../images/icons/social/behance.png') no-repeat;
}
.blogger {
  background: url('../imgaes/icons/social/blogger.png') no-repeat;}
.deviantart{
  background: url('../imgaes/icons/social/deviantart.png') no-repeat;}
.dribbble {
  background: url('../images/icons/social/dribbble.png') no-repeat;}
.dropbox {
  background: url('../images/icons/social/dropbox.png') no-repeat;}
.evernote {
  background: url('../images/icons/social/evernote.png') no-repeat;}
.facebook {
  background: url('../images/icons/social/facebook.png') no-repeat;}
.forrst {
  background: url('../images/icons/social/forrst.png') no-repeat;}
.github {
  background: url('../images/icons/social/github.png') no-repeat;}
.googleplus {
  background: url('../images/icons/social/googleplus.png') no-repeat;}
.jolicloud {
  background: url('../images/icons/social/jolicloud.png') no-repeat;}
.last-fm {
  background: url('../images/icons/social/last-fm.png') no-repeat;
}
.linkedin {
  background: url('../images/icons/social/linkedin.png') no-repeat;
}
.picasa {
  background: url('../images/icons/social/picasa.png') no-repeat;
}
.pintrest {
  background: url('../images/icons/social/pintrest.png') no-repeat;
}
.rss {
  background: url('../images/icons/social/rss.png') no-repeat;
}
.skype {
  background: url('../images/icons/social/skype.png') no-repeat;
}
.spotify {
  background: url('../images/icons/social/spotify.png') no-repeat;
}
.stumbleupon {
  background: url('../images/icons/social/stumbleupon.png') no-repeat;
}
.tumblr {
  background: url('../images/icons/social/tumblr.png') no-repeat;
}
.twitter {
  background: url('../images/icons/social/twitter.png') no-repeat;
}
.vimeo {
  background: url('../images/icons/social/vimeo.png') no-repeat;
}
.wordpress {
  background: url('../images/icons/social/wordpress.png') no-repeat;
}
.xing {
  background: url('../images/icons/social/xing.png') no-repeat;
}
.yahoo {
  background: url('../images/icons/social/yahoo.png') no-repeat;
}
.youtube {
  background: url('../images/icons/social/youtube.png') no-repeat;
}


/*-------------------------------------------------*/
/* =  Mobile Menu - selectbox
/*-------------------------------------------------*/
.sbHolder {
  background: #0ad1e5;
  /*border: 1px solid #e4e4e4;*/
  height: 44px;
  line-height: 44px;
  position: relative;
  margin: auto;
  max-width: 100%;
  z-index: 99;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  display: none;
}

.sbSelector{
  display: block;
  height: 44px;
  left: 0;
  line-height: 44px;
  outline: none;
  overflow: hidden;
  position: absolute;
  text-indent: 10px;
  top: 0;
  width: 100%;
}
.sbSelector:link, .sbSelector:visited, .sbSelector:hover{
  color: #fff;
  outline: none;
  text-decoration: none;
}
.sbToggle{
  background: url('../images/select-icons.png') 0 -116px no-repeat;
  display: block;
  height: 30px;
  outline: none;
  position: absolute;
  right: 5px;
  top: 7px;
  width: 30px;

  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}
.sbToggle:hover {
  opacity: 0.8;
  background-position: 0 -167px;
}
.sbToggleOpen {
  background-position: 0 -16px;
}
.sbToggleOpen:hover {
  opacity: 0.8;
  background-position: 0 -166px;
}
.sbOptions {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 1;
  overflow-y: auto;
}
.sbOptions li {
  padding: 0 5px;
  line-height: 10px;
}
.sbOptions a {
  border-bottom: dotted 1px #e4e4e4;
  display: block;
  outline: none;
  padding: 0 0 0 3px;
}
.sbOptions a:link, .sbOptions a:visited {
  color: #646464;
  text-decoration: none;
  padding-left: 20px !important;
}
.sbOptions > li:first-child > a {
  padding-left: 10px;
}
.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus{
  color: #d91c03;
}
.sbOptions li.last a{
  border-bottom: none;
}
.sbOptions .sbGroup{
  border-bottom: dotted 1px #e4e4e4;
  color: #646464;
  display: block;
  font-weight: bold;
  line-height: 40px;
  font-family: 'Economica', 'Lucida Sans Unicode', Arial;
  font-weight: normal;
  font-size: 18px;
}
.sbOptions .sbSub{
  padding-left: 17px !important;
}



/*-------------------------------------------------*/
/* =  Slider
/*-------------------------------------------------*/

section.slider {
  background: #f9f9f9;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
  margin-bottom: 40px;
}
section.slider div {
  margin: auto;
}

.slides li {
  width: 100%;
  overflow: hidden;
}

.slides li img {
  max-width: 100%;
  max-height: 100%;
}
.slides .flex-caption { 
  position:absolute; 
  color: #ffffff; 
  font-size: 13px; 
  font-style: italic; 
  font-family: georgia; 
  bottom: 0px; 
  padding:9px 12px 10px 12px; 
  background: rgba(0,0,0,0.5);
  line-height: 18px;
}

#slider1 .slides .flex-caption,
#slider2 .slides .flex-caption { 
  right: 76px;
  margin: 0;
}

#slider2 {
  padding: 0 !important;
  bottom: none !important;
}

/*-------------------------------------------------*/
/* =  Content - home
/*-------------------------------------------------*/
#inner-content {
  margin: auto !important;
  padding:0 20px;
}

#inner-content article {
  position: relative;
  min-height: 90px;
  margin-bottom: 40px;
  margin-left: 0;
  clear: both;
}

.new-location-message {
  border-left: 5px solid $hf-green;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 12px 40px;

  h2 {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
  }
}

.row-fluid h1 {
  color: #404040;
  font-size: 14px;
  font-weight: bold;
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  padding: 0;
  margin-bottom: 15px;
  overflow: hidden;
  line-height: 35px;
  border-bottom: 3px solid $hf-green;
    display: inline-block;
}

.texter{
  margin-bottom: 30px;
}

.texter2{
  margin-bottom: 55px;
}

.callout{border-left:5px solid lighten($hf-green, 10%);background-color:#3a3a3a;clear:both;padding:12px 40px;color:#fff;overflow:hidden;position:relative}
.callout h2{color:#fff;text-transform:uppercase;margin:0 125px 0 0;font-size:20px;}
.callout h2 small{display:block;font-size:14px;line-height:1.7;text-transform:none}
.callout .link{position:absolute;right:0;top:0;width:75px;background-color:#0ad1e5;color:#fff;height:100%;text-align:center}
.callout .link:before{content:'';border:500px solid transparent;border-right-color:#0ad1e5;position:absolute;right:100%}
.callout .link [class*="ico-"]{position:absolute;top:50%;left:50%;margin-top:-25px;margin-left:-25px}

#page {
  margin-bottom: 52px;
  min-width: 900px;
}

#page-port {
  margin-bottom: 32px;
}


#inner-content div#articles {
  clear: both;
  margin-bottom: 40px;
}

#inner-content div.span3:first-child {
  margin-left: 0;
}

#inner-content div.span4 h2 {
  line-height: 0;
  margin-bottom: 20px;

}

#inner-content div.span4 h2 img {
  margin-right: 12px;
}

#inner-content div.span4 h2 span {
  font-size: 17px;
  font-weight: bold;
  color: #0ad1e5;
}
#inner-content #articles p {
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 16px;
}

#articles2 div.span3 a, 
#articles div.span4 a {
  position: relative;
  color: $hf-green;
  font-size: 11px;
  display: inline-block;
  border: 1px solid #dbdbdb;
  border-right: none;
  margin-bottom: 30px;
  padding: 7px;
  padding-right: 27px;
  line-height: 10px;
}

#articles2 div.span3 a:hover span, 
#articles div.span4 a:hover span {
  background: #868686 url('../images/readmore-arrow.png') center center no-repeat;
}

#articles2 div.span3 a span, 
#articles div.span4 a span {
  position: absolute;
  width:20px;
  height: 26px;
  right: 0;
  top:-1px;
  background: #0ad1e5 url('../images/readmore-arrow.png') center center no-repeat;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;

}

.heading h1{
  border:0;
  font-size: 16px;
  margin:0;
  line-height:35px;
}

.heading h2{
  border:0;
  font-size: 15px;
  margin:0;
  line-height:35px;
}

.heading h3{
  border:0;
  font-size: 14px;
  margin:0;
  line-height:35px;
}

.heading h4{
  border:0;
  font-size: 13px;
  margin:0;
  line-height:35px;
}

.heading h5{
  border:0;
  font-size: 12px;
  margin:0;
  line-height:35px;
}

.heading h6{
  border:0;
  font-size: 11px;
  margin:0;
  line-height:35px;
}

blockquote {
    border-left: 5px solid #0ad1e5;
    margin: 0;
    padding: 0 0 0 15px;
}

blockquote p {
  font-size:12px;
}

blockquote.pull-right {
    border-left: 0 none;
    border-right: 5px solid #0ad1e5;
    float: right;
    margin: 0;
    padding: 0 15px 0 0;
}

.highlight {
    background: none repeat scroll 0 0 #0ad1e5;
    display: inline;
    padding: 2px 3px;
    width: auto;
}

/*-------------------------------------------------*/
/* =  Recent
/*-------------------------------------------------*/

#recent {
  margin-bottom: 40px !important;
  height: 100%;
}

#recent h1 {
  color: #404040;
  font-size: 16px;
  margin: 0;
  margin-bottom: 18px;
  padding: 0;
  font-weight: bold;
  font-family: Calibri;
}

#recent .left {
  margin-bottom: 20px;
  margin-right: 20px;
  float: left;
  width: 23.4%;
  height: auto;
}

#recent .left:last-child {
  margin-right: 0px;
}

#recent .left:hover img {
  text-decoration: none;
  filter:grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

#recent .left img {
  width:100%;
  height: auto;
  filter:grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

#recent .left p {
  width: 100%;
  height: auto;
  z-index: 10;
  position: relative;
  left: 0;
  color: #fff;
  font-size: 13px;
  background: #515151;
  padding: 15px 0;
  text-align: center;
  margin: 0;
  transition: all 0.4s ease-in-out ;
    -moz-transition: all 0.4s ease-in-out ;
    -webkit-transition: all 0.4s ease-in-out ;
    -o-transition: all 0.4s ease-in-out;
}

#recent .left{
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#recent .left:hover p{
  background: #0ad1e5;
}

#up,
#down {
  display: inline-block;
  position: absolute;
  width:80px;
  height: 80px;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#up {
  top: -40px;
  left: -40px;
  background: url('../images/link.png') center center;
}

#down {
  right: -40px;
  bottom: 0px;
  background: url('../images/zoom+.png') center center;
}

#up:hover,
#down:hover {
  opacity: 0.8;
}

/*-------------------------------------------------*/
/* =  our clients
/*-------------------------------------------------*/
#our_clients .images {
  margin-bottom: 59px;

}

.images span {
  float: left;
  width: 19.8%;
  height: auto;
  border: 1px solid #e1e1e1;
  border-right: none;
  text-align:center;
}

.images span:last-child {
  border-right:1px solid #e1e1e1;
}

.images span:hover {
  opacity:0.7;
}


/*-------------------------------------------------*/
/* =  Footer
/*-------------------------------------------------*/

footer {
  border-top: 3px solid lighten($hf-green, 10%);
  margin-top: -7px;
  background: #3a3a3a url('../images/bg3.png') repeat 0 0;
}

.innerfooter {
  margin: auto;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 10px;
}

.innerfooter article {
  margin-bottom: 30px;
  margin-left: 19px;
}

.innerfooter article:first-child{
  margin-left: 0;
}

.innerfooter article h2 {
  font-size: 15px;
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: lighten($hf-green, 5%);
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-width: 5px;
  text-shadow: 1px 0px 1px #000;
  font-weight: bold;
}

article.about p {
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #c2c2c2;
  line-height: 18px;
  text-shadow: 1px 0px 1px #000;
}

article.about a {
  color:#fff;
}

article.about a:hover {
  color:#0ad1e5;
}

.footerbottom {
  max-width: 940px;
  margin: auto;
  border-top: 1px solid #c2c2c2;  
  text-align:center;
}

.footerbottom p {
  color: #c2c2c2;
  margin: 15px 0 15px;
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif; font-size:11px;
}

.block_footer_links {}
.block_footer_links li {padding:8px 0px 8px; display:block; overflow:hidden;}
.block_footer_links li:first-child {padding-top:0px;}
.block_footer_links li a {font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif; font-size:12px; color:#fff; line-height:16px;}
.block_footer_links li a:hover {color:#0ad1e5; text-decoration:none;}
.ulink1 {float:left;margin-right:50px;}
.ulink2 {float:left;}

/*-------------------------------------------------*/
/* =  Content - home
/*-------------------------------------------------*/

.main1 {
  margin-bottom: 10px;
}
.main1 div.span4 {
  margin-bottom: 30px;
}


.main1 .services h2 {
  padding: 18px 0 18px 16px;
  font-size: 13px;
  color: #2f2f2f;
  border-top: 1px solid #e3e3e3; 
  cursor: pointer;
}

.services .active {
  border-bottom: 1px solid #e3e3e3;
  border-left: 3px solid #0ad1e5;
  color: #0ad1e5 !important;
}

.main1 .services h2 img {
  margin-right: 12px;
}

.main1 .services p {
  font-size: 13px;
  color: #535353;
  line-height: 20px;
  margin: 5px 0;
}

.hide { display: none;}

.main1 .testimonials blockquote {
  font-size: 13px;
  color: #4b4b4b;
  margin: 0;
  font-family: Georgia;
  line-height: 20px;
  font-style: italic;
  background: #f7f7f7;
  padding: 16px;
  float: left;
}

.main1 .testimonials blockquote span {
  float: right;
  font-style: normal;
  font-weight: bold;
}

.testimonials li{ display:none;}
.testimonials li:first-child{ display:block;}
.testimonials ul{ list-style:none;}

#inner-content #articles div.span3 > img {
  width: 220px;
  margin-bottom: 15px;
}

.articles img,
.articles1 img {
  width: 32px !important;
  height: 32px !important;
}

.articles h2,
.articles1 h2 {
  margin-bottom: 10px !important;
  line-height: 20px !important;
}

.articles div.span4 {
  text-align: center;
  border: 1px solid #dbdbdb;
  padding-top: 15px;
  margin-bottom: 0px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;  
}

.articles div.span4:hover {
  border: 1px solid #0ad1e5;
}

.articles div.span4 p{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

/*our team*/

#our-team div.span3:first-child{
  margin-left: 0!important;
}

#our-team {

}

#our-team div.span3 {
  margin-bottom: 40px;
}

#member1{
  margin-left: 0 !important;
}

#our-team a {
  display: inline-block;
  position: relative;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
  transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
}

#our-team a:hover:after {
  content: '';
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #0ad1e5;

}

#our-team a img {
  border: 1px solid #cdcdcd;
  width: 218px;
}

#our-team h4 {
  font-size: 14px;
  font-weight: bold;
  color: #6a6a6a;
  margin-bottom: 3px;
}

#our-team span {
  color: #0ad1e5;
}

#our-team p {
  color: #535353;
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-top: 10px;
}

/*-------------------------------------------------*/
/* =  Blog
/*-------------------------------------------------*/

.cont-head {
  background: #3a3a3a url('../images/bg.png') repeat 0 0;
  height: 64px;
  margin-bottom: 25px;
}

.cont-text h1 {
  float: left;
  font-size: 16px;
  color: #fff;
  text-transform: normal;
  margin-top: 25px;
}

.cont-text p {
  float: right;
  font-size: 11px;
  color: #717171;
  margin-top: 20px;
}

.cont-text p a {
  display: inline-block;
  color: #0ad1e5;
  margin-left: 12px;
}

.cont-text p a span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border: 1px solid #e7e7e7;
  background: #fff url('../images/Blog-head-arrow.png') center center no-repeat;
  margin-right: 12px;
  margin-bottom: -9px;
}

#inner-content .span12 {
  margin-left: 0;
}

.blog-post,
.single-post {
  margin-bottom: 37px;
}

.blog-post img,
.single-post img {
  margin-bottom: 20px;
}

.blog-post .frame-scale-with-grid {
  margin-bottom: 20px;
}


.blog-post p {
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 15px;
}

.post-title {
  margin-bottom: 20px;
}

.post-title h1 {
  color: #4f4f4f;
  font-size: 14px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
}

.post-title h1 a {
  color: #4f4f4f;
  transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
}

.post-title h1 a:hover {
  color: #0ad1e5;
}

.post-title p span {
  display: inline-block;
  font-size: 11px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #4F4F4F;
  margin-right: 10px;
}

.post-title p span a {
  color: #0ad1e5;
}

#read-more {
  position: relative;
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 11px;
  display: inline-block;
  background: #0ad1e5;
  border-right: none;
  padding: 10px;
  line-height: 10px;
}


.pagination {
  margin:0 0 52px;
  padding: 0;
}
.pagination a {
  display: inline-block;
  background: #0ad1e5;
  color: #fff;
  padding: 8px 12px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  margin-right: 3px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

.pagination a:hover {
  background: #646464;
}

.pagination a#prev,
.pagination a#next {
  color: #fff;
  background: #0ad1e5;
  transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  padding: 8px 10px;
}

.pagination a#prev:hover,
.pagination a#next:hover {
  background: #646464;
}

/*-------------------------------------------------*/
/* =  Blog with 2,3,4 colums
/*-------------------------------------------------*/

.blog .span6:nth-child(2n+1){
  margin-left: 0;
}

.blog .span4:nth-child(3n+1){
  margin-left: 0;
}

.blog .span4 .post-title p {
  clear: both;
}

.blog .span3:nth-child(4n+1){
  margin-left: 0;
}

.blog .span3 img {
  margin-bottom: 15px;
}

.blog .span3 .post-title h1 {
  margin-bottom: 20px;
}

.blog .span3 .post-title,
.blog .span4 .post-title {
  margin-bottom: 10px;
}

.blog-vers2 .post-title {
  margin-bottom: 20px;
}

.blog-vers2 .span6 p {
  clear: both;
}

/*-------------------------------------------------*/
/* =  portfolio
/*-------------------------------------------------*/
#portfolio-filter {
  margin-bottom: 17px;
}

#portfolio-filter li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

#portfolio-filter li a {
  display: inline-block;
  padding: 7px 10px;
  background: #0ad1e5;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 13px;
  color: #fff;
  transition: background 0.4s linear ;
    -moz-transition: background 0.4s linear ;
    -webkit-transition: background 0.4s linear ;
    -o-transition: background 0.4s linear;
}

#portfolio-filter li a:hover {
  background: #646464;
}

#portfolio-filter li a.active4 {
  background: #646464;
}

ul.portfolio {
  display:none;
}
.portfolio .span6:nth-child(2n+1) {
  margin-left: 0;
}

.portfolio .span4:nth-child(3n+1) {
  margin-left: 0;
}

.portfolio-post {
  margin-bottom: 20px;
}

.portfolio-post-end {
  margin-bottom: 0px;
}

.pdesc{
  font-weight: bold;
}

.col4{
  position: absolute;
  left:0;
  top:0;
  width: 220px;
  margin-right: 20px;
}

.col4:nth-child(4n) {
  margin-right: 0;
}

.col3 {
  width: 300px;
  margin-right: 20px;
  display: inline;
}

.col2 {
  width: 460px;
  margin-right: 20px;
  display: inline-block;
}

.col2:nth-child(2n) {
  margin-right: 0;
}

ul.portfolio {
  width: 103%;
  margin-bottom: 45px;
}

/*-------------------------------------------------------*/
/* Isotope filtering
/*-------------------------------------------------------*/
.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
.isotope, .isotope .isotope-item {/* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property:-moz-transform, opacity;
  transition-property:transform, opacity;
}

/*-------------------------------------------------------*/
/* About
/*-------------------------------------------------------*/

#about .span5 {
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 25px;
}

#about .span5 img {
  width: 100%;
}

#about .span7 h1 {
  margin-bottom: 8px;
}

#about .span7 {
  margin-bottom: 25px;
}

#about .span7 p {
  font-size: 13px;
  color: #7d7d7d;
  line-height: 20px;
}

#about .span6 {
  margin-bottom: 25px;
}

#about .images ul li {
  float: left;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-right: none;
  border-bottom: none;
  width: 32.8%;
}

#about .images ul li:nth-child(3n) {
  border-right: 1px solid #e1e1e1;
}

#about .images ul li:last-child {
  border-right: 1px solid #e1e1e1;
}

#about .images ul li:nth-last-child(-n+3) {
  border-bottom: 1px solid #e1e1e1;
}

.skills h4{
  font-size:12px;
  margin-bottom:5px;
    font-weight: 600;
}

/*-------------------------------------------------------*/
/* sidebar
/*-------------------------------------------------------*/

#sidebar ul li.widget {
  background: url('../images/bg-white.png');
  display: block;
  padding: 10px;
  margin-bottom: 22px;
}

ul.tab-links li {
  float: left;
  background: #f8f8f8;
  padding: 12px 0;
  width: 32.8%;
  text-align: center;
  border: 1px solid #ebebeb;
  border-left: none;
}

ul.tab-links li:first-child {
  border-left: 1px solid #ebebeb;
}

ul.tab-links li a {
  color: #5a5a5a;
}

ul.tab-content li {
  background: #fff;
  display: none;
}

ul.tab-links li.active {
  background: #fff;
  border-bottom: none;
  border-top: 2px solid #0ad1e5;
}

ul.tab-links li.active a {
  color: #0ad1e5;
}

ul.tab-content li:first-child {
  display: block;
}

ul.tab-content li div {
  overflow: hidden;
  padding: 15px 10px;
  border: 1px solid #ebebeb;
  border-top: none;
}

ul.tab-content li div img {
  float: left;
  margin-right: 12px;
}

ul.tab-content li div h3 {
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 7px;
  max-height: 36px;
  overflow: hidden;
}

ul.tab-content li div h3 a{
  color: #828282;
}

ul.tab-content li div span {
  font-size: 10px;
  color: #999999;
}

ul.tab-content li div p {
  font-size: 11px;
  color: #999999;
  line-height: 18px;
}

ul.tab-content li div span img {
  margin-right: 8px;
}

.widget h2 {
  font-size: 13px;
  color: #0ad1e5;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
}

.cat-widget div {
  display:inline-block;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-bottom: none;
  margin: 0;
  width: 99%;
  max-height: 320px;
}


.cat-widget div h3 {
  font-size: 13px;
  color: #2f2f2f;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
}

.cat-widget div img {
  margin-right: 12px;
  margin-top: -5px;
}

.cat-widget div p {
  font-size: 13px;
  color: #535353;
  line-height: 20px;
  padding: 0 17px 15px;
  border-bottom: 1px solid #ebebeb;
}

div.cat-item h3 a {
  color: #404040;
}

div.cat-item h3 a:hover {
  color: #0ad1e5;
}


li.widget p {
  font-size: 12px;
  line-height: 20px;
}

li.tag-cloud-widget a {
  display: inline-block;
  color: #fff;
  padding: 5px;
  background: #666666;
  margin: 0 4px 6px 0;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

li.tag-cloud-widget a:hover {
  background: #0ad1e5;
}

li.subscribe-widget input[type="text"] {
  float: left;
  width: 90%;
  height: 20px;
  font-size: 11px;
  color: #6a6a6a;
  border: 1px solid #d6d6d6;
}

li.subscribe-widget input[type="submit"] {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #0ad1e5;
  width: 25%;
  padding: 0px 0px;
  height: 30px;
  border: none;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

li.subscribe-widget input[type="submit"]:hover {
  opacity: 0.9;
}

li.flicker-widget a {
  display: inline-block;
  width: 30%;
  height: 30%;
  margin-right: 3.4%;
  margin-bottom: 3.4%;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

li.flicker-widget a:hover {
  opacity: 0.8;
}

li.flicker-widget a:nth-child(3n+1) {
  margin-right: 0;
}

li.flicker-widget a:nth-last-child(-n+3) { 
  margin-bottom: 0;
}

li.flicker-widget a img {
  width: 100%;
}

li.tag-widget p {
  color: #3c3c3c;
  font-size: 13px;
  line-height: 16px;
  width: 90%;
  float: right;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 15px !important;
}

li.tag-widget div p img {
  margin-left: -30px;
  margin-right: 5px;
}

li.tag-widget div p a {
  color: #357d9d;
}

li.tag-widget div span {
  color: #676767;
}

/*-------------------------------------------------------*/
/* Singlepost
/*-------------------------------------------------------*/

.single-post p {
  line-height: 20px;
  margin-bottom: 18px;
}

.single-post blockquote {
  color: #828282;
  font-size: 16px;
  font-style: italic;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  line-height: 25px;
  padding: 0 24px;
  border-left: 2px solid #619d21;
  border-right: 2px solid #619d21;
  margin-bottom: 20px;
}

.social-media ul li {
  display: inline-block;
  width: 80px;
  margin-right: 5px;
}

#author,
#comment-leave {
  margin-bottom: 57px;
}

#comments {
  margin-top: -18px;
}

#author h1,
#comments h1,
#comment-leave h1 {
  font-weight: bold;
}

ul.member-post li {
  float: left;
  background: #f8f8f8;
  text-align: center;
  border: 1px solid #ebebeb;
  border-left: none;
  border-bottom: none;
  margin-bottom: -1px;
  z-index: 100;
}

ul.member-post li:first-child {
  border-left: 1px solid #ebebeb;
}

ul.member-post li a {
  display: inline-block;
  color: #373737;
  height: 12px;
  padding: 12px 15px;
}

ul.member-post li a img {
  margin-right: 5px;
}

ul.member-post li.active {
  background: #fff;
  border-bottom: 1px #fff;
  border-top: 2px solid #0ad1e5;
}

ul.member-data li {
  min-height: 100px;
  display: none;
}

ul.member-data li:first-child {
  display: block;
}

ul.member-data li {
  padding: 15px;
  border: 1px solid #ebebeb;
}

ul.member-data li img {
  float: left;
  margin-right: 12px;
}

ul.member-data li h4 {
  color: #101110;
  font-size: 14px;
  margin-bottom: 5px;
}

ul.member-data li a {
  display: inline-block;
  color: #0ad1e5;
  font-size: 11px;
  font-style: italic;
  font-family: georgia;
  margin-bottom: 7px;
}

ul.member-data li p,
#comments li div p {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}

#comments ul li {
  clear: both;
  margin-bottom: 50px;
}

#comments ul li.depth1 ul {
  margin-top: 50px;
}

#comments ul li.depth1 ul li {
  margin-left: 12%;
  margin-bottom: 50px;
}

#comments ul li img {
  float: left;
  overflow: hidden;
}

#comments ul li div {
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  padding: 0 20px 20px;
  margin-top: 20px;
  margin-left: 70px;
}

#comments li div h5 {
  color: #101110;
  font-size: 13px;
  margin-bottom: 6px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}

#comments li div span {
  display: inline-block;
  color: #7d7d7d;
  font-size: 11px;
  margin-bottom: 7px;
}

#comments li div a {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 11px;
  padding: 5px 7px;
  background: #696969;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

#comments li div a:hover {
  background: #0ad1e5; 
}

/*-------------------------------------------------------*/
/* contact
/*-------------------------------------------------------*/
#contact {

}

#contact h1 {
  margin-bottom: 10px;
}

#contact p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
}

div#map {
  height: 220px;
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 30px;
}

#contact .span8 h1,
#contact .span4 h1 {

}

#contact-form {
  margin-bottom: 58px;
  display: inline-block;
}

#contact input[type="text"],
#contact input[type="email"],
#contact-form2 input[type="text"] {
  float: left;
  border: 1px solid #d8d8d8;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size:12px;
  color: #878787;
  padding:12px 10px;
  margin-bottom:10px;
  margin-right: 10px;
  display:block;
  height:20px;
  width: 28.1%;
  background:rgba(255, 255, 255, 0.2);
}

#contact label,
#contact-form2 label {
  float: left;
  min-width: 36px;
  height: 36px;
  cursor: pointer;
}

#contact textarea,
#contact-form2 textarea {
  clear: both;
  border: 1px solid #d8d8d8;
  font-size:12px;
  color: #878787;
  width: 96%;
  height: 140px;

}

#contact-form,
#contact-form2 {
  width: 100%;
}

#contact input[type="submit"],
#contact-form2 input[type="submit"] {
  float: left;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-right: 10px;
  font-size: 12px;
  background: #0ad1e5;
  color: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
  padding: 10px 25px 8px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}
#contact input[type="reset"],
#contact-form2 input[type="reset"] {
  float: left;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-right: 10px;
  font-size: 12px;
  background: #0ad1e5;
  color: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
  padding: 10px 25px 8px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}


#contact input[type="submit"]:hover,
#contact-form2 input[type="submit"]:hover {
  background: #646464;
}

#contact input[type="reset"]:hover,
#contact-form2 input[type="reset"]:hover {
  background: #646464;
}

#contact .message,
#contact-form2 .message {
  float: left;
  height:30px;
  font-size:12px;
}

#contact .message.error,
#contact-form2 .message.error {
  color:#eb2c06;
}

#contact .message.success,
#contact-form2 .message.success {
  color:#0ad1e5;
}

#contact .span4 p {
  margin-bottom: 15px;
}

#contact .span4 div {
  margin-bottom: 12px;
}

#contact .span4 div p {
  font-size: 12px;
  margin-bottom: 8px;
}

#contact .span4 div img { 
  margin-right: 10px;
}

#contact .span4 ul {
  margin-bottom: 40px;
}

#contact .span4 ul li {
  float: left;
  margin-right: 8px;
}

#contact .span4 ul li a {
  display: inline-block;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

#contact .span4 ul li a:hover { 
  opacity: 0.8;
}

/*-------------------------------------------------------*/
/* shortcodes
/*-------------------------------------------------------*/

#shortcodes > h1 {
  font-size: 18px;
  color: #0ad1e5;
  padding-bottom: 14px !important;
  margin-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.headings h1,
.headings h2,
.headings h3,
.headings h4, 
.headings h5, 
.headings h6,
#shortcodes h5 {
  color: #404040;
  font-family: Calibri;
  margin-bottom: 10px;
}

.headings h1 {
  font-size: 30px;
}

.headings h2 {
  font-size: 26px;
}

.headings h3 {
  font-size: 22px;
}

.headings h4 {
  font-size: 18px;
}

.headings h5,
#shortcodes h5 {
  font-size: 16px;
}

.headings h6 {
  font-size: 12px;
}

.headings p,
.text p,
.left-img,
.right-img,
.dropcaps p {
  font-size: 12px;
  line-height: 20px;
}

.headings,
.text {
  margin-bottom: 50px;
}

.left-img {
  margin-bottom: 20px;
}

.right-img {
  margin-bottom: 70px;
}

.left-img img,
.right-img img {
  width: 180px;
  height: 132px;
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
}
#shortcodes
.left-img img {
  float: left;
  margin-right: 15px;
}

.right-img img {
  float: right;
  margin-left: 15px;
}

#shortcodes .icons,
#shortcodes .buttons {
  margin-bottom: 70px;
}

#shortcodes .icons p {
  margin-bottom: 15px;
  color: #7d7d7d;
  font-size: 12px;
}

#shortcodes .icons img {
  margin-right: 12px;
}

.buttons a {
  display: inline-block;
  width:68px;
  height: 22px;
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: #343434;
  margin-right: 4px;
  margin-bottom: 10px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.buttons a:hover {
  opacity: 0.8;
}

#btn1 {
  background: #fff;
  color: #5b5b5b;
  border:1px solid #c1c1c1;
}

#btn2 {
  background: #5d8aa8;
  border:1px solid #5d8aa8;
}


#btn3 {
  background: #b7b7b7;
  color: #323232;
  border:1px solid #b7b7b7;
}

#btn4 {
  background: #faebd7;
  color: #5b5b5b;
  border:1px solid #c1c1c1;
}

#btn5 {
  background: #7fffd4;
  color: #5b5b5b;
  border:1px solid #7fffd4;
}

#btn6 {
  background: #838b8b;
  border:1px solid #838b8b;
}

#btn7 {
  background: #8b7d6b;
  border:1px solid #8b7d6b;
}

#btn8 {
  background: #3099de;
  border:1px solid #3099de;
}

#btn9 {
  background: #a52a2a;
  border:1px solid #a52a2a;
}

#btn10 {
  background: #deb887;
  color: #333333;
  border:1px solid #deb887;
}

#btn11 {
  background: #5f9ea0;
  border:1px solid #5f9ea0;
}

#btn12 {
  background: #66cd00;
  border:1px solid #66cd00;
}

#btn13 {
  background: #d2691e;
  border:1px solid #d2691e;
}

#btn14 {
  background: #ee6a50;
  border:1px solid #ee6a50;
}

#btn15 {
  background: #8b8878;
  border:1px solid #8b8878;
}

#btn16 {
  background: #cdc8b1;
  color: #323232;
  border:1px solid #c1c1c1;
}

#btn17 {
  background: #00cdcd;
  border:1px solid #c1c1c1;
}

#btn18 {
  background: #ffb90f;
  border:1px solid #ffb90f;
}

#btn19 {
  background: #006400;
  border:1px solid #006400;
}

#btn20 {
  background: #bdb76b;
  border:1px solid #bdb76b;
}

#btn21 {
  background: #556b2f;
  border:1px solid #556b2f;
}

#btn22 {
  background: #7fffd4;
  color: #5b5b5b;
  border:1px solid #c1c1c1;
}

#btn23 {
  background: #a2cd5a;
  border:1px solid #a2cd5a;
}

#btn24 {
  background: #ff8c00;
  border:1px solid #ff8c00;
}

#btn25 {
  background: #bf3eff;
  border:1px solid #bf3eff;
}

#btn26 {
  background: #e9967a;
  border:1px solid #e9967a;
}

#btn27 {
  background: #8fbc8f;
  border:1px solid #8fbc8f;
}

#btn28 {
  background: #483d8b;
  border:1px solid #483d8b;
}

#btn29 {
  background: #2f4f4f;
  border:1px solid #2f4f4f;
}

#btn30 {
  background: #97ffff;
  color: #404140;
  border:1px solid #97ffff;
}

#btn31 {
  background: #ff1493;
  border:1px solid #ff1493;
}

#btn32 {
  background: #00bfff;
  border:1px solid #00bfff;
}

#btn33 {
  background: #696969;
  border:1px solid #696969;
}

#btn34 {
  background: #ee2c2c;
  border:1px solid #ee2c2c;
}

#btn35 {
  background: #228b22;
  border:1px solid #228b22;
}

#btn36 {
  background: #dcdcdc;
  color: #3e3f3e;
  border:1px solid #dcdcdc;
}

#btn37 {
  background: #ffd700;
  color: #3e3f3e;
  border:1px solid #ffd700;
}

#btn38 {
  background: #8b7500;
  border:1px solid #8b7500;
}

#btn39 {
  background: #030303;
  border:1px solid #030303;
}

#btn40 {
  background: #ff69b4;
  border:1px solid #ff69b4;
}

#btn41 {
  background: #8b3a3a;
  border:1px solid #8b3a3a;
}

#btn42 {
  background: #7cfc00;
  color: #323332;
  border:1px solid #7cfc00;
}

#btn43 {
  background: #eedc82;
  color: #393a39;
  border:1px solid #eedc82;
}

#btn44 {
  background: #ff0f0f;
  border:1px solid #ff0f0f;
}

#btn45 {
  background: #ffb6c1;
  color: #454645;
  border:1px solid #ffb6c1;
}

#btn46 {
  background: #8470ff;
  border:1px solid #8470ff;
}

#btn47 {
  background: #b03060;
  border:1px solid #b03060;
}

#btn48 {
  background: #191970;
  border:1px solid #191970;
}

#btn1,#btn2,#btn3,#btn4,#btn5,#btn6,#btn7,#btn8,#btn9,#btn10,
#btn11,#btn12,#btn13,#btn14,#btn15,#btn16,#btn17,#btn18,#btn19,#btn20,
#btn21,#btn22,#btn23,#btn24,#btn25,#btn26,#btn27,#btn28,#btn29,#btn30,
#btn31,#btn32,#btn33,#btn34,#btn35,#btn36,#btn37,#btn38,#btn39,#btn40,
#btn41,#btn42,#btn43,#btn44,#btn45,#btn46,#btn47,#btn48 {
  background-image: -webkit-gradient(linear, center top, center bottom, from(rgba(255,255,255,0.1)), to(rgba(0,0,0,0.1)));
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(0,0,0,0.1));
}

.social_icons {
  margin-bottom: 80px;
}

.social_icons a {
  display: inline-block;
  margin-right: 3.5px;
  margin-bottom: 7px;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.social_icons a:hover {
  opacity: 0.8;
}

.social_icons a:last-child {
  margin-right: 0px;
}

.dropcaps {

}

.dropcaps span {
  float: left;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  background: $hf-green;
  color: #fff;
  font-weight:bold;
  margin-right: 15px;
}

#blockquote2 {
  color: #7d7d7d;
  font-size: 28px;
  font-family: Georgia;
  font-style: italic;
  line-height: 36px;
  padding: 0 50px;
  margin-bottom: 70px;
  position: relative;
  clear: both;
}

#blockquote2:after {
  content:'"';
  position:absolute;
  right:0px;
  bottom:-20px;
  font-size:78px;
  font-family: georgia;
  color:#0ad1e5;
}

#blockquote2:before {
  content:'"';
  position:absolute;
  font-family: georgia;
  left:0;
  top:20px;
  font-size:78px;
  color:#0ad1e5;
}

#shortcodes #map {
  margin-bottom: 85px;
}

#shortcodes #contact {
  margin-bottom: 40px;
}

#shortcodes .serv-widget {
  margin-bottom: 85px;
}

ul.tab-list li {
  float: left;
  background: #357d9d;
  text-align: center;
  margin-right: 1px;
  z-index: 100;
}

ul.tab-list li a {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  height: 12px;
  padding: 12px 15px;
  font-size: 11px;
}

ul.tab-list li a img {
  margin-right: 5px;
}

ul.tab-list li.active {
  background: #f5f5f5;
}

ul.tab-list li.active a{
  color: #454545;
}

ul.tab-cont li {
  min-height: 100px;
  display: none;
}

ul.tab-cont li:first-child {
  display: block;
}

ul.tab-cont li {
  padding: 15px;
  background: #f5f5f5;
}

ul.tab-cont li img {
  float: left;
  margin-right: 12px;
}

ul.tab-cont li p {
  color: #828282;
  line-height: 20px;
}

ul.tab-cont {
  margin-bottom: 85px;
}

#shortcodes #recent {
  margin-bottom: 65px !important;
}

#serv3 {
  margin-bottom: 50px !important;
}

#shortcodes .articles { 
  border-bottom:1px solid #e6e6e6;
  padding-bottom: 30px !important;
  margin-bottom: 45px!important;
}

#shortcodes #articles {
  border-bottom:1px solid #e6e6e6;
  padding-bottom: 20px;
  margin-bottom: 45px;
}

#shortcodes #our_clients {
  margin-bottom: 80px;
}

/*-------------------------------------------------------*/
/* single post
/*-------------------------------------------------------*/
.single-post .slides img {
  width: 100%;
  height: auto;
  border: none;
  padding: 0;
  margin: 0;
}

.single-post .slides p {
  margin-bottom: 0;
}

/* =================================================================== */
/* Pricing Tables
====================================================================== */
.pricing-table {
  float: left;
  margin: 1px 0 0 1px;

}

.pricing-table h3 {
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 6px 0;
  margin: 0;
}

.pricing-table li {
  color: #888;
  background: #fdfdfd;
  text-align: center;
  padding: 11px 0;
  border-bottom: 1px solid #fff;
}

.pricing-table li:nth-child(2n) {
  background: #f4f4f4;
}
  
.pricing-table h4 {
  padding:0 0 10px
}

a.sign-up {
  text-align: center;
  display: block;
  color: #fff;
  padding: 12px 0;
  font-weight: bold;
  font-size: 14px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

a.sign-up:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

/* Table
====================================*/
.pricing-table h3,
.pricing-table h4 {
  color: #fff;
  margin: 0;
}

.pricing-table h4 {
  padding: 15px 0;
}

.price,
.time {
  display: block;
  text-align: center;
  line-height: 24px;
}

.price {
  font-size: 24px;
  font-weight: normal;
}

.time {
  font-size: 12px;
  font-weight: normal;
}

/* Color Schemes
====================================*/

/* Color - 1 */
.pricing-table .color-1 h3, .color-1 .sign-up {background-color: #808080;}
.pricing-table .color-1 h4 {background-color: #909090;}

/* Color - 2 */
.pricing-table .color-2 h3, .color-2 .sign-up {background-color: #404040;}
.pricing-table .color-2 h4 {background-color: #4c4c4c;}

/* Color - 3 */
.pricing-table .color-3 h3, .color-3 .sign-up {background-color: #0ad1e5;}
.pricing-table .color-3 h4 {background-color: #0CEAFF;}

/*-------------------------------------------------------*/
/* slider4 - revolution slider
/*-------------------------------------------------------*/

#slider4 {
  background:#fff url(../images/bg3.png);
  padding: 25px 20px;
  margin-bottom: 20px;
}

#slider4_2 {
  padding: 25px 20px;
  margin-bottom: 20px;
}

.bannercontainer {  
    padding:5px;    
    background-color:#fff;
    max-width:940px;  
    position:relative;
    position:relative;  
    margin-left:auto;
    margin-right:auto;
    padding: 0; 
    overflow:hidden;
  }
 
  .banner{    
    width:100%;
    max-height:380px;
    position:relative;
    overflow:hidden;      
   }


.bannercontainer .tp-bullets.tp-thumbs .tp-mask {
  width: 100% !important;
  height: 130px !important;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet {
  position: relative;
  width: 168.5px !important;
  height: 100px !important;
  margin: 15px 15px 15px 0;
}

.bannercontainer .tp-bullets.tp-thumbs img {
  width: 100%;
  height: 100px;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet:before {
  position: absolute;
  content: '';
  border: 5px solid rgba(0,0,0, 0.29);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet.selected:before {
  position: absolute;
  content: '';
  border: 5px solid #0ad1e5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.bannercontainer .big_title {
  background: $hf-green;
  max-width: 455px !important;
  padding: 10px;
  white-space: normal !important;
}


.bannercontainer .big_title h2 {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
}


.bannercontainer .big_black {
  background: rgba(0,0,0,0.75);
  max-width: 455px !important;
  padding: 10px;
  white-space: normal !important;
}

.bannercontainer .big_black h2 {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 20px;
}

.bannercontainer .big_black p {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: normal;
  max-width: 400px !important;
  line-height: 1.5em;
}

.bannercontainer .big_black {
  background: rgba(0,0,0,0.75);
  max-width: 455px !important;
  padding: 20px;
  white-space: normal !important;
}

.bannercontainer .big_black h2 {
  font-size: 0.45em;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

.bannercontainer .big_black p {
  font-size: 0.35em;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: normal;
  max-width: 400px !important;
  line-height: 1.5em;
}

// appointment form align
.form-horizontal .control-label {
  width: 220px;
}
