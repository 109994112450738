.header-logo-container {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.header-logo {
    width: 200px;
    display: inline-block;
    margin-right: 30px;

    img {
        max-width: 100%;
    }
}

/*-------------------------------------------------*/
/* =  Header
/*-------------------------------------------------*/

#top-line {
  min-height: 15px;
  width: 100%;
  border-top: 3px solid $hf-brown;
  position: relative;
}

#top {
  margin: auto;
  width: 940px;
  height: 100%;
  margin-top: 10px;
}

#top #logo {
  float: left;
  margin-bottom: 27px;
}

#top aside {
  float: right;
}

#top aside ul {
  float: right;
  margin: 0;
  margin-top: 3px;
}

#top aside ul li {
  display: inline;
  margin-left: 7px;
}

#top aside ul li:first-child {
  margin-left: 11px;
}

#menu {
    border-top: 0px solid #e5e5e5;
    border-bottom: 0px solid #e5e5e5;
    background: #fff url('../images/bg-white.png') repeat 0 0;

    nav {
        width: 940px;
        margin: auto;

        ul {
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 25px;

                a {
                    padding: 18px 0;
                    font-size: 18px;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    color: #202020;
                    font-family: 'Economica', 'Lucida Sans Unicode', Arial;
                    font-weight: normal;
                    transition: color 0.2s linear;

                    &:hover {
                        color: $hf-green;
                    }
                }
            }

            .nav-button {
                &:hover {
                    border-bottom: none !important;
                }

                a {
                    background: $hf-green;
                    padding: 10px;
                    border-radius: 4px;
                    color: white;

                    &:hover {
                        color: white;
                        background: lighten($hf-green, 10%);
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

header nav ul li:not(.active):hover { 
  border-bottom: 3px solid $hf-green;
}

header nav ul li:hover li {
  border-bottom: 0px solid $hf-green !important;  
}

header nav ul li.active {
  border-top: 3px solid $hf-green !important;
  border-bottom: 3px solid $hf-green !important;
  
}
/* DropDown Menu = Superfish */

.sf-menu ul {
  position: absolute;
  top: -999em;
  width: 180px; /* left offset of submenus need to match (see below) */
}

.sf-menu ul li {
  width: 100%;
}

.sf-menu ul li:first-child {
  border-top: 4px solid #0ad1e5;
}

.sf-menu ul li li:first-child {
  border-top: 4px solid #0ad1e5;
}

.sf-menu li:hover {
  visibility: inherit; /* fixes IE7 'sticky bug' */
}

 li {
  position: relative;
}
.sub-menu {
  border: 1px solid #e8e8e8;
  border-top: none;
}
.sub-menu li {
  border-top: 1px solid #e8e8e8;
}

.sub-menu a {
  display: block;
  position: relative;
  padding: 10px 0px 10px 10px !important;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  left: 0;
  top: 54px; /* match top ul list item height */
  z-index: 99;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 180px; /* match ul width */
  top: -1px;
  /*margin-left: 2px;*/
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 180px; /* match ul width */
  top: -1px;
}

.sf-menu li li {
  background: rgba(255,255,255, 0.95);
  padding-right: 0px;
}

.sf-menu li li li {
  background: rgba(255,255,255, 0.95);
  padding-right: 0px;
}
