.tp-simpleresponsive .caption {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";	-moz-opacity: 0;	-khtml-opacity: 0;	opacity: 0; position:absolute; visibility: hidden;

}

.tp-simpleresponsive img	{	max-width:none; }

/******************************
	-	IE8 HACKS	-
*******************************/
.noFilterClass {
	filter:none !important;
}


/******************************
	-	SHADOWS		-
******************************/
.tp-bannershadow  {
		position:absolute;

		margin-left:auto;
		margin-right:auto;
		-moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
	}

.tp-bannershadow.tp-shadow1 {	background:url(../assets/shadow1.png) no-repeat; background-size:100%; width:890px; height:30px; bottom:-30px;}
.tp-bannershadow.tp-shadow2 {	background:url(../assets/shadow2.png) no-repeat; background-size:100%; width:890px; height:60px;bottom:-60px;}
.tp-bannershadow.tp-shadow3 {	background:url(../assets/shadow3.png) no-repeat; background-size:100%; width:890px; height:60px;bottom:-60px;}


/********************************
	-	FULLSCREEN VIDEO	-
*********************************/
.caption.fullscreenvideo {	left:0px; top:0px; position:absolute;width:100%;height:100%;}
.caption.fullscreenvideo iframe	{ width:100% !important; height:100% !important;}


/*************************
	-	CAPTIONS	-
**************************/
.caption.big_white	{
						position: absolute;
						color: #3b3b3b;
						font-size: 18px;
						font-family: Arial;
						font-weight: bold;
					}

.caption.big_orange	{
						position: absolute;
						color: #8f8f8f;
						text-shadow: none;
						font-size: 15px;
						line-height: 24px;
						font-family: Calibri;
					}

.caption.big_black	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 36px;
						font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
						padding: 10px;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						background-color:#000;
						opacity:.6
					}

.caption.medium_grey	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 20px;
						line-height: 20px;
						font-family: Arial;
						padding: 2px 4px;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						background-color:#888;
						white-space:nowrap;
						text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
					}

.caption.small_text	{
						position: absolute;
						color: #8f8f8f;
						font-size: 12px;
						line-height: 24px;
						font-family: georgia;
						font-style: italic;
					}

.caption.medium_text	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 20px;
						line-height: 20px;
						font-family: Arial;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						white-space:nowrap;
						text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
					}

.caption.large_text	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 40px;
						line-height: 40px;
						font-family: Arial;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						white-space:nowrap;
						text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
					}

.caption.very_large_text	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 60px;
						line-height: 60px;
						font-family: Arial;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						white-space:nowrap;
						text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
						letter-spacing: -2px;
					}


.caption.very_big_white	{
						position: absolute;
						color: #fff;
						text-shadow: none;
						font-weight: 700;
						font-size: 60px;
						line-height: 60px;
						font-family: Arial;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						white-space:nowrap;
						padding: 0px 4px;
						padding-top: 1px;
						background-color:#000;
					}

.caption.very_big_black	{
						position: absolute;
						color: #000;
						text-shadow: none;
						font-weight: 700;
						font-size: 60px;
						line-height: 60px;
						font-family: Arial;
						margin: 0px;
						border-width: 0px;
						border-style: none;
						white-space:nowrap;
						padding: 0px 4px;
						padding-top: 1px;
						background-color:#fff;
					}

.caption.boxshadow	{
						-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
						-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
						box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
					}

.caption.black {
						color: #000;
						text-shadow: none;
					}

.caption.noshadow {
						text-shadow: none;
					}

.caption a { color: #ff7302; text-shadow: none;	-webkit-transition: all 0.2s ease-out; -moz-transition: all 0.2s ease-out; -o-transition: all 0.2s ease-out; -ms-transition: all 0.2s ease-out;	 }
.caption a:hover { color: #ffa902; }



/******************************
	-	BUTTONS	-
*******************************/

.button				{	padding:15px 12px 15px; border-radius: 6px; -moz-border-radius: 6px; -webkit-border-radius: 6px;cursor:pointer; color:#fdfdfd !important; font-size:11px; font-family: arial; font-weight: bold;}

.button.big			{	color:#fff; text-shadow:0px 1px 1px rgba(0, 0, 0, 0.6); font-weight:bold; padding:9px 20px; font-size:19px;  line-height:57px !important; background:url(gradient/g40.png) repeat-x top;}

.purchase:hover,
.button:hover,
.button.big:hover		{opacity: 0.8}



	@media only screen and (min-width: 768px) and (max-width: 959px) {

	 }



	@media only screen and (min-width: 480px) and (max-width: 767px) {
		.button	{	padding:4px 8px 3px; line-height:25px !important; font-size:11px !important;font-weight:normal;	}
		a.button { -webkit-transition: none; -moz-transition: none; -o-transition: none; -ms-transition: none;	 }


	}

    @media only screen and (min-width: 0px) and (max-width: 479px) {
		.button	{	padding:2px 5px 2px; line-height:20px !important; font-size:10px !important;}
		a.button { -webkit-transition: none; -moz-transition: none; -o-transition: none; -ms-transition: none;	 }
	}





/*	BUTTON COLORS	*/



.button.green, .button:hover.green,
.purchase.green, .purchase:hover.green			{ background-color:#0ad1e5;}


.button.blue, .button:hover.blue,
.purchase.blue, .purchase:hover.blue			{ background-color:#1d78cb; -webkit-box-shadow:  0px 3px 0px 0px #0f3e68;        -moz-box-shadow:   0px 3px 0px 0px #0f3e68;        box-shadow:   0px 3px 0px 0px #0f3e68;}


.button.red, .button:hover.red,
.purchase.red, .purchase:hover.red				{ background-color:#cb1d1d; -webkit-box-shadow:  0px 3px 0px 0px #7c1212;        -moz-box-shadow:   0px 3px 0px 0px #7c1212;        box-shadow:   0px 3px 0px 0px #7c1212;}

.button.orange, .button:hover.orange,
.purchase.orange, .purchase:hover.orange		{ background-color:#ff7700; -webkit-box-shadow:  0px 3px 0px 0px #a34c00;        -moz-box-shadow:   0px 3px 0px 0px #a34c00;        box-shadow:   0px 3px 0px 0px #a34c00;}

.button.darkgrey,.button.grey,
.button:hover.darkgrey,.button:hover.grey,
.purchase.darkgrey, .purchase:hover.darkgrey	{ background-color:#8d8d8d;}

.button.lightgrey, .button:hover.lightgrey,
.purchase.lightgrey, .purchase:hover.lightgrey	{ background-color:#888; -webkit-box-shadow:  0px 3px 0px 0px #555;        -moz-box-shadow:   0px 3px 0px 0px #555;        box-shadow:   0px 3px 0px 0px #555;}



/************************
	-	NAVIGATION	-
*************************/

/** BULLETS **/
.tp-bullets									{	z-index:100; position:absolute; bottom:10px;}
.tp-bullets.simplebullets					{ 	bottom:10px;}


.tp-bullets.simplebullets.navbar				{ bottom:-20px; background:url(../assets/navigdots_bgtile.png); height:35px; padding:0px 0px; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px ;margin-top:-17px;}

.tp-bullets.simplebullets .bullet			{	cursor:pointer; position:relative;	background:url(../images/bullets2.png) no-Repeat bottom left;	width:19px;	height:18px;  margin-right:0px; float:left; margin-top:-12px;}
.tp-bullets.simplebullets .bullet.last		{	margin-right:0px;}

/**	SQUARE BULLETS **/
.tp-bullets.simplebullets.square .bullet			{	cursor:pointer; position:relative;	background:url(../assets/bullets2.png) no-Repeat bottom left;	width:19px;	height:19px;  margin-right:0px; float:left; margin-top:-10px;}
.tp-bullets.simplebullets.square .bullet.last		{	margin-right:0px;}

/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar .bullet		{	cursor:pointer; position:relative;	background:url(../assets/navigdots.png) no-Repeat bottom left;	width:15px;	height:15px;  margin-left:5px !important; margin-right:5px !important;float:left; margin-top:10px;}
.tp-bullets.simplebullets.navbar .bullet.first		{	margin-left:30px !important;}
.tp-bullets.simplebullets.navbar .bullet.last		{	margin-right:30px !important;}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected	{	background-position:top left; }




/*************************************
	-	TP ARROWS 	-
**************************************/

.tp-leftarrow.large					{ z-index:100;cursor:pointer; position:relative;	background: #0ad1e5 url(prev-slide.png) center center no-repeat;	width:37px;	height:37px; margin-top:-23px; }
.tp-rightarrow.large				{ z-index:100;cursor:pointer; position:relative;	background: #0ad1e5 url(next-slide.png) center center no-repeat;	width:37px;	height:37px; margin-top:-23px; }

.tp-leftarrow.large:hover	{ opacity: 0.5; }
.tp-rightarrow:hover { opacity: 0.5; }






/****************************************************************************************************
	-	TP THUMBS 	-
*****************************************************************************************************

 - tp-thumbs & tp-mask Width is the width of the basic Thumb Container (500px basic settings)

 - .bullet width & height is the dimension of a simple Thumbnail (basic 100px x 50px)

 *****************************************************************************************************/


.tp-bullets.tp-thumbs						{	z-index:100; position:absolute; padding:0px 2px 2px;background-color:#fff;
												width:940px;height:50px; 			/* THE DIMENSIONS OF THE THUMB CONTAINER */
											}

.fullwidthbanner-container .tp-thumbs					{  padding:2px;}

.tp-bullets.tp-thumbs .tp-mask				{ height:50px;  			/* THE DIMENSIONS OF THE THUMB CONTAINER */
												overflow:hidden; position:relative;}


.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer	{	width:5000px; position:absolute;}

.tp-bullets.tp-thumbs .bullet				{   width:100px; height:50px; 			/* THE DIMENSION OF A SINGLE THUMB */
												cursor:pointer; overflow:hidden;background:none;margin:0;float:left;
												-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
												/*filter: alpha(opacity=50);	*/
												-moz-opacity: 1;
												-khtml-opacity: 1;
												opacity: 1;
												-webkit-transition: all 0.2s ease-out; -moz-transition: all 0.2s ease-out; -o-transition: all 0.2s ease-out; -ms-transition: all 0.2s ease-out;
											}
.tp-bullets.tp-thumbs .bullet:hover,
.tp-bullets.tp-thumbs .bullet.selected		{ 	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";

												-moz-opacity: 1;
												-khtml-opacity: 1;
												opacity: 1;
											}
.tp-thumbs img								{	width:100%; }


/************************************
		-	TP BANNER TIMER		-
*************************************/
.tp-bannertimer								{	width:100%; height:10px; ;position:absolute; z-index:200;}
.tp-bannertimer.tp-bottom					{	bottom:0px !important;height:5px;}




/***************************************
	-	RESPONSIVE SETTINGS 	-
****************************************/


	@media only screen and (min-width: 768px) and (max-width: 959px) {

	 }



	@media only screen and (min-width: 480px) and (max-width: 767px) {
			.responsive .tp-bullets.tp-thumbs				{	width:300px !important; height:30px !important;}
			.responsive .tp-bullets.tp-thumbs .tp-mask		{	width:300px !important; height:30px !important;}
			.responsive .tp-bullets.tp-thumbs .bullet		{	width:60px !important;height:30px !important;}

	}

    @media only screen and (min-width: 0px) and (max-width: 479px) {
				.responsive .tp-bullets	{	display:none}
				.responsive .tparrows	{	display:none;}
				.bannercontainer { margin-bottom: 0 !important;}
	}





/*********************************************

	-	BASIC SETTINGS FOR THE BANNER	-

***********************************************/

 .tp-simpleresponsive img {
		-moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;

}



.tp-simpleresponsive a{	text-decoration:none;}

.tp-simpleresponsive ul {
	list-style:none;
	padding:0;
	margin:0;
}

.tp-simpleresponsive >ul >li{
	list-stye:none;
	position:absolute;
	visibility:hidden;
}
/*  CAPTION SLIDELINK   **/
.caption.slidelink a div {	width:10000px; height:10000px;  }







