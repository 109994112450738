#works{
	margin-bottom: 40px;
}

.works, .blog, .clients {
  overflow: hidden;
}
.works-carousel-container, .blog-wrapper, .blog-wrapper1, .logo-wrapper {
  clear: both;
  display: block; 
  position: relative; 
  margin-left: -31px;
}
.blog-wrapper1 {
  margin-left: 0px;
}
.works-carousel, .blog-carousel, .works-carousel2, .blog-carousel2, .works-carousel3, .logo-carousel {
  position: relative;
  display: block;
}
.works-carousel .columns img, .works-carousel2 .columns img, .works-carousel3 .columns img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

 .works-carousel .columns, .blog-carousel .columns, .works-carousel2 .columns, .works-carousel3 .columns, .blog-carousel2 .columns, 
 .logo-carousel .columns {  
  margin-left: 21px !important;  
  position: relative;
  float: left;
}

.works-carousel2{
	left:5px;
}
.works-title {
  text-align: center;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1;
}
.oldie .works-title {
  background: url(../img/bg-header-ie.png) repeat;
}
.works-title h4 {
  padding: 10px;
  font-size: 14px;
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}
.works-title h4, .works-title p {
  margin-bottom: 0px;
}

#content .works-title a {
  color: #fff;
}
a.works-more, a.blog-more {
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: 43px;
}
  .works-direction-nav, .blog-direction-nav, .logo-direction-nav {
    position: absolute;
    top: -47px;
    right: 0;   
  }
   .logo-direction-nav {
    top: -35px;
   }
  .works-direction-nav li, .blog-direction-nav li {
      position: relative;
      display: block;
      float: left;
  }
  .works-direction-nav a.works-prev, a.works-next, .blog-direction-nav a.blog-prev, .blog-direction-nav a.blog-next,
  .works-direction-nav a.works2-prev, a.works2-next, .blog-direction-nav a.blog2-prev, .blog-direction-nav a.blog2-next,
  .works-direction-nav a.works3-prev, a.works3-next, .logo-direction-nav a.logo-prev, .logo-direction-nav a.logo-next {
      display: block;
      float: left;
      width: 20px;
      height: 20px;
      text-indent: -9999px;
      margin-right: 1px;
      background: #818181 url(bg-carousel-nav.png) no-repeat -15px 2px;   
      border-radius: 2px;
  }
  .logo-direction-nav a.logo-prev:hover, .logo-direction-nav a.logo-next:hover {
    border: none !important;
  }
  .works-direction-nav a.works-prev, .blog-direction-nav a.blog-prev,
  .works-direction-nav a.works2-prev, .blog-direction-nav a.blog2-prev,
  .works-direction-nav a.works3-prev, .logo-direction-nav a.logo-prev {
    margin-right: 1px;
    background-position: 1px 2px;
  }
  .works-direction-nav a.works-prev:hover,  a.works-next:hover, .blog-direction-nav a.blog-prev:hover, a.blog-next:hover,
  .works-direction-nav a.works2-prev:hover,  a.works2-next:hover, .blog2-direction-nav a.blog2-prev:hover, a.blog2-next:hover,
  .works-direction-nav a.works3-prev:hover,  a.works3-next:hover, .logo-direction-nav a.logo-prev:hover, .logo-direction-nav a.logo-next:hover {
    background-color: #49abcf;
  }
  .works-direction-nav a.disabled, .works-direction-nav a.disabled:hover, 
  .blog-direction-nav a.disabled, .blog-direction-nav a.disabled:hover,
  .works-direction-nav a.disabled, .works2-direction-nav a.disabled:hover, 
  .blog-direction-nav a.disabled, .blog2-direction-nav a.disabled:hover,
  .works3-direction-nav a.disabled:hover, .logo-direction-nav a.light.disabled, .logo-direction-nav a.light.disabled:hover {
    background-color: #efefef !important;
    cursor: default;
  }
 .logo-direction-nav a.disabled, .logo-direction-nav a.disabled:hover {
    background-color: #4a4a4a;
    cursor: default;
  }
.works-thumb {
    position: relative;
    display: block;
    line-height: 0;
    overflow: hidden;
    z-index: 0;
    height: 100%;
	width:219px;
}
.works-thumb h4 {
  margin-bottom: 5px;
}
.works-thumb img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.works-thumb:hover .overlay {
  opacity: 1;
  visibility: visible;
  top: 0;
  right: 0;
}

.works-thumb2 {
    position: relative;
    display: block;
    line-height: 0;
    overflow: hidden;
    z-index: 0;
    height: 100%;
}
.works-thumb2 h4 {
  margin-bottom: 5px;
}
.works-thumb2 img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.works-thumb2:hover .overlay {
  opacity: 1;
  visibility: visible;
  top: 0;
  right: 0;
}

.overlay { 
  color: #fff;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #0AD1E5;
  top: -50%;
  right: -50%; 
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.overlay1 { 
  top: -50%;
  right: 0%;
}
.overlay2 { 
  top: 50%;
  right: 0%;
}
.overlay3 { 
  top: 0%;
  right: 50%;
}
.overlay4 { 
  top: 0%;
  right: -50%;
}
.overlay5 { 
  top: -50%;
  right: 50%;
}
.overlay6 { 
  top: -50%;
  right: -50%;
}
.overlay7 { 
  top: 50%;
  right: -50%;
}
.overlay8 { 
  top: 50%;
  right: 50%;
}
.works-thumb .overlay {
  padding: 15px;
}
.works-thumb2 .overlay {
  padding: 15px;
}

.overlay h4, #content .overlay-icons a {
  color: #fff;
  font-family:'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size:14px;
}
.overlay-icons {
  position: absolute;
  bottom: 0px;  
  right: 0px;
  width: 100%;
  display: block;
  text-align: center;
}
.overlay-icons:before {
  content: "";
  width: 100%;
  
  position: absolute;
  left: 0;
  display: block;
  
}
.works-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: #0AD1E5;
  color: white !important;
  padding: 20px 10px;
  font-size: 24px;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";  
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 3;
}
#content .works-icon:hover {
  color: #fff; 
}
.works-thumb:hover a.works-icon {
  top: -42px;
  right: -42px;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; 
  opacity: 0;
}
.works-preview2, .works-link2 {
display: block;
width: 32px;
height: 32px;
background: #4A4A4A url(../img/bg-icons-overlay.png) no-repeat 0 0;
bottom: 0;
right: 33px;
position: absolute;
}
.works-link2 {
  right: 0;
  background-position: 0px -32px;
}
.works-preview, .works-cat, .works-link {
  display: inline-block; 
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";  
  opacity: 0.75;  
  font-size: 24px; 
  padding: 18px 5px 16px;
  margin: 5px 10px 5px 0px;
  position: relative; 
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
}
#content .overlay-icons a:hover {
  color: #fff; 
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
.works-preview {
  margin-right: 0px;
}
.works-link {
  margin-left: 5px;
}
.works-cat {
  float: left;
  opacity: 1!important;
  margin-left: 10px;
}
.overlay p{
	line-height:24px;
}

.align-right {
    float: right;
}

.align-left { float: left; }


 
/* Fancybox
================================================== */

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1104;
  display: none;
}

#fancybox-loading div {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 480px;
  background-image: url('../img/fancybox/fancybox.png');
}

#fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  display: none;
}

#fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  overflow: auto;
  display: none;
}

#fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1101;
  outline: none;
  display: none;
}

#fancybox-outer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}

#fancybox-content {
  width: 0;
  height: 0;
  padding: 0;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 1102;
  border: 0px solid #fff;
}

#fancybox-hide-sel-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1101;
}

#fancybox-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: transparent url('../img/fancybox/fancybox.png') -40px 0px;
  cursor: pointer;
  z-index: 1103;
  display: none;
}

#fancybox-error {
  color: #444;
  font: normal 12px/20px Arial;
  padding: 14px;
  margin: 0;
}

#fancybox-img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 0;
  vertical-align: top;
}

#fancybox-frame {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

#fancybox-left, #fancybox-right {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 35%;
  cursor: pointer;
  outline: none;
  background: transparent url('../img/fancybox/blank.gif');
  z-index: 1102;
  display: none;
}

#fancybox-left {
  left: 0px;
}

#fancybox-right {
  right: 0px;
}

#fancybox-left-ico, #fancybox-right-ico {
  position: absolute;
  top: 50%;
  left: -9999px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  cursor: pointer;
  z-index: 1102;
  display: block;
}

#fancybox-left-ico {
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -30px;
}

#fancybox-right-ico {
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -60px;
}

#fancybox-left:hover, #fancybox-right:hover {
  visibility: visible; /* IE6 */
}

#fancybox-left:hover span {
  left: 20px;
}

#fancybox-right:hover span {
  left: auto;
  right: 20px;
}

.fancybox-bg {
  position: absolute;
  padding: 0;
  margin: 0;
  border: 0;
  width: 20px;
  height: 20px;
  z-index: 1001;
}

#fancybox-bg-n {
  top: -20px;
  left: 0;
  width: 100%;
  background-image: url('../img/fancybox/fancybox-x.png');
}

#fancybox-bg-ne {
  top: -20px;
  right: -20px;
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -162px;
}

#fancybox-bg-e {
  top: 0;
  right: -20px;
  height: 100%;
  background-image: url('../img/fancybox/fancybox-y.png');
  background-position: -20px 0px;
}

#fancybox-bg-se {
  bottom: -20px;
  right: -20px;
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -182px; 
}

#fancybox-bg-s {
  bottom: -20px;
  left: 0;
  width: 100%;
  background-image: url('../img/fancybox/fancybox-x.png');
  background-position: 0px -20px;
}

#fancybox-bg-sw {
  bottom: -20px;
  left: -20px;
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -142px;
}

#fancybox-bg-w {
  top: 0;
  left: -20px;
  height: 100%;
  background-image: url('../img/fancybox/fancybox-y.png');
}

#fancybox-bg-nw {
  top: -20px;
  left: -20px;
  background-image: url('../img/fancybox/fancybox.png');
  background-position: -40px -122px;
}

#fancybox-title {
  font-family: Helvetica;
  font-size: 12px;
  z-index: 1102;
}

.fancybox-title-inside {
  padding-bottom: 10px;
  text-align: center;
  color: #333;
  background: #fff;
  position: relative;
}

.fancybox-title-outside {
  padding-top: 10px;
  color: #fff;
}

.fancybox-title-over {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #FFF;
  text-align: left;
}

#fancybox-title-over {
  padding: 10px;
  background-image: url('../img/fancybox/fancy_title_over.png');
  display: block;
}

.fancybox-title-float {
  position: absolute;
  left: 0;
  bottom: -20px;
  height: 32px;
}

#fancybox-title-float-wrap {
  border: none;
  border-collapse: collapse;
  width: auto;
}

#fancybox-title-float-wrap td {
  border: none;
  white-space: nowrap;
}

#fancybox-title-float-left {
  padding: 0 0 0 15px;
  background: url('../img/fancybox/fancybox.png') -40px -90px no-repeat;
}

#fancybox-title-float-main {
  color: #FFF;
  line-height: 29px;
  font-weight: bold;
  padding: 0 0 3px 0;
  background: url('../img/fancybox/fancybox-x.png') 0px -40px;
}

#fancybox-title-float-right {
  padding: 0 0 0 15px;
  background: url('../img/fancybox/fancybox.png') -55px -90px no-repeat;
}

/* IE6 */

.fancybox-ie6 #fancybox-close { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_close.png', sizingMethod='scale'); }

.fancybox-ie6 #fancybox-left-ico { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_nav_left.png', sizingMethod='scale'); }
.fancybox-ie6 #fancybox-right-ico { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_nav_right.png', sizingMethod='scale'); }

.fancybox-ie6 #fancybox-title-over { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_over.png', sizingMethod='scale'); zoom: 1; }
.fancybox-ie6 #fancybox-title-float-left { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_left.png', sizingMethod='scale'); }
.fancybox-ie6 #fancybox-title-float-main { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_main.png', sizingMethod='scale'); }
.fancybox-ie6 #fancybox-title-float-right { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_right.png', sizingMethod='scale'); }

.fancybox-ie6 #fancybox-bg-w, .fancybox-ie6 #fancybox-bg-e, .fancybox-ie6 #fancybox-left, .fancybox-ie6 #fancybox-right, #fancybox-hide-sel-frame {
  height: expression(this.parentNode.clientHeight + "px");
}

#fancybox-loading.fancybox-ie6 {
  position: absolute; margin-top: 0;
  top: expression( (-20 + (document.documentElement.clientHeight ? document.documentElement.clientHeight/2 : document.body.clientHeight/2 ) + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop )) + 'px');
}

#fancybox-loading.fancybox-ie6 div  { background: transparent; filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='fancybox/fancy_loading.png', sizingMethod='scale'); }

/* IE6, IE7, IE8 */

.fancybox-ie .fancybox-bg { background: transparent !important; }

.fancybox-ie #fancybox-bg-n { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_n.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-ne { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_ne.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-e { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_e.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-se { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_se.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-s { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_s.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-sw { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_sw.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-w { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_w.png', sizingMethod='scale'); }
.fancybox-ie #fancybox-bg-nw { filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_nw.png', sizingMethod='scale'); }
