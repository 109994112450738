﻿/* Progress Bar */
.progress-bar h5 {
    margin-bottom: 6px;
}
.progress-bar li {
    list-style: none outside none;
    margin-bottom: 12px;
}
.meter {
    background: none repeat scroll 0 0 #FCFCFC;
    height: 23px;
    position: relative;
}
.meter > span {
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.meter > span:after, .animate > span > span {
    bottom: 0;
    content: "";
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}
.meter > span:after, .animate > span > spans li.current {
    background: none repeat scroll 0 0 #0ad1e5;
}

/* Testimonials */
.testimonial {

}
.testimonial .body {
    background-color: #F5F5F5;
    margin-bottom: 10px;
    padding: 20px 20px 20px 48px;
    position: relative;
}
.testimonial .body:before {
    color: #444444;
    content: "ˮ";
    font-size: 72px;
    left: 16px;
    position: absolute;
    text-align: center;
    top: 40px;
}
.testimonial .author {
    padding-left: 20px;
}
.testimonial .author [class*="ico-"] {
    float: left;
    line-height: 26px;
	margin-top:2px;
}
.testimonial .author .name {
    margin-left: 24px;
}

/* Tabs */
.htabs {
    border-bottom: 1px solid #EEEEEE;
    height: 27px;
    line-height: 15px;
    margin-right: 4px;
}
.htabs a {
    background: url("grad_menu.png") repeat-x scroll left top #FAFAFA;
    border-left: 1px solid #E4E4E4;
    border-radius: 3px 3px 0 0;
    border-right: 1px solid #E4E4E4;
    border-top: 1px solid #E4E4E4;
    color: #666666;
    float: left;
    font-size: 13px;
    margin-right: 4px;
    padding: 6px 10px 5px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
.htabs a.selected {
    background: none repeat scroll 0 0 #FFFFFF;
    border-left: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
    border-top: 1px solid #E4E4E4;
    padding-bottom: 6px;
}
.tab-content {
    border-bottom: 1px solid #E4E4E4;
    border-left: 1px solid #E4E4E4;
    border-radius: 0 4px 4px 4px;
    border-right: 1px solid #E4E4E4;
    overflow: auto;
    padding: 20px;
    z-index: 2;
}

/* Accordions */
.acc_holder {
}
.acc_item {
	padding: 12px 0px;
	margin: 0px 0 0;
	position: relative;
	cursor: pointer;

	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;	
	
    background: #fff;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-khtml-border-radius: 4px;
	border-radius: 4px;	    
}
.acc_item:hover {
	background: #f8f8f8;
}
.acc_control {
	float: left;
	background: #778185 url('acc_bgr.png') 0px -18px no-repeat;
	width: 18px;
	height: 18px;
	margin: 0 14px 0px 8px;

	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
    
    -moz-border-radius: 17px;
    -webkit-border-radius: 17px;
    -khtml-border-radius: 17px;
    border-radius: 17px; 	
}
.active_acc .acc_control {
	background: #778185 url('acc_bgr.png') 0px 0px no-repeat;	
	}
.acc_heading {
	display: block;
	margin-left: 40px;
}	
	
.accordion {
	padding: 0px;
	margin: 0;

	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;	
	
	font-size: 12px;
	font-weight:600;
	line-height: 18px;
}

.accordion.active_acc {
	background:url('acc_bgr.png') 0px 0px no-repeat;
}
.accordion_content {
	display: none;
	padding: 10px 10px 0 40px;
	color: #777;
}

/* Alert Boxes */
.success, .warning, .attention, .information {
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 14px;
    margin-top: 6px;
    padding: 8px 14px;
}
.success a, .warning a, .attention a, .information a {
    color: #444444;
}
.success {
    background: none repeat scroll 0 0 #D6F7E2;
    border: 1px solid #C2EBD1;
}
.warning {
    background: none repeat scroll 0 0 #FFE8E8;
    border: 1px solid #EFD9D9;
}
.attention {
    background: none repeat scroll 0 0 #FFF5CC;
    border: 1px solid #F0E6BF;
}
.information {
    background: none repeat scroll 0 0 #E2F5F7;
    border: 1px solid #D1E9EC;
}
.success .close, .warning .close, .attention .close, .information .close {
    cursor: pointer;
    float: right;
    padding-right: 4px;
    padding-top: 4px;
}
.required {
    color: #FF4242;
    font-weight: bold;
    padding-left: 2px;
}
.error {
    color: #FF4242;
    display: block;
}
.close_img {
    background: url("close.png") no-repeat scroll 0 0 transparent;
    cursor: pointer;
    float: right;
    height: 7px;
    margin: 7px 0 7px 20px;
    width: 7px;
}

/* Styled Lists */
ul.arrowed {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
ul.arrowed li {
    background: url("arrow.png") no-repeat scroll 0 3px transparent;
    margin-bottom: 3px;
    padding: 0 0 0 20px;
}
ul.dotted {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
ul.dotted li {
    background: url("dot.png") no-repeat scroll 0 3px transparent;
    margin-bottom: 3px;
    padding: 0 0 0 20px;
}
ul.checked {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
ul.checked li {
    background: url("check.png") no-repeat scroll 0 3px transparent;
    margin-bottom: 6px;
    padding: 0 0 0 20px;
}
