@charset "UTF-8";
/*@import url("http://fonts.googleapis.com/css?family=Economica:700normal&subset=all");*/
@font-face {
  font-family: 'Economica';
  font-style: normal;
  font-weight: 700;
  src: local("Economica Bold"), local("Economica-Bold"), url(https://themes.googleusercontent.com/static/fonts/economica/v1/UK4l2VEpwjv3gdcwbwXE9BsxEYwM7FgeyaSgU71cLG0.woff) format("woff");
}

/*!
 * Bootstrap v2.2.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:hover,
a:active {
  outline: 0;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  width: auto\9;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

#map_canvas img {
  max-width: none;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}

.clearfix:after {
  clear: both;
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
}

p {
  margin: 0;
}

a {
  color: #1a8643;
  text-decoration: none;
}

a:hover {
  color: #22b158;
}

.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}

.row {
  margin-left: -20px;
  *zoom: 1;
}

.row:before,
.row:after {
  display: table;
  line-height: 0;
  content: "";
}

.row:after {
  clear: both;
}

[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}

.span12 {
  width: 940px;
}

.span11 {
  width: 860px;
}

.span10 {
  width: 780px;
}

.span9 {
  width: 700px;
}

.span8 {
  width: 620px;
}

.span7 {
  width: 540px;
}

.span6 {
  width: 460px;
}

.span5 {
  width: 380px;
}

.span4 {
  width: 300px;
}

.span3 {
  width: 220px;
}

.span2 {
  width: 140px;
}

.span1 {
  width: 60px;
}

.offset12 {
  margin-left: 980px;
}

.offset11 {
  margin-left: 900px;
}

.offset10 {
  margin-left: 820px;
}

.offset9 {
  margin-left: 740px;
}

.offset8 {
  margin-left: 660px;
}

.offset7 {
  margin-left: 580px;
}

.offset6 {
  margin-left: 500px;
}

.offset5 {
  margin-left: 420px;
}

.offset4 {
  margin-left: 340px;
}

.offset3 {
  margin-left: 260px;
}

.offset2 {
  margin-left: 180px;
}

.offset1 {
  margin-left: 100px;
}

.row-fluid {
  width: 100%;
  *zoom: 1;
}

.row-fluid:before,
.row-fluid:after {
  display: table;
  line-height: 0;
  content: "";
}

.row-fluid:after {
  clear: both;
}

.row-fluid [class*="span"] {
  display: block;
  float: left;
  width: 100%;
  min-height: 30px;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}

.row-fluid .span12 {
  width: 100%;
  *width: 99.94680851063829%;
}

.row-fluid .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%;
}

.row-fluid .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%;
}

.row-fluid .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%;
}

.row-fluid .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%;
}

.row-fluid .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%;
}

.row-fluid .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%;
}

.row-fluid .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%;
}

.row-fluid .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%;
}

.row-fluid .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%;
}

.row-fluid .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%;
}

.row-fluid .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%;
}

.row-fluid .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%;
}

.row-fluid .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%;
}

.row-fluid .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%;
}

.row-fluid .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%;
}

.row-fluid .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%;
}

.row-fluid .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%;
}

.row-fluid .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%;
}

.row-fluid .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%;
}

.row-fluid .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%;
}

.row-fluid .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%;
}

.row-fluid .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%;
}

.row-fluid .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%;
}

.row-fluid .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%;
}

.row-fluid .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%;
}

.row-fluid .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%;
}

.row-fluid .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%;
}

.row-fluid .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%;
}

.row-fluid .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%;
}

.row-fluid .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%;
}

.row-fluid .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%;
}

.row-fluid .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%;
}

.row-fluid .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%;
}

.row-fluid .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%;
}

.row-fluid .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%;
}

[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}

[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}

.container {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
}

.container:before,
.container:after {
  display: table;
  line-height: 0;
  content: "";
}

.container:after {
  clear: both;
}

.container-fluid {
  *zoom: 1;
}

.container-fluid:before,
.container-fluid:after {
  display: table;
  line-height: 0;
  content: "";
}

.container-fluid:after {
  clear: both;
}

.lead {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 200;
  line-height: 30px;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

cite {
  font-style: normal;
}

.muted {
  color: #999999;
}

.text-warning {
  color: #c09853;
}

.text-error {
  color: #b94a48;
}

.text-info {
  color: #3a87ad;
}

.text-success {
  color: #468847;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 1;
  color: inherit;
  text-rendering: optimizelegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

h1 small {
  font-size: 24px;
}

h2 small {
  font-size: 18px;
}

h3 small {
  font-size: 14px;
}

h4 small {
  font-size: 14px;
}

.page-header {
  padding-bottom: 9px;
  margin: 20px 0 30px;
  border-bottom: 1px solid #eeeeee;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}

dl {
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 20px;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 10px;
}

.dl-horizontal {
  *zoom: 1;
}

.dl-horizontal:before,
.dl-horizontal:after {
  display: table;
  line-height: 0;
  content: "";
}

.dl-horizontal:after {
  clear: both;
}

.dl-horizontal dt {
  float: left;
  width: 160px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dl-horizontal dd {
  margin-left: 180px;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #ffffff;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #999999;
}

abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

blockquote {
  padding: 0;
  margin: 0;
}

blockquote p {
  margin-bottom: 0;
  font-size: 16px;
}

blockquote small {
  display: block;
  line-height: 20px;
  color: #999999;
}

blockquote small:before {
  content: '\2014 \00A0';
}

blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}

blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}

blockquote.pull-right small:before {
  content: '';
}

blockquote.pull-right small:after {
  content: '\00A0 \2014';
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}

address {
  display: block;
  margin-bottom: 20px;
  font-style: normal;
  line-height: 20px;
}

code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 12px;
  color: #333333;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

code {
  padding: 2px 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

pre.prettyprint {
  margin-bottom: 20px;
}

pre code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

form {
  margin: 0 0 0px;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

legend small {
  font-size: 15px;
  color: #999999;
}

label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

input,
button,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

label {
  display: block;
  margin-bottom: 5px;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  outline: none;
  display: inline-block;
  padding: 4px 6px;
  margin-bottom: 9px;
  font-size: 14px;
  color: #555555;
}

textarea {
  height: auto;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #ffffff;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: none;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  *margin-top: 0;
  line-height: normal;
  cursor: pointer;
}

select {
  width: 220px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

select[multiple],
select[size] {
  height: auto;
}

.uneditable-input,
.uneditable-textarea {
  color: #999999;
  cursor: not-allowed;
  background-color: #fcfcfc;
  border-color: #cccccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
}

.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}

.uneditable-textarea {
  width: auto;
  height: auto;
}

.radio,
.checkbox {
  min-height: 18px;
  padding-left: 18px;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}

.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}

.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}

.input-mini {
  width: 60px;
}

.input-small {
  width: 90px;
}

.input-medium {
  width: 150px;
}

.input-large {
  width: 210px;
}

.input-xlarge {
  width: 270px;
}

.input-xxlarge {
  width: 530px;
}

input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}

.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}

input,
textarea,
.uneditable-input {
  margin-left: 0;
}

.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}

input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 926px;
}

input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 846px;
}

input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 766px;
}

input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 686px;
}

input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 606px;
}

input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 526px;
}

input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 446px;
}

input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 366px;
}

input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 286px;
}

input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 206px;
}

input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 126px;
}

input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 46px;
}

.controls-row {
  *zoom: 1;
}

.controls-row:before,
.controls-row:after {
  display: table;
  line-height: 0;
  content: "";
}

.controls-row:after {
  clear: both;
}

.controls-row [class*="span"] {
  float: left;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}

.control-group.warning > label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}

.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
}

.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}

.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}

.control-group.error > label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #b94a48;
}

.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #b94a48;
}

.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  border-color: #b94a48;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}

.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}

.control-group.success > label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}

.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
}

.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}

.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}

.control-group.info > label,
.control-group.info .help-block,
.control-group.info .help-inline {
  color: #3a87ad;
}

.control-group.info .checkbox,
.control-group.info .radio,
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  color: #3a87ad;
}

.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  border-color: #3a87ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  border-color: #2d6987;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}

.control-group.info .input-prepend .add-on,
.control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}

input:focus:required:invalid,
textarea:focus:required:invalid,
select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}

input:focus:required:invalid:focus,
textarea:focus:required:invalid:focus,
select:focus:required:invalid:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}

.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}

.form-actions:before,
.form-actions:after {
  display: table;
  line-height: 0;
  content: "";
}

.form-actions:after {
  clear: both;
}

.help-block,
.help-inline {
  color: #595959;
}

.help-block {
  display: block;
  margin-bottom: 10px;
}

.help-inline {
  display: inline-block;
  *display: inline;
  padding-left: 5px;
  vertical-align: middle;
  *zoom: 1;
}

.input-append,
.input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap;
}

.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  font-size: 14px;
  vertical-align: top;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}

.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #eeeeee;
  border: 1px solid #ccc;
}

.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn {
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}

.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}

.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-append .add-on,
.input-append .btn {
  margin-left: -1px;
}

.input-append .add-on:last-child,
.input-append .btn:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.form-search .input-append .search-query {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}

.form-search .input-append .btn {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .search-query {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .btn {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}

.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  vertical-align: middle;
  *zoom: 1;
}

.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}

.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}

.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}

.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}

.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}

.control-group {
  margin-bottom: 10px;
}

legend + .control-group {
  margin-top: 20px;
  -webkit-margin-top-collapse: separate;
}

.form-horizontal .control-group {
  margin-bottom: 20px;
  *zoom: 1;
}

.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  line-height: 0;
  content: "";
}

.form-horizontal .control-group:after {
  clear: both;
}

.form-horizontal .control-label {
  float: left;
  width: 160px;
  padding-top: 5px;
  text-align: right;
}

.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  margin-left: 180px;
  *margin-left: 0;
}

.form-horizontal .controls:first-child {
  *padding-left: 180px;
}

.form-horizontal .help-block {
  margin-bottom: 0;
}

.form-horizontal input + .help-block,
.form-horizontal select + .help-block,
.form-horizontal textarea + .help-block {
  margin-top: 10px;
}

.form-horizontal .form-actions {
  padding-left: 180px;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child,
.table-bordered tfoot:last-child tr:last-child td:first-child {
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child,
.table-bordered tfoot:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f5f5f5;
}

table [class*=span],
.row-fluid table [class*=span] {
  display: table-cell;
  float: none;
  margin-left: 0;
}

.table .span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}

.table .span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}

.table .span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}

.table .span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}

.table .span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}

.table .span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}

.table .span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}

.table .span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}

.table .span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}

.table .span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}

.table .span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}

.table .span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}

.table .span13 {
  float: none;
  width: 1004px;
  margin-left: 0;
}

.table .span14 {
  float: none;
  width: 1084px;
  margin-left: 0;
}

.table .span15 {
  float: none;
  width: 1164px;
  margin-left: 0;
}

.table .span16 {
  float: none;
  width: 1244px;
  margin-left: 0;
}

.table .span17 {
  float: none;
  width: 1324px;
  margin-left: 0;
}

.table .span18 {
  float: none;
  width: 1404px;
  margin-left: 0;
}

.table .span19 {
  float: none;
  width: 1484px;
  margin-left: 0;
}

.table .span20 {
  float: none;
  width: 1564px;
  margin-left: 0;
}

.table .span21 {
  float: none;
  width: 1644px;
  margin-left: 0;
}

.table .span22 {
  float: none;
  width: 1724px;
  margin-left: 0;
}

.table .span23 {
  float: none;
  width: 1804px;
  margin-left: 0;
}

.table .span24 {
  float: none;
  width: 1884px;
  margin-left: 0;
}

.table tbody tr.success td {
  background-color: #dff0d8;
}

.table tbody tr.error td {
  background-color: #f2dede;
}

.table tbody tr.warning td {
  background-color: #fcf8e3;
}

.table tbody tr.info td {
  background-color: #d9edf7;
}

.table-hover tbody tr.success:hover td {
  background-color: #d0e9c6;
}

.table-hover tbody tr.error:hover td {
  background-color: #ebcccc;
}

.table-hover tbody tr.warning:hover td {
  background-color: #faf2cc;
}

.table-hover tbody tr.info:hover td {
  background-color: #c4e3f3;
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  *margin-right: .3em;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("../img/glyphicons-halflings.html");
  background-position: 14px 14px;
  background-repeat: no-repeat;
}

/* White icons with optional class, or on hover/active states of certain elements */
.icon-white,
.nav-tabs > .active > a > [class^="icon-"],
.nav-tabs > .active > a > [class*=" icon-"],
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"] {
  background-image: url("../img/glyphicons-halflings-white.html");
}

.icon-glass {
  background-position: 0      0;
}

.icon-music {
  background-position: -24px 0;
}

.icon-search {
  background-position: -48px 0;
}

.icon-envelope {
  background-position: -72px 0;
}

.icon-heart {
  background-position: -96px 0;
}

.icon-star {
  background-position: -120px 0;
}

.icon-star-empty {
  background-position: -144px 0;
}

.icon-user {
  background-position: -168px 0;
}

.icon-film {
  background-position: -192px 0;
}

.icon-th-large {
  background-position: -216px 0;
}

.icon-th {
  background-position: -240px 0;
}

.icon-th-list {
  background-position: -264px 0;
}

.icon-ok {
  background-position: -288px 0;
}

.icon-remove {
  background-position: -312px 0;
}

.icon-zoom-in {
  background-position: -336px 0;
}

.icon-zoom-out {
  background-position: -360px 0;
}

.icon-off {
  background-position: -384px 0;
}

.icon-signal {
  background-position: -408px 0;
}

.icon-cog {
  background-position: -432px 0;
}

.icon-trash {
  background-position: -456px 0;
}

.icon-home {
  background-position: 0 -24px;
}

.icon-file {
  background-position: -24px -24px;
}

.icon-time {
  background-position: -48px -24px;
}

.icon-road {
  background-position: -72px -24px;
}

.icon-download-alt {
  background-position: -96px -24px;
}

.icon-download {
  background-position: -120px -24px;
}

.icon-upload {
  background-position: -144px -24px;
}

.icon-inbox {
  background-position: -168px -24px;
}

.icon-play-circle {
  background-position: -192px -24px;
}

.icon-repeat {
  background-position: -216px -24px;
}

.icon-refresh {
  background-position: -240px -24px;
}

.icon-list-alt {
  background-position: -264px -24px;
}

.icon-lock {
  background-position: -287px -24px;
}

.icon-flag {
  background-position: -312px -24px;
}

.icon-headphones {
  background-position: -336px -24px;
}

.icon-volume-off {
  background-position: -360px -24px;
}

.icon-volume-down {
  background-position: -384px -24px;
}

.icon-volume-up {
  background-position: -408px -24px;
}

.icon-qrcode {
  background-position: -432px -24px;
}

.icon-barcode {
  background-position: -456px -24px;
}

.icon-tag {
  background-position: 0 -48px;
}

.icon-tags {
  background-position: -25px -48px;
}

.icon-book {
  background-position: -48px -48px;
}

.icon-bookmark {
  background-position: -72px -48px;
}

.icon-print {
  background-position: -96px -48px;
}

.icon-camera {
  background-position: -120px -48px;
}

.icon-font {
  background-position: -144px -48px;
}

.icon-bold {
  background-position: -167px -48px;
}

.icon-italic {
  background-position: -192px -48px;
}

.icon-text-height {
  background-position: -216px -48px;
}

.icon-text-width {
  background-position: -240px -48px;
}

.icon-align-left {
  background-position: -264px -48px;
}

.icon-align-center {
  background-position: -288px -48px;
}

.icon-align-right {
  background-position: -312px -48px;
}

.icon-align-justify {
  background-position: -336px -48px;
}

.icon-list {
  background-position: -360px -48px;
}

.icon-indent-left {
  background-position: -384px -48px;
}

.icon-indent-right {
  background-position: -408px -48px;
}

.icon-facetime-video {
  background-position: -432px -48px;
}

.icon-picture {
  background-position: -456px -48px;
}

.icon-pencil {
  background-position: 0 -72px;
}

.icon-map-marker {
  background-position: -24px -72px;
}

.icon-adjust {
  background-position: -48px -72px;
}

.icon-tint {
  background-position: -72px -72px;
}

.icon-edit {
  background-position: -96px -72px;
}

.icon-share {
  background-position: -120px -72px;
}

.icon-check {
  background-position: -144px -72px;
}

.icon-move {
  background-position: -168px -72px;
}

.icon-step-backward {
  background-position: -192px -72px;
}

.icon-fast-backward {
  background-position: -216px -72px;
}

.icon-backward {
  background-position: -240px -72px;
}

.icon-play {
  background-position: -264px -72px;
}

.icon-pause {
  background-position: -288px -72px;
}

.icon-stop {
  background-position: -312px -72px;
}

.icon-forward {
  background-position: -336px -72px;
}

.icon-fast-forward {
  background-position: -360px -72px;
}

.icon-step-forward {
  background-position: -384px -72px;
}

.icon-eject {
  background-position: -408px -72px;
}

.icon-chevron-left {
  background-position: -432px -72px;
}

.icon-chevron-right {
  background-position: -456px -72px;
}

.icon-plus-sign {
  background-position: 0 -96px;
}

.icon-minus-sign {
  background-position: -24px -96px;
}

.icon-remove-sign {
  background-position: -48px -96px;
}

.icon-ok-sign {
  background-position: -72px -96px;
}

.icon-question-sign {
  background-position: -96px -96px;
}

.icon-info-sign {
  background-position: -120px -96px;
}

.icon-screenshot {
  background-position: -144px -96px;
}

.icon-remove-circle {
  background-position: -168px -96px;
}

.icon-ok-circle {
  background-position: -192px -96px;
}

.icon-ban-circle {
  background-position: -216px -96px;
}

.icon-arrow-left {
  background-position: -240px -96px;
}

.icon-arrow-right {
  background-position: -264px -96px;
}

.icon-arrow-up {
  background-position: -289px -96px;
}

.icon-arrow-down {
  background-position: -312px -96px;
}

.icon-share-alt {
  background-position: -336px -96px;
}

.icon-resize-full {
  background-position: -360px -96px;
}

.icon-resize-small {
  background-position: -384px -96px;
}

.icon-plus {
  background-position: -408px -96px;
}

.icon-minus {
  background-position: -433px -96px;
}

.icon-asterisk {
  background-position: -456px -96px;
}

.icon-exclamation-sign {
  background-position: 0 -120px;
}

.icon-gift {
  background-position: -24px -120px;
}

.icon-leaf {
  background-position: -48px -120px;
}

.icon-fire {
  background-position: -72px -120px;
}

.icon-eye-open {
  background-position: -96px -120px;
}

.icon-eye-close {
  background-position: -120px -120px;
}

.icon-warning-sign {
  background-position: -144px -120px;
}

.icon-plane {
  background-position: -168px -120px;
}

.icon-calendar {
  background-position: -192px -120px;
}

.icon-random {
  width: 16px;
  background-position: -216px -120px;
}

.icon-comment {
  background-position: -240px -120px;
}

.icon-magnet {
  background-position: -264px -120px;
}

.icon-chevron-up {
  background-position: -288px -120px;
}

.icon-chevron-down {
  background-position: -313px -119px;
}

.icon-retweet {
  background-position: -336px -120px;
}

.icon-shopping-cart {
  background-position: -360px -120px;
}

.icon-folder-close {
  background-position: -384px -120px;
}

.icon-folder-open {
  width: 16px;
  background-position: -408px -120px;
}

.icon-resize-vertical {
  background-position: -432px -119px;
}

.icon-resize-horizontal {
  background-position: -456px -118px;
}

.icon-hdd {
  background-position: 0 -144px;
}

.icon-bullhorn {
  background-position: -24px -144px;
}

.icon-bell {
  background-position: -48px -144px;
}

.icon-certificate {
  background-position: -72px -144px;
}

.icon-thumbs-up {
  background-position: -96px -144px;
}

.icon-thumbs-down {
  background-position: -120px -144px;
}

.icon-hand-right {
  background-position: -144px -144px;
}

.icon-hand-left {
  background-position: -168px -144px;
}

.icon-hand-up {
  background-position: -192px -144px;
}

.icon-hand-down {
  background-position: -216px -144px;
}

.icon-circle-arrow-right {
  background-position: -240px -144px;
}

.icon-circle-arrow-left {
  background-position: -264px -144px;
}

.icon-circle-arrow-up {
  background-position: -288px -144px;
}

.icon-circle-arrow-down {
  background-position: -312px -144px;
}

.icon-globe {
  background-position: -336px -144px;
}

.icon-wrench {
  background-position: -360px -144px;
}

.icon-tasks {
  background-position: -384px -144px;
}

.icon-filter {
  background-position: -408px -144px;
}

.icon-briefcase {
  background-position: -432px -144px;
}

.icon-fullscreen {
  background-position: -456px -144px;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle {
  *margin-bottom: -3px;
}

.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.dropdown-menu a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-repeat: repeat-x;
  outline: 0;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: #999999;
}

.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
}

.open {
  *z-index: 1000;
}

.open > .dropdown-menu {
  display: block;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  border-color: transparent;
  border-left-color: #cccccc;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: " ";
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown .dropdown-menu .nav-header {
  padding-right: 20px;
  padding-left: 20px;
}

.typeahead {
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}

.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.collapse.in {
  height: auto;
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.close:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.btn {
  display: inline-block;
  *display: inline;
  padding: 4px 14px;
  margin-bottom: 0;
  *margin-left: .3em;
  font-size: 14px;
  line-height: 20px;
  *line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  *background-color: #e6e6e6;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #bbbbbb;
  *border: 0;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-bottom-color: #a2a2a2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}

.btn:active,
.btn.active {
  background-color: #cccccc \9;
}

.btn:first-child {
  *margin-left: 0;
}

.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
  /* Buttons in IE7 don't get borders, so darken on hover */
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

.btn.active,
.btn:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-color: #e6e6e6;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-large {
  padding: 9px 14px;
  font-size: 16px;
  line-height: normal;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.btn-large [class^="icon-"] {
  margin-top: 2px;
}

.btn-small {
  padding: 3px 9px;
  font-size: 12px;
  line-height: 18px;
}

.btn-small [class^="icon-"] {
  margin-top: 0;
}

.btn-mini {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 17px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}

.btn {
  border-color: #c5c5c5;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}

.btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006dcc;
  *background-color: #0044cc;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-repeat: repeat-x;
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #ffffff;
  background-color: #0044cc;
  *background-color: #003bb3;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #003399 \9;
}

.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  *background-color: #f89406;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-repeat: repeat-x;
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
  *background-color: #df8505;
}

.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}

.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  *background-color: #bd362f;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
  *background-color: #a9302a;
}

.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}

.btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  *background-color: #51a351;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-repeat: repeat-x;
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #ffffff;
  background-color: #51a351;
  *background-color: #499249;
}

.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}

.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  *background-color: #2f96b4;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}

.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}

.btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  *background-color: #222222;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#222222));
  background-image: -webkit-linear-gradient(top, #444444, #222222);
  background-image: -o-linear-gradient(top, #444444, #222222);
  background-image: linear-gradient(to bottom, #444444, #222222);
  background-image: -moz-linear-gradient(top, #444444, #222222);
  background-repeat: repeat-x;
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
  *background-color: #151515;
}

.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}

button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}

button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  color: #0088cc;
  cursor: pointer;
  border-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn-link:hover {
  color: #005580;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover {
  color: #333333;
  text-decoration: none;
}

.btn-group {
  position: relative;
  *margin-left: .3em;
  font-size: 0;
  white-space: nowrap;
  vertical-align: middle;
}

.btn-group:first-child {
  *margin-left: 0;
}

.btn-group + .btn-group {
  margin-left: 5px;
}

.btn-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0;
}

.btn-toolbar .btn-group {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}

.btn-toolbar .btn + .btn,
.btn-toolbar .btn-group + .btn,
.btn-toolbar .btn + .btn-group {
  margin-left: 5px;
}

.btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn-group > .btn + .btn {
  margin-left: -1px;
}

.btn-group > .btn,
.btn-group > .dropdown-menu {
  font-size: 14px;
}

.btn-group > .btn-mini {
  font-size: 11px;
}

.btn-group > .btn-small {
  font-size: 12px;
}

.btn-group > .btn-large {
  font-size: 16px;
}

.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}

.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}

.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-bottom-left-radius: 6px;
  border-bottom-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
}

.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
}

.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  *padding-top: 5px;
  padding-right: 8px;
  *padding-bottom: 5px;
  padding-left: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-group > .btn-mini + .dropdown-toggle {
  *padding-top: 2px;
  padding-right: 5px;
  *padding-bottom: 2px;
  padding-left: 5px;
}

.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}

.btn-group > .btn-large + .dropdown-toggle {
  *padding-top: 7px;
  padding-right: 12px;
  *padding-bottom: 7px;
  padding-left: 12px;
}

.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #0044cc;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}

.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222222;
}

.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}

.btn-mini .caret,
.btn-small .caret,
.btn-large .caret {
  margin-top: 6px;
}

.btn-large .caret {
  border-top-width: 5px;
  border-right-width: 5px;
  border-left-width: 5px;
}

.dropup .btn-large .caret {
  border-top: 0;
  border-bottom: 5px solid #000000;
}

.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}

.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}

.btn-group-vertical .btn {
  display: block;
  float: none;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn-group-vertical .btn + .btn {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical .btn:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.btn-group-vertical .btn:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.btn-group-vertical .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

.btn-group-vertical .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  color: #c09853;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.alert h4 {
  margin: 0;
}

.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}

.alert-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger,
.alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.alert-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}

.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}

.alert-block p + p {
  margin-top: 5px;
}

.nav {
  margin-bottom: 20px;
  margin-left: 0;
  list-style: none;
}

.nav > li > a {
  display: block;
}

.nav > li > a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}

.nav > .pull-right {
  float: right;
}

.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.nav li + .nav-header {
  margin-top: 9px;
}

.nav-list {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 0;
}

.nav-list > li > a,
.nav-list .nav-header {
  margin-right: -15px;
  margin-left: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.nav-list > li > a {
  padding: 3px 15px;
}

.nav-list > .active > a,
.nav-list > .active > a:hover {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #0088cc;
}

.nav-list [class^="icon-"] {
  margin-right: 2px;
}

.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.nav-tabs,
.nav-pills {
  *zoom: 1;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  line-height: 0;
  content: "";
}

.nav-tabs:after,
.nav-pills:after {
  clear: both;
}

.nav-tabs > li,
.nav-pills > li {
  float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  color: #555555;
  cursor: default;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  color: #ffffff;
  background-color: #0088cc;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li > a {
  margin-right: 0;
}

.nav-tabs.nav-stacked {
  border-bottom: 0;
}

.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-topleft: 4px;
}

.nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.nav-tabs.nav-stacked > li > a:hover {
  z-index: 2;
  border-color: #ddd;
}

.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}

.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}

.nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

.nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.nav .dropdown-toggle .caret {
  margin-top: 6px;
  border-top-color: #0088cc;
  border-bottom-color: #0088cc;
}

.nav .dropdown-toggle:hover .caret {
  border-top-color: #005580;
  border-bottom-color: #005580;
}

/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}

.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}

.nav > .dropdown.active > a:hover {
  cursor: pointer;
}

.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}

.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.tabs-stacked .open > a:hover {
  border-color: #999999;
}

.tabbable {
  *zoom: 1;
}

.tabbable:before,
.tabbable:after {
  display: table;
  line-height: 0;
  content: "";
}

.tabbable:after {
  clear: both;
}

.tab-content {
  overflow: auto;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

.nav > .disabled > a {
  color: #999999;
}

.nav > .disabled > a:hover {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
}

.navbar {
  *position: relative;
  *z-index: 2;
  margin-bottom: 20px;
  overflow: visible;
  color: #777777;
}

.navbar-inner {
  min-height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  *zoom: 1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}

.navbar-inner:before,
.navbar-inner:after {
  display: table;
  line-height: 0;
  content: "";
}

.navbar-inner:after {
  clear: both;
}

.navbar .container {
  width: auto;
}

.nav-collapse.collapse {
  height: auto;
}

.navbar .brand {
  display: block;
  float: left;
  padding: 10px 20px 10px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777777;
  text-shadow: 0 1px 0 #ffffff;
}

.navbar .brand:hover {
  text-decoration: none;
}

.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
}

.navbar-link {
  color: #777777;
}

.navbar-link:hover {
  color: #333333;
}

.navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #f2f2f2;
}

.navbar .btn,
.navbar .btn-group {
  margin-top: 5px;
}

.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn {
  margin-top: 0;
}

.navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}

.navbar-form:before,
.navbar-form:after {
  display: table;
  line-height: 0;
  content: "";
}

.navbar-form:after {
  clear: both;
}

.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: 5px;
}

.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}

.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}

.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 6px;
  white-space: nowrap;
}

.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}

.navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}

.navbar-search .search-query {
  padding: 4px 14px;
  margin-bottom: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.navbar-static-top {
  position: static;
  width: 100%;
  margin-bottom: 0;
}

.navbar-static-top .navbar-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}

.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}

.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}

.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-right: 0;
  padding-left: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-fixed-bottom .navbar-inner {
  -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}

.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}

.navbar .nav > li {
  float: left;
}

.navbar .nav > li > a {
  float: none;
  padding: 10px 15px 10px;
  color: #777777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}

.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}

.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
}

.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: #555555;
  text-decoration: none;
  background-color: #e5e5e5;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}

.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-right: 5px;
  margin-left: 5px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  *background-color: #e5e5e5;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075);
}

.navbar .btn-navbar:hover,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}

.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
  background-color: #cccccc \9;
}

.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}

.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}

.navbar .nav > li > .dropdown-menu:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.navbar .nav > li > .dropdown-menu:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  top: auto;
  bottom: -7px;
  border-top: 7px solid #ccc;
  border-bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.2);
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  top: auto;
  bottom: -6px;
  border-top: 6px solid #ffffff;
  border-bottom: 0;
}

.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  color: #555555;
  background-color: #e5e5e5;
}

.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777777;
  border-bottom-color: #777777;
}

.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}

.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}

.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}

.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.navbar-inverse {
  color: #999999;
}

.navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  border-color: #252525;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
}

.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #999999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.navbar-inverse .brand:hover,
.navbar-inverse .nav > li > a:hover {
  color: #ffffff;
}

.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover {
  color: #ffffff;
  background-color: transparent;
}

.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #ffffff;
  background-color: #111111;
}

.navbar-inverse .navbar-link {
  color: #999999;
}

.navbar-inverse .navbar-link:hover {
  color: #ffffff;
}

.navbar-inverse .divider-vertical {
  border-right-color: #222222;
  border-left-color: #111111;
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  color: #ffffff;
  background-color: #111111;
}

.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999999;
  border-bottom-color: #999999;
}

.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}

.navbar-inverse .navbar-search .search-query {
  color: #ffffff;
  background-color: #515151;
  border-color: #111111;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #cccccc;
}

.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #cccccc;
}

.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #cccccc;
}

.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #333333;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #ffffff;
  border: 0;
  outline: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.navbar-inverse .btn-navbar {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  *background-color: #040404;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
  background-image: -webkit-linear-gradient(top, #151515, #040404);
  background-image: -o-linear-gradient(top, #151515, #040404);
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-repeat: repeat-x;
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.navbar-inverse .btn-navbar:hover,
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active,
.navbar-inverse .btn-navbar.disabled,
.navbar-inverse .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #040404;
  *background-color: #000000;
}

.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}

.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.breadcrumb li {
  display: inline-block;
  *display: inline;
  text-shadow: 0 1px 0 #ffffff;
  *zoom: 1;
}

.breadcrumb .divider {
  padding: 0 5px;
  color: #ccc;
}

.breadcrumb .active {
  color: #999999;
}

.pagination {
  height: 40px;
  margin: 20px 0;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination ul > li {
  display: inline;
}

.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 0 14px;
  line-height: 38px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
}

.pagination ul > li > a:hover,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}

.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #999999;
  cursor: default;
}

.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover {
  color: #999999;
  cursor: default;
  background-color: transparent;
}

.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin: 20px 0;
  text-align: center;
  list-style: none;
  *zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  line-height: 0;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager a,
.pager span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a,
.pager .next span {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover,
.pager .disabled span {
  color: #999999;
  cursor: default;
  background-color: #fff;
}

.modal-open .modal .dropdown-menu {
  z-index: 2050;
}

.modal-open .modal .dropdown.open {
  *z-index: 2050;
}

.modal-open .modal .popover {
  z-index: 2060;
}

.modal-open .modal .tooltip {
  z-index: 2080;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin: -250px 0 0 -280px;
  overflow: auto;
  background-color: #ffffff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.modal.fade {
  top: -25%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.fade.in {
  top: 50%;
}

.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
}

.modal-header .close {
  margin-top: 2px;
}

.modal-header h3 {
  margin: 0;
  line-height: 30px;
}

.modal-body {
  max-height: 400px;
  padding: 15px;
  overflow-y: auto;
}

.modal-form {
  margin-bottom: 0;
}

.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
}

.modal-footer:before,
.modal-footer:after {
  display: table;
  line-height: 0;
  content: "";
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
}

.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.tooltip.top {
  margin-top: -3px;
}

.tooltip.right {
  margin-left: 3px;
}

.tooltip.bottom {
  margin-top: 3px;
}

.tooltip.left {
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-right-color: #000000;
  border-width: 5px 5px 5px 0;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-left-color: #000000;
  border-width: 5px 0 5px 5px;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  width: 236px;
  padding: 1px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.popover.top {
  margin-bottom: 10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-right: 10px;
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover-content p,
.popover-content ul,
.popover-content ol {
  margin-bottom: 0;
}

.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow:after {
  z-index: -1;
  content: "";
}

.popover.top .arrow {
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  border-top-color: #ffffff;
  border-width: 10px 10px 0;
}

.popover.top .arrow:after {
  bottom: -1px;
  left: -11px;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 11px 11px 0;
}

.popover.right .arrow {
  top: 50%;
  left: -10px;
  margin-top: -10px;
  border-right-color: #ffffff;
  border-width: 10px 10px 10px 0;
}

.popover.right .arrow:after {
  bottom: -11px;
  left: -1px;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 11px 11px 11px 0;
}

.popover.bottom .arrow {
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-bottom-color: #ffffff;
  border-width: 0 10px 10px;
}

.popover.bottom .arrow:after {
  top: -1px;
  left: -11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 11px 11px;
}

.popover.left .arrow {
  top: 50%;
  right: -10px;
  margin-top: -10px;
  border-left-color: #ffffff;
  border-width: 10px 0 10px 10px;
}

.popover.left .arrow:after {
  right: -1px;
  bottom: -11px;
  border-left-color: rgba(0, 0, 0, 0.25);
  border-width: 11px 0 11px 11px;
}

.thumbnails {
  margin-left: -20px;
  list-style: none;
  *zoom: 1;
}

.thumbnails:before,
.thumbnails:after {
  display: table;
  line-height: 0;
  content: "";
}

.thumbnails:after {
  clear: both;
}

.row-fluid .thumbnails {
  margin-left: 0;
}

.thumbnails > li {
  float: left;
  margin-bottom: 20px;
  margin-left: 20px;
}

.thumbnail {
  display: block;
  padding: 4px;
  line-height: 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a.thumbnail:hover {
  border-color: #0088cc;
  -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}

.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.thumbnail .caption {
  padding: 9px;
  color: #555555;
}

.label,
.badge {
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999999;
}

.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.badge {
  padding: 1px 9px 2px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

a.label:hover,
a.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.label-important,
.badge-important {
  background-color: #b94a48;
}

.label-important[href],
.badge-important[href] {
  background-color: #953b39;
}

.label-warning,
.badge-warning {
  background-color: #f89406;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

.label-success,
.badge-success {
  background-color: #468847;
}

.label-success[href],
.badge-success[href] {
  background-color: #356635;
}

.label-info,
.badge-info {
  background-color: #3a87ad;
}

.label-info[href],
.badge-info[href] {
  background-color: #2d6987;
}

.label-inverse,
.badge-inverse {
  background-color: #333333;
}

.label-inverse[href],
.badge-inverse[href] {
  background-color: #1a1a1a;
}

.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}

.btn-mini .label,
.btn-mini .badge {
  top: 0;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress .bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.progress-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-danger .bar,
.progress .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}

.progress-danger.progress-striped .bar,
.progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-success .bar,
.progress .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}

.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-info .bar,
.progress .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}

.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-warning .bar,
.progress .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}

.progress-warning.progress-striped .bar,
.progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.accordion {
  margin-bottom: 20px;
}

.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.accordion-heading {
  border-bottom: 0;
}

.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}

.accordion-toggle {
  cursor: pointer;
}

.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}

.carousel {
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel .item > img {
  display: block;
  line-height: 1;
}

.carousel .active,
.carousel .next,
.carousel .prev {
  display: block;
}

.carousel .active {
  left: 0;
}

.carousel .next,
.carousel .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel .next {
  left: 100%;
}

.carousel .prev {
  left: -100%;
}

.carousel .next.left,
.carousel .prev.right {
  left: 0;
}

.carousel .active.left {
  left: -100%;
}

.carousel .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  background: #222222;
  border: 3px solid #ffffff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.carousel-control.right {
  right: 15px;
  left: auto;
}

.carousel-control:hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}

.carousel-caption h4,
.carousel-caption p {
  line-height: 20px;
  color: #ffffff;
}

.carousel-caption h4 {
  margin: 0 0 5px;
}

.carousel-caption p {
  margin-bottom: 0;
}

.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  background-color: #eeeeee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
  color: inherit;
}

.hero-unit p {
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  color: inherit;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.affix {
  position: fixed;
}

/*!
 * Bootstrap Responsive v2.2.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}

.clearfix:after {
  clear: both;
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visible-phone {
  display: none !important;
}

.visible-tablet {
  display: none !important;
}

.hidden-desktop {
  display: none !important;
}

.visible-desktop {
  display: inherit !important;
}

@media (min-width: 768px) and (max-width: 979px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-tablet {
    display: inherit !important;
  }
  .hidden-tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-phone {
    display: inherit !important;
  }
  .hidden-phone {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .row {
    margin-left: -30px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    line-height: 0;
    content: "";
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 940px;
  }
  .span12 {
    width: 940px;
  }
  .span11 {
    width: 860px;
  }
  .span10 {
    width: 780px;
  }
  .span9 {
    width: 700px;
  }
  .span8 {
    width: 620px;
  }
  .span7 {
    width: 540px;
  }
  .span6 {
    width: 460px;
  }
  .span5 {
    width: 380px;
  }
  .span4 {
    width: 300px;
  }
  .span3 {
    width: 220px;
  }
  .span2 {
    width: 140px;
  }
  .span1 {
    width: 60px;
  }
  .offset12 {
    margin-left: 1230px;
  }
  .offset11 {
    margin-left: 1130px;
  }
  .offset10 {
    margin-left: 1030px;
  }
  .offset9 {
    margin-left: 930px;
  }
  .offset8 {
    margin-left: 830px;
  }
  .offset7 {
    margin-left: 730px;
  }
  .offset6 {
    margin-left: 630px;
  }
  .offset5 {
    margin-left: 530px;
  }
  .offset4 {
    margin-left: 430px;
  }
  .offset3 {
    margin-left: 310px;
  }
  .offset2 {
    margin-left: 230px;
  }
  .offset1 {
    margin-left: 130px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    line-height: 0;
    content: "";
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.564102564102564%;
    *margin-left: 2.5109110747408616%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851063829%;
  }
  .row-fluid .span11 {
    width: 91.45299145299145%;
    *width: 91.39979996362975%;
  }
  .row-fluid .span10 {
    width: 82.90598290598291%;
    *width: 82.8527914166212%;
  }
  .row-fluid .span9 {
    width: 74.35897435897436%;
    *width: 74.30578286961266%;
  }
  .row-fluid .span8 {
    width: 65.81196581196582%;
    *width: 65.75877432260411%;
  }
  .row-fluid .span7 {
    width: 57.26495726495726%;
    *width: 57.21176577559556%;
  }
  .row-fluid .span6 {
    width: 48.664757228587014%;
    *width: 48.664757228587014%;
  }
  .row-fluid .span5 {
    width: 40.17094017094017%;
    *width: 40.11774868157847%;
  }
  .row-fluid .span4 {
    width: 31.570740134569924%;
    *width: 31.570740134569924%;
  }
  .row-fluid .span3 {
    width: 23.023731587561375%;
    *width: 23.023731587561375%;
  }
  .row-fluid .span2 {
    width: 14.52991452991453%;
    *width: 14.476723040552828%;
  }
  .row-fluid .span1 {
    width: 5.982905982905983%;
    *width: 5.929714493544281%;
  }
  .row-fluid .offset12 {
    margin-left: 105.12820512820512%;
    *margin-left: 105.02182214948171%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.56410256410257%;
    *margin-left: 102.45771958537915%;
  }
  .row-fluid .offset11 {
    margin-left: 96.58119658119658%;
    *margin-left: 96.47481360247316%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 94.01709401709402%;
    *margin-left: 93.91071103837061%;
  }
  .row-fluid .offset10 {
    margin-left: 88.03418803418803%;
    *margin-left: 87.92780505546462%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.47008547008548%;
    *margin-left: 85.36370249136206%;
  }
  .row-fluid .offset9 {
    margin-left: 79.48717948717949%;
    *margin-left: 79.38079650845607%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 76.92307692307693%;
    *margin-left: 76.81669394435352%;
  }
  .row-fluid .offset8 {
    margin-left: 70.94017094017094%;
    *margin-left: 70.83378796144753%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.37606837606839%;
    *margin-left: 68.26968539734497%;
  }
  .row-fluid .offset7 {
    margin-left: 62.393162393162385%;
    *margin-left: 62.28677941443899%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.82905982905982%;
    *margin-left: 59.72267685033642%;
  }
  .row-fluid .offset6 {
    margin-left: 53.84615384615384%;
    *margin-left: 53.739770867430444%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.28205128205128%;
    *margin-left: 51.175668303327875%;
  }
  .row-fluid .offset5 {
    margin-left: 45.299145299145295%;
    *margin-left: 45.1927623204219%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.73504273504273%;
    *margin-left: 42.62865975631933%;
  }
  .row-fluid .offset4 {
    margin-left: 36.75213675213675%;
    *margin-left: 36.645753773413354%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.18803418803419%;
    *margin-left: 34.081651209310785%;
  }
  .row-fluid .offset3 {
    margin-left: 28.205128205128204%;
    *margin-left: 28.0987452264048%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.641025641025642%;
    *margin-left: 25.53464266230224%;
  }
  .row-fluid .offset2 {
    margin-left: 19.65811965811966%;
    *margin-left: 19.551736679396257%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.094017094017094%;
    *margin-left: 16.98763411529369%;
  }
  .row-fluid .offset1 {
    margin-left: 11.11111111111111%;
    *margin-left: 11.004728132387708%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.547008547008547%;
    *margin-left: 8.440625568285142%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 30px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 1156px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 1056px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 956px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 856px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 756px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 656px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 556px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 456px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 356px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 256px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 156px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 56px;
  }
  .thumbnails {
    margin-left: -30px;
  }
  .thumbnails > li {
    margin-left: 30px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .row {
    margin-left: -20px;
    *zoom: 1;
  }
  .row:before,
  .row:after {
    display: table;
    line-height: 0;
    content: "";
  }
  .row:after {
    clear: both;
  }
  [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 20px;
  }
  .container,
  .navbar-static-top .container,
  .navbar-fixed-top .container,
  .navbar-fixed-bottom .container {
    width: 724px;
  }
  .span12 {
    width: 724px;
  }
  .span11 {
    width: 662px;
  }
  .span10 {
    width: 600px;
  }
  .span9 {
    width: 538px;
  }
  .span8 {
    width: 476px;
  }
  .span7 {
    width: 414px;
  }
  .span6 {
    width: 352px;
  }
  .span5 {
    width: 290px;
  }
  .span4 {
    width: 228px;
  }
  .span3 {
    width: 166px;
  }
  .span2 {
    width: 104px;
  }
  .span1 {
    width: 42px;
  }
  .offset12 {
    margin-left: 764px;
  }
  .offset11 {
    margin-left: 702px;
  }
  .offset10 {
    margin-left: 640px;
  }
  .offset9 {
    margin-left: 578px;
  }
  .offset8 {
    margin-left: 516px;
  }
  .offset7 {
    margin-left: 454px;
  }
  .offset6 {
    margin-left: 392px;
  }
  .offset5 {
    margin-left: 330px;
  }
  .offset4 {
    margin-left: 268px;
  }
  .offset3 {
    margin-left: 206px;
  }
  .offset2 {
    margin-left: 144px;
  }
  .offset1 {
    margin-left: 82px;
  }
  .row-fluid {
    width: 100%;
    *zoom: 1;
  }
  .row-fluid:before,
  .row-fluid:after {
    display: table;
    line-height: 0;
    content: "";
  }
  .row-fluid:after {
    clear: both;
  }
  .row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.7624309392265194%;
    *margin-left: 2.709239449864817%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851063829%;
  }
  .row-fluid .span11 {
    width: 91.43646408839778%;
    *width: 91.38327259903608%;
  }
  .row-fluid .span10 {
    width: 82.87292817679558%;
    *width: 82.81973668743387%;
  }
  .row-fluid .span9 {
    width: 74.30939226519337%;
    *width: 74.25620077583166%;
  }
  .row-fluid .span8 {
    width: 65.74585635359117%;
    *width: 65.69266486422946%;
  }
  .row-fluid .span7 {
    width: 57.18232044198895%;
    *width: 57.12912895262725%;
  }
  .row-fluid .span6 {
    width: 48.61878453038674%;
    *width: 48.56559304102504%;
  }
  .row-fluid .span5 {
    width: 40.05524861878453%;
    *width: 40.00205712942283%;
  }
  .row-fluid .span4 {
    width: 31.491712707182323%;
    *width: 31.43852121782062%;
  }
  .row-fluid .span3 {
    width: 22.92817679558011%;
    *width: 22.87498530621841%;
  }
  .row-fluid .span2 {
    width: 14.3646408839779%;
    *width: 14.311449394616199%;
  }
  .row-fluid .span1 {
    width: 5.801104972375691%;
    *width: 5.747913483013988%;
  }
  .row-fluid .offset12 {
    margin-left: 105.52486187845304%;
    *margin-left: 105.41847889972962%;
  }
  .row-fluid .offset12:first-child {
    margin-left: 102.76243093922652%;
    *margin-left: 102.6560479605031%;
  }
  .row-fluid .offset11 {
    margin-left: 96.96132596685082%;
    *margin-left: 96.8549429881274%;
  }
  .row-fluid .offset11:first-child {
    margin-left: 94.1988950276243%;
    *margin-left: 94.09251204890089%;
  }
  .row-fluid .offset10 {
    margin-left: 88.39779005524862%;
    *margin-left: 88.2914070765252%;
  }
  .row-fluid .offset10:first-child {
    margin-left: 85.6353591160221%;
    *margin-left: 85.52897613729868%;
  }
  .row-fluid .offset9 {
    margin-left: 79.8342541436464%;
    *margin-left: 79.72787116492299%;
  }
  .row-fluid .offset9:first-child {
    margin-left: 77.07182320441989%;
    *margin-left: 76.96544022569647%;
  }
  .row-fluid .offset8 {
    margin-left: 71.2707182320442%;
    *margin-left: 71.16433525332079%;
  }
  .row-fluid .offset8:first-child {
    margin-left: 68.50828729281768%;
    *margin-left: 68.40190431409427%;
  }
  .row-fluid .offset7 {
    margin-left: 62.70718232044199%;
    *margin-left: 62.600799341718584%;
  }
  .row-fluid .offset7:first-child {
    margin-left: 59.94475138121547%;
    *margin-left: 59.838368402492065%;
  }
  .row-fluid .offset6 {
    margin-left: 54.14364640883978%;
    *margin-left: 54.037263430116376%;
  }
  .row-fluid .offset6:first-child {
    margin-left: 51.38121546961326%;
    *margin-left: 51.27483249088986%;
  }
  .row-fluid .offset5 {
    margin-left: 45.58011049723757%;
    *margin-left: 45.47372751851417%;
  }
  .row-fluid .offset5:first-child {
    margin-left: 42.81767955801105%;
    *margin-left: 42.71129657928765%;
  }
  .row-fluid .offset4 {
    margin-left: 37.01657458563536%;
    *margin-left: 36.91019160691196%;
  }
  .row-fluid .offset4:first-child {
    margin-left: 34.25414364640884%;
    *margin-left: 34.14776066768544%;
  }
  .row-fluid .offset3 {
    margin-left: 28.45303867403315%;
    *margin-left: 28.346655695309746%;
  }
  .row-fluid .offset3:first-child {
    margin-left: 25.69060773480663%;
    *margin-left: 25.584224756083227%;
  }
  .row-fluid .offset2 {
    margin-left: 19.88950276243094%;
    *margin-left: 19.783119783707537%;
  }
  .row-fluid .offset2:first-child {
    margin-left: 17.12707182320442%;
    *margin-left: 17.02068884448102%;
  }
  .row-fluid .offset1 {
    margin-left: 11.32596685082873%;
    *margin-left: 11.219583872105325%;
  }
  .row-fluid .offset1:first-child {
    margin-left: 8.56353591160221%;
    *margin-left: 8.457152932878806%;
  }
  input,
  textarea,
  .uneditable-input {
    margin-left: 0;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 20px;
  }
  input.span12,
  textarea.span12,
  .uneditable-input.span12 {
    width: 710px;
  }
  input.span11,
  textarea.span11,
  .uneditable-input.span11 {
    width: 648px;
  }
  input.span10,
  textarea.span10,
  .uneditable-input.span10 {
    width: 586px;
  }
  input.span9,
  textarea.span9,
  .uneditable-input.span9 {
    width: 524px;
  }
  input.span8,
  textarea.span8,
  .uneditable-input.span8 {
    width: 462px;
  }
  input.span7,
  textarea.span7,
  .uneditable-input.span7 {
    width: 400px;
  }
  input.span6,
  textarea.span6,
  .uneditable-input.span6 {
    width: 338px;
  }
  input.span5,
  textarea.span5,
  .uneditable-input.span5 {
    width: 276px;
  }
  input.span4,
  textarea.span4,
  .uneditable-input.span4 {
    width: 214px;
  }
  input.span3,
  textarea.span3,
  .uneditable-input.span3 {
    width: 152px;
  }
  input.span2,
  textarea.span2,
  .uneditable-input.span2 {
    width: 90px;
  }
  input.span1,
  textarea.span1,
  .uneditable-input.span1 {
    width: 28px;
  }
}

@media (max-width: 767px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin-right: -20px;
    margin-left: -20px;
  }
  .container-fluid {
    padding: 0;
    margin: 0;
    width: 100% !important;
  }
  .dl-horizontal dt {
    float: none;
    width: auto;
    clear: none;
    text-align: left;
  }
  .dl-horizontal dd {
    margin-left: 0;
  }
  .container {
    width: auto;
  }
  .row-fluid {
    width: 100%;
  }
  .row,
  .thumbnails {
    margin-left: 0;
  }
  .thumbnails > li {
    float: none;
    margin-left: 0;
  }
  [class*="span"],
  .row-fluid [class*="span"] {
    display: block;
    float: none;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .span12,
  .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .input-large,
  .input-xlarge,
  .input-xxlarge,
  input[class*="span"],
  select[class*="span"],
  textarea[class*="span"],
  .uneditable-input {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .input-prepend input,
  .input-append input,
  .input-prepend input[class*="span"],
  .input-append input[class*="span"] {
    display: inline-block;
    width: auto;
  }
  .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0;
  }
  .modal {
    position: fixed;
    top: 20px;
    right: 20px;
    left: 20px;
    width: auto;
    margin: 0;
  }
  .modal.fade.in {
    top: auto;
  }
}

@media (max-width: 480px) {
  .nav-collapse {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .page-header h1 small {
    display: block;
    line-height: 20px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    border: 1px solid #ccc;
  }
  .form-horizontal .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .form-horizontal .control-list {
    padding-top: 0;
  }
  .form-horizontal .form-actions {
    padding-right: 10px;
    padding-left: 10px;
  }
  .modal {
    top: 10px;
    right: 10px;
    left: 10px;
  }
  .modal-header .close {
    padding: 10px;
    margin: -10px;
  }
  .carousel-caption {
    position: static;
  }
}

@media (max-width: 979px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-right: 10px;
    padding-left: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777777;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .dropdown-menu a:hover {
    background-color: #f2f2f2;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover {
    background-color: #111111;
  }
  .nav-collapse.in .btn-group {
    padding: 0;
    margin-top: 5px;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    display: block;
    float: none;
    max-width: none;
    padding: 0;
    margin: 0 15px;
    background-color: transparent;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111111;
    border-bottom-color: #111111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 980px) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}

.tp-simpleresponsive .caption {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.tp-simpleresponsive img {
  max-width: none;
}

/******************************
	-	IE8 HACKS	-
*******************************/
.noFilterClass {
  filter: none !important;
}

/******************************
	-	SHADOWS		-
******************************/
.tp-bannershadow {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-bannershadow.tp-shadow1 {
  background: url(../assets/shadow1.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 30px;
  bottom: -30px;
}

.tp-bannershadow.tp-shadow2 {
  background: url(../assets/shadow2.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 60px;
  bottom: -60px;
}

.tp-bannershadow.tp-shadow3 {
  background: url(../assets/shadow3.png) no-repeat;
  background-size: 100%;
  width: 890px;
  height: 60px;
  bottom: -60px;
}

/********************************
	-	FULLSCREEN VIDEO	-
*********************************/
.caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.caption.fullscreenvideo iframe {
  width: 100% !important;
  height: 100% !important;
}

/*************************
	-	CAPTIONS	-
**************************/
.caption.big_white {
  position: absolute;
  color: #3b3b3b;
  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
}

.caption.big_orange {
  position: absolute;
  color: #8f8f8f;
  text-shadow: none;
  font-size: 15px;
  line-height: 24px;
  font-family: Calibri;
}

.caption.big_black {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 36px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  padding: 10px;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  background-color: #000;
  opacity: .6;
}

.caption.medium_grey {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: Arial;
  padding: 2px 4px;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  background-color: #888;
  white-space: nowrap;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.caption.small_text {
  position: absolute;
  color: #8f8f8f;
  font-size: 12px;
  line-height: 24px;
  font-family: georgia;
  font-style: italic;
}

.caption.medium_text {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: Arial;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  white-space: nowrap;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.caption.large_text {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  font-family: Arial;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  white-space: nowrap;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.caption.very_large_text {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  font-family: Arial;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  white-space: nowrap;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  letter-spacing: -2px;
}

.caption.very_big_white {
  position: absolute;
  color: #fff;
  text-shadow: none;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  font-family: Arial;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  white-space: nowrap;
  padding: 0px 4px;
  padding-top: 1px;
  background-color: #000;
}

.caption.very_big_black {
  position: absolute;
  color: #000;
  text-shadow: none;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  font-family: Arial;
  margin: 0px;
  border-width: 0px;
  border-style: none;
  white-space: nowrap;
  padding: 0px 4px;
  padding-top: 1px;
  background-color: #fff;
}

.caption.boxshadow {
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.caption.black {
  color: #000;
  text-shadow: none;
}

.caption.noshadow {
  text-shadow: none;
}

.caption a {
  color: #ff7302;
  text-shadow: none;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.caption a:hover {
  color: #ffa902;
}

/******************************
	-	BUTTONS	-
*******************************/
.button {
  padding: 15px 12px 15px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  cursor: pointer;
  color: #fdfdfd !important;
  font-size: 11px;
  font-family: arial;
  font-weight: bold;
}

.button.big {
  color: #fff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  padding: 9px 20px;
  font-size: 19px;
  line-height: 57px !important;
  background: url(gradient/g40.png) repeat-x top;
}

.purchase:hover,
.button:hover,
.button.big:hover {
  opacity: 0.8;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .button {
    padding: 4px 8px 3px;
    line-height: 25px !important;
    font-size: 11px !important;
    font-weight: normal;
  }
  a.button {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .button {
    padding: 2px 5px 2px;
    line-height: 20px !important;
    font-size: 10px !important;
  }
  a.button {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
  }
}

/*	BUTTON COLORS	*/
.button.green, .button:hover.green,
.purchase.green, .purchase:hover.green {
  background-color: #0ad1e5;
}

.button.blue, .button:hover.blue,
.purchase.blue, .purchase:hover.blue {
  background-color: #1d78cb;
  -webkit-box-shadow: 0px 3px 0px 0px #0f3e68;
  -moz-box-shadow: 0px 3px 0px 0px #0f3e68;
  box-shadow: 0px 3px 0px 0px #0f3e68;
}

.button.red, .button:hover.red,
.purchase.red, .purchase:hover.red {
  background-color: #cb1d1d;
  -webkit-box-shadow: 0px 3px 0px 0px #7c1212;
  -moz-box-shadow: 0px 3px 0px 0px #7c1212;
  box-shadow: 0px 3px 0px 0px #7c1212;
}

.button.orange, .button:hover.orange,
.purchase.orange, .purchase:hover.orange {
  background-color: #ff7700;
  -webkit-box-shadow: 0px 3px 0px 0px #a34c00;
  -moz-box-shadow: 0px 3px 0px 0px #a34c00;
  box-shadow: 0px 3px 0px 0px #a34c00;
}

.button.darkgrey, .button.grey,
.button:hover.darkgrey, .button:hover.grey,
.purchase.darkgrey, .purchase:hover.darkgrey {
  background-color: #8d8d8d;
}

.button.lightgrey, .button:hover.lightgrey,
.purchase.lightgrey, .purchase:hover.lightgrey {
  background-color: #888;
  -webkit-box-shadow: 0px 3px 0px 0px #555;
  -moz-box-shadow: 0px 3px 0px 0px #555;
  box-shadow: 0px 3px 0px 0px #555;
}

/************************
	-	NAVIGATION	-
*************************/
/** BULLETS **/
.tp-bullets {
  z-index: 100;
  position: absolute;
  bottom: 10px;
}

.tp-bullets.simplebullets {
  bottom: 10px;
}

.tp-bullets.simplebullets.navbar {
  bottom: -20px;
  background: url(../assets/navigdots_bgtile.png);
  height: 35px;
  padding: 0px 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-top: -17px;
}

.tp-bullets.simplebullets .bullet {
  cursor: pointer;
  position: relative;
  background: url(../images/bullets2.png) no-Repeat bottom left;
  width: 19px;
  height: 18px;
  margin-right: 0px;
  float: left;
  margin-top: -12px;
}

.tp-bullets.simplebullets .bullet.last {
  margin-right: 0px;
}

/**	SQUARE BULLETS **/
.tp-bullets.simplebullets.square .bullet {
  cursor: pointer;
  position: relative;
  background: url(../assets/bullets2.png) no-Repeat bottom left;
  width: 19px;
  height: 19px;
  margin-right: 0px;
  float: left;
  margin-top: -10px;
}

.tp-bullets.simplebullets.square .bullet.last {
  margin-right: 0px;
}

/** navbar NAVIGATION VERSION **/
.tp-bullets.simplebullets.navbar .bullet {
  cursor: pointer;
  position: relative;
  background: url(../assets/navigdots.png) no-Repeat bottom left;
  width: 15px;
  height: 15px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  float: left;
  margin-top: 10px;
}

.tp-bullets.simplebullets.navbar .bullet.first {
  margin-left: 30px !important;
}

.tp-bullets.simplebullets.navbar .bullet.last {
  margin-right: 30px !important;
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
  background-position: top left;
}

/*************************************
	-	TP ARROWS 	-
**************************************/
.tp-leftarrow.large {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: #0ad1e5 url(prev-slide.png) center center no-repeat;
  width: 37px;
  height: 37px;
  margin-top: -23px;
}

.tp-rightarrow.large {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: #0ad1e5 url(next-slide.png) center center no-repeat;
  width: 37px;
  height: 37px;
  margin-top: -23px;
}

.tp-leftarrow.large:hover {
  opacity: 0.5;
}

.tp-rightarrow:hover {
  opacity: 0.5;
}

/****************************************************************************************************
	-	TP THUMBS 	-
*****************************************************************************************************

 - tp-thumbs & tp-mask Width is the width of the basic Thumb Container (500px basic settings)

 - .bullet width & height is the dimension of a simple Thumbnail (basic 100px x 50px)

 *****************************************************************************************************/
.tp-bullets.tp-thumbs {
  z-index: 100;
  position: absolute;
  padding: 0px 2px 2px;
  background-color: #fff;
  width: 940px;
  height: 50px;
  /* THE DIMENSIONS OF THE THUMB CONTAINER */
}

.fullwidthbanner-container .tp-thumbs {
  padding: 2px;
}

.tp-bullets.tp-thumbs .tp-mask {
  height: 50px;
  /* THE DIMENSIONS OF THE THUMB CONTAINER */
  overflow: hidden;
  position: relative;
}

.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer {
  width: 5000px;
  position: absolute;
}

.tp-bullets.tp-thumbs .bullet {
  width: 100px;
  height: 50px;
  /* THE DIMENSION OF A SINGLE THUMB */
  cursor: pointer;
  overflow: hidden;
  background: none;
  margin: 0;
  float: left;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  /*filter: alpha(opacity=50);	*/
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.tp-bullets.tp-thumbs .bullet:hover,
.tp-bullets.tp-thumbs .bullet.selected {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.tp-thumbs img {
  width: 100%;
}

/************************************
		-	TP BANNER TIMER		-
*************************************/
.tp-bannertimer {
  width: 100%;
  height: 10px;
  position: absolute;
  z-index: 200;
}

.tp-bannertimer.tp-bottom {
  bottom: 0px !important;
  height: 5px;
}

/***************************************
	-	RESPONSIVE SETTINGS 	-
****************************************/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .responsive .tp-bullets {
    display: none;
  }
  .responsive .tparrows {
    display: none;
  }
  .bannercontainer {
    margin-bottom: 0 !important;
  }
}

/*********************************************

	-	BASIC SETTINGS FOR THE BANNER	-

***********************************************/
.tp-simpleresponsive img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-simpleresponsive a {
  text-decoration: none;
}

.tp-simpleresponsive ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tp-simpleresponsive > ul > li {
  list-stye: none;
  position: absolute;
  visibility: hidden;
}

/*  CAPTION SLIDELINK   **/
.caption.slidelink a div {
  width: 10000px;
  height: 10000px;
}

@media (min-width: 768px) and (max-width: 979px) {
  #top {
    width: 724px;
  }
  #menu nav {
    width: 724px;
  }
  #recent .left {
    width: 48.6%;
  }
  #recent .left:nth-child(3n) {
    margin-right: 0px;
  }
  .flicker-images a:nth-child(3n) {
    margin-right: 11px;
  }
  ul.tab-links li {
    width: 32.63% !important;
  }
  #contact input[type="text"],
  #contact-form2 input[type="text"] {
    width: 19% !important;
  }
  #contact textarea,
  #contact-form2 textarea {
    width: 95%;
  }
  ul.member-post li a {
    font-size: 11px;
    padding: 12px 5px;
  }
  .section_slider_1 .nivo-caption {
    display: none !important;
  }
  .section_slider_6 .caption {
    display: none;
  }
  .banner .caption {
    display: none;
  }
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

@media (max-width: 768px) {
  #top-line article {
    padding: 0 20px !important;
  }
  #top-line article a {
    right: 20px;
  }
  #top {
    width: 100% !important;
  }
  #top #logo {
    margin-left: 20px;
  }
  #top aside {
    margin-right: 20px !important;
  }
  #menu {
    padding: 0 20px;
  }
  #menu nav {
    width: 100%;
  }
  #menu nav li a {
    padding-right: 15px;
  }
  header nav ul li:not(.active):hover {
    border-bottom: none !important;
  }
  header nav > ul.sf-menu {
    display: none;
  }
  header nav.navigation .sbHolder {
    display: block;
  }
  nav ul li {
    display: block !important;
  }
  section.slider {
    height: 100%;
  }
  .slider {
    padding: 20px 20px !important;
  }
  .singlework .slider {
    padding: 20px 0px !important;
  }
  .flex-control-nav {
    display: none !important;
  }
  #recent .left {
    width: 47.2%;
  }
  .innerfooter {
    margin-right: 20px !important;
  }
  #recent .left:nth-child(3n) {
    margin-right: 0px;
  }
  .images span {
    width: 19.5% !important;
  }
  .innerfooter article:first-child {
    margin-left: 20px;
  }
  .Twiter p {
    width: 85%;
    margin-left: 30px;
    float: none;
  }
  .flicker-images a:nth-child(3n) {
    margin-right: 11px;
  }
  .back-to-top p {
    margin-left: 20px;
  }
  .cont-head {
    padding: 0 20px;
  }
  #contact input[type="text"],
  #contact-form2 input[type="text"] {
    width: 70% !important;
    padding: 12px 1.6%;
    margin-right: 0 !important;
  }
  #contact textarea,
  #contact-form2 textarea {
    width: 98% !important;
  }
  #contact label,
  #contact-form2 label {
    width: 8%;
    height: 36px;
    clear: both;
  }
  #contact input[type="submit"],
  #contact-form2 input[type="submit"] {
    margin-right: 0;
  }
  ul.member-post li a span {
    display: none;
  }
  ul.member-post li a img {
    margin-right: 0;
  }
  .paragraf p {
    width: 420px !important;
  }
  .callout .link:before {
    display: none;
  }
  .section_slider_1 .nivo-caption {
    display: none !important;
  }
  .section_slider_1 {
    padding: 0 20px 0 20px !important;
  }
  .section_slider_6 .caption {
    display: none;
  }
  .section_slider_6 {
    padding: 0 20px 0 20px !important;
  }
  .banner .caption {
    display: none;
  }
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

@media (max-width: 600px) {
  #top #logo {
    float: none;
    margin-bottom: 15px !important;
    margin-left: 0 !important;
    text-align: center;
  }
  #top aside {
    float: none;
    margin-bottom: 15px !important;
    margin-right: 0px !important;
  }
  #top aside form {
    float: none;
  }
  #top #search-bar {
    position: relative;
    margin: auto;
    width: 246px;
  }
  #top aside ul {
    float: none;
    text-align: center;
    margin-left: 110px;
  }
  #top {
    margin-bottom: 10px;
  }
  .ticker-wrapper.has-js {
    display: none;
  }
  #inner-content article blockquote {
    margin-bottom: 20px;
    font-size: 16px;
  }
  #inner-content article a {
    margin-top: 0;
  }
  .main3 .descript ul li {
    width: 100%;
    margin-bottom: 20px;
  }
  #comments ul li.depth1 ul li {
    margin-left: 0;
  }
  ul.tab-list li a {
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    height: 12px;
    padding: 10px 3px 6px;
    font-size: 10px;
  }
  .cont-text p {
    display: none;
  }
  .section_slider_1 .nivo-caption {
    display: none !important;
  }
  .banner .caption {
    display: none;
  }
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

@media (max-width: 400px) {
  #recent .left {
    width: 100%;
    margin-right: 0;
  }
  #link-up,
  #link-down {
    display: none;
  }
  .fullwidthbanner-container .caption.big_black {
    font-size: 16px;
  }
  .col3 {
    width: 100%;
    margin-right: 0;
  }
  .section_slider_1 .nivo-caption {
    display: none !important;
  }
  .section_slider_6 .caption {
    display: none;
  }
  .banner .caption {
    display: none;
  }
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

@media (max-width: 500px) {
  .col2 {
    width: 97%;
    margin-right: 0;
  }
  .section_slider_1 .nivo-caption {
    display: none !important;
  }
  .section_slider_6 .caption {
    display: none;
  }
  .banner .caption {
    display: none;
  }
  .responsive .tp-bullets.tp-thumbs {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .tp-mask {
    width: 300px !important;
    height: 30px !important;
  }
  .responsive .tp-bullets.tp-thumbs .bullet {
    width: 60px !important;
    height: 30px !important;
  }
}

/* Glyphicons
====================================*/
.the-icons li {
  margin: 0 0 -15px -20px;
  padding: 0;
  line-height: 0;
}

.the-icons b {
  margin-top: 24px;
  position: absolute;
}

[class^="ico-"],
[class*="ico-"] {
  display: inline-block;
  width: 48px;
  height: 48px;
  *margin-right: .3em;
  line-height: 48px;
  vertical-align: text-top;
  background-image: url("../images/icons/glyphicons.png");
  background-position: 48px 48px;
  background-repeat: no-repeat;
}

[class^="ico-"]:last-child,
[class*="ico-"]:last-child {
  *margin-left: 0;
}

.ico-white {
  background-image: url("../images/icons/glyphicons-white.png");
  opacity: 1;
}

.ico-glass {
  background-position: -0px -0px;
}

.ico-leaf {
  background-position: -48px -0px;
}

.ico-dog {
  background-position: -96px -0px;
}

.ico-user {
  background-position: -144px -0px;
}

.ico-girl {
  background-position: -192px -0px;
}

.ico-car {
  background-position: -240px -0px;
}

.ico-user-add {
  background-position: -288px -0px;
}

.ico-user-remove {
  background-position: -336px -0px;
}

.ico-film {
  background-position: -384px -0px;
}

.ico-magic {
  background-position: -432px -0px;
}

.ico-envelope {
  background-position: -0px -48px;
}

.ico-camera {
  background-position: -48px -48px;
}

.ico-heart {
  background-position: -96px -48px;
}

.ico-beach-umbrella {
  background-position: -144px -48px;
}

.ico-train {
  background-position: -192px -48px;
}

.ico-print {
  background-position: -240px -48px;
}

.ico-bin {
  background-position: -288px -48px;
}

.ico-music {
  background-position: -336px -48px;
}

.ico-note {
  background-position: -384px -48px;
}

.ico-cogwheel {
  background-position: -432px -48px;
}

.ico-home {
  background-position: -0px -96px;
}

.ico-snowflake {
  background-position: -48px -96px;
}

.ico-fire {
  background-position: -96px -96px;
}

.ico-cogwheels {
  background-position: -144px -96px;
}

.ico-parents {
  background-position: -192px -96px;
}

.ico-binoculars {
  background-position: -240px -96px;
}

.ico-road {
  background-position: -288px -96px;
}

.ico-search {
  background-position: -336px -96px;
}

.ico-cars {
  background-position: -384px -96px;
}

.ico-notes-2 {
  background-position: -432px -96px;
}

.ico-pencil {
  background-position: -0px -144px;
}

.ico-bus {
  background-position: -48px -144px;
}

.ico-wifi-alt {
  background-position: -96px -144px;
}

.ico-luggage {
  background-position: -144px -144px;
}

.ico-old-man {
  background-position: -192px -144px;
}

.ico-woman {
  background-position: -240px -144px;
}

.ico-file {
  background-position: -288px -144px;
}

.ico-credit {
  background-position: -336px -144px;
}

.ico-airplane {
  background-position: -384px -144px;
}

.ico-notes {
  background-position: -432px -144px;
}

.ico-stats {
  background-position: -0px -192px;
}

.ico-charts {
  background-position: -48px -192px;
}

.ico-pie-chart {
  background-position: -96px -192px;
}

.ico-group {
  background-position: -144px -192px;
}

.ico-keys {
  background-position: -192px -192px;
}

.ico-calendar {
  background-position: -240px -192px;
}

.ico-router {
  background-position: -288px -192px;
}

.ico-camera-small {
  background-position: -336px -192px;
}

.ico-dislikes {
  background-position: -384px -192px;
}

.ico-star {
  background-position: -432px -192px;
}

.ico-link {
  background-position: -0px -240px;
}

.ico-eye-open {
  background-position: -48px -240px;
}

.ico-eye-close {
  background-position: -96px -240px;
}

.ico-alarm {
  background-position: -144px -240px;
}

.ico-clock {
  background-position: -192px -240px;
}

.ico-stopwatch {
  background-position: -240px -240px;
}

.ico-projector {
  background-position: -288px -240px;
}

.ico-history {
  background-position: -336px -240px;
}

.ico-truck {
  background-position: -384px -240px;
}

.ico-cargo {
  background-position: -432px -240px;
}

.ico-compass {
  background-position: -0px -288px;
}

.ico-keynote {
  background-position: -48px -288px;
}

.ico-attach {
  background-position: -96px -288px;
}

.ico-power {
  background-position: -144px -288px;
}

.ico-lightbulb {
  background-position: -192px -288px;
}

.ico-tag {
  background-position: -240px -288px;
}

.ico-tags {
  background-position: -288px -288px;
}

.ico-cleaning {
  background-position: -336px -288px;
}

.ico-ruller {
  background-position: -384px -288px;
}

.ico-gift {
  background-position: -432px -288px;
}

.ico-umbrella {
  background-position: -0px -336px;
}

.ico-book {
  background-position: -48px -336px;
}

.ico-bookmark {
  background-position: -96px -336px;
}

.ico-signal-alt {
  background-position: -144px -336px;
}

.ico-cup {
  background-position: -192px -336px;
}

.ico-stroller {
  background-position: -240px -336px;
}

.ico-headphones {
  background-position: -288px -336px;
}

.ico-headset {
  background-position: -336px -336px;
}

.ico-warning-sign {
  background-position: -384px -336px;
}

.ico-signal {
  background-position: -432px -336px;
}

.ico-retweet {
  background-position: -0px -384px;
}

.ico-refresh {
  background-position: -48px -384px;
}

.ico-roundabout {
  background-position: -96px -384px;
}

.ico-random {
  background-position: -144px -384px;
}

.ico-heat {
  background-position: -192px -384px;
}

.ico-repeat {
  background-position: -240px -384px;
}

.ico-display {
  background-position: -288px -384px;
}

.ico-log-book {
  background-position: -336px -384px;
}

.ico-adress-book {
  background-position: -384px -384px;
}

.ico-magnet {
  background-position: -432px -384px;
}

.ico-table {
  background-position: -0px -432px;
}

.ico-adjust {
  background-position: -48px -432px;
}

.ico-tint {
  background-position: -96px -432px;
}

.ico-crop {
  background-position: -144px -432px;
}

.ico-vector-path-square {
  background-position: -192px -432px;
}

.ico-vector-path-circle {
  background-position: -240px -432px;
}

.ico-vector-path-polygon {
  background-position: -288px -432px;
}

.ico-vector-path-line {
  background-position: -336px -432px;
}

.ico-vector-path-curve {
  background-position: -384px -432px;
}

.ico-vector-path-all {
  background-position: -432px -432px;
}

.ico-font {
  background-position: -0px -480px;
}

.ico-italic {
  background-position: -48px -480px;
}

.ico-bold {
  background-position: -96px -480px;
}

.ico-text-underline {
  background-position: -144px -480px;
}

.ico-text-strike {
  background-position: -192px -480px;
}

.ico-text-height {
  background-position: -240px -480px;
}

.ico-text-width {
  background-position: -288px -480px;
}

.ico-text-resize {
  background-position: -336px -480px;
}

.ico-left-indent {
  background-position: -384px -480px;
}

.ico-right-indent {
  background-position: -432px -480px;
}

.ico-align-left {
  background-position: -0px -528px;
}

.ico-align-center {
  background-position: -48px -528px;
}

.ico-align-right {
  background-position: -96px -528px;
}

.ico-justify {
  background-position: -144px -528px;
}

.ico-list {
  background-position: -192px -528px;
}

.ico-text-smaller {
  background-position: -240px -528px;
}

.ico-text-bugger {
  background-position: -288px -528px;
}

.ico-embed {
  background-position: -336px -528px;
}

.ico-embed-close {
  background-position: -384px -528px;
}

.ico-adjust-alt {
  background-position: -432px -528px;
}

.ico-message-full {
  background-position: -0px -576px;
}

.ico-message-empty {
  background-position: -48px -576px;
}

.ico-message-in {
  background-position: -96px -576px;
}

.ico-message-out {
  background-position: -144px -576px;
}

.ico-message-plus {
  background-position: -192px -576px;
}

.ico-message-minus {
  background-position: -240px -576px;
}

.ico-message-ban {
  background-position: -288px -576px;
}

.ico-message-flag {
  background-position: -336px -576px;
}

.ico-message-lock {
  background-position: -384px -576px;
}

.ico-message-new {
  background-position: -432px -576px;
}

.ico-inbox {
  background-position: -0px -624px;
}

.ico-inbox-plus {
  background-position: -48px -624px;
}

.ico-inbox-minus {
  background-position: -96px -624px;
}

.ico-inbox-lock {
  background-position: -144px -624px;
}

.ico-inbox-in {
  background-position: -192px -624px;
}

.ico-inbox-out {
  background-position: -240px -624px;
}

.ico-computer-locked {
  background-position: -288px -624px;
}

.ico-computer-service {
  background-position: -336px -624px;
}

.ico-computer-process {
  background-position: -384px -624px;
}

.ico-phone {
  background-position: -432px -624px;
}

.ico-database-lock {
  background-position: -0px -672px;
}

.ico-database-plus {
  background-position: -48px -672px;
}

.ico-database-minus {
  background-position: -96px -672px;
}

.ico-database-ban {
  background-position: -144px -672px;
}

.ico-folder-open {
  background-position: -192px -672px;
}

.ico-folder-plus {
  background-position: -240px -672px;
}

.ico-folder-minus {
  background-position: -288px -672px;
}

.ico-folder-lock {
  background-position: -336px -672px;
}

.ico-folder-flag {
  background-position: -384px -672px;
}

.ico-folder-new {
  background-position: -432px -672px;
}

.ico-check {
  background-position: -0px -720px;
}

.ico-edit {
  background-position: -48px -720px;
}

.ico-new-window {
  background-position: -96px -720px;
}

.ico-more-windows {
  background-position: -144px -720px;
}

.ico-show-big-thumbnails {
  background-position: -192px -720px;
}

.ico-show-thumbnails {
  background-position: -240px -720px;
}

.ico-show-thumbnails-lines {
  background-position: -288px -720px;
}

.ico-show-lines {
  background-position: -336px -720px;
}

.ico-playlist {
  background-position: -384px -720px;
}

.ico-picture {
  background-position: -432px -720px;
}

.ico-imac {
  background-position: -0px -768px;
}

.ico-macbook {
  background-position: -48px -768px;
}

.ico-ipad {
  background-position: -96px -768px;
}

.ico-iphone {
  background-position: -144px -768px;
}

.ico-iphone-transfer {
  background-position: -192px -768px;
}

.ico-iphone-exchange {
  background-position: -240px -768px;
}

.ico-ipod {
  background-position: -288px -768px;
}

.ico-ipod-shuffle {
  background-position: -336px -768px;
}

.ico-ear-plugs {
  background-position: -384px -768px;
}

.ico-albums {
  background-position: -432px -768px;
}

.ico-step-backward {
  background-position: -0px -816px;
}

.ico-fast-backward {
  background-position: -48px -816px;
}

.ico-rewind {
  background-position: -96px -816px;
}

.ico-play {
  background-position: -144px -816px;
}

.ico-pause {
  background-position: -192px -816px;
}

.ico-stop {
  background-position: -240px -816px;
}

.ico-forward {
  background-position: -288px -816px;
}

.ico-fast-forward {
  background-position: -336px -816px;
}

.ico-step-forward {
  background-position: -384px -816px;
}

.ico-eject {
  background-position: -432px -816px;
}

.ico-facetime-video {
  background-position: -0px -864px;
}

.ico-download-alt {
  background-position: -48px -864px;
}

.ico-mute {
  background-position: -96px -864px;
}

.ico-volume-up {
  background-position: -144px -864px;
}

.ico-volume-down {
  background-position: -192px -864px;
}

.ico-screenshot {
  background-position: -240px -864px;
}

.ico-move {
  background-position: -288px -864px;
}

.ico-more {
  background-position: -336px -864px;
}

.ico-brightness-reduce {
  background-position: -384px -864px;
}

.ico-brightness-increase {
  background-position: -432px -864px;
}

.ico-circle-plus {
  background-position: -0px -912px;
}

.ico-circle-minus {
  background-position: -48px -912px;
}

.ico-circle-remove {
  background-position: -96px -912px;
}

.ico-circle-ok {
  background-position: -144px -912px;
}

.ico-circle-question-mark {
  background-position: -192px -912px;
}

.ico-circle-info {
  background-position: -240px -912px;
}

.ico-circle-exclamation-mark {
  background-position: -288px -912px;
}

.ico-remove {
  background-position: -336px -912px;
}

.ico-ok {
  background-position: -384px -912px;
}

.ico-ban {
  background-position: -432px -912px;
}

.ico-download {
  background-position: -0px -960px;
}

.ico-upload {
  background-position: -48px -960px;
}

.ico-shopping-cart {
  background-position: -96px -960px;
}

.ico-lock {
  background-position: -144px -960px;
}

.ico-unlock {
  background-position: -192px -960px;
}

.ico-electricity {
  background-position: -240px -960px;
}

.ico-ok-2 {
  background-position: -288px -960px;
}

.ico-remove-2 {
  background-position: -336px -960px;
}

.ico-cart-out {
  background-position: -384px -960px;
}

.ico-cart-in {
  background-position: -432px -960px;
}

.ico-left-arrow {
  background-position: -0px -1008px;
}

.ico-right-arrow {
  background-position: -48px -1008px;
}

.ico-down-arrow {
  background-position: -96px -1008px;
}

.ico-up-arrow {
  background-position: -144px -1008px;
}

.ico-resize-small {
  background-position: -192px -1008px;
}

.ico-resize-full {
  background-position: -240px -1008px;
}

.ico-circle-arrow-left {
  background-position: -288px -1008px;
}

.ico-circle-arrow-right {
  background-position: -336px -1008px;
}

.ico-circle-arrow-top {
  background-position: -384px -1008px;
}

.ico-circle-arrow-down {
  background-position: -432px -1008px;
}

.ico-play-button {
  background-position: -0px -1056px;
}

.ico-unshare {
  background-position: -48px -1056px;
}

.ico-share {
  background-position: -96px -1056px;
}

.ico-thin-right-arrow {
  background-position: -144px -1056px;
}

.ico-thin-left-arrow {
  background-position: -192px -1056px;
}

.ico-bluetooth {
  background-position: -240px -1056px;
}

.ico-euro {
  background-position: -288px -1056px;
}

.ico-usd {
  background-position: -336px -1056px;
}

.ico-bp {
  background-position: -384px -1056px;
}

.ico-retweet-2 {
  background-position: -432px -1056px;
}

.ico-moon {
  background-position: -0px -1104px;
}

.ico-sun {
  background-position: -48px -1104px;
}

.ico-cloud {
  background-position: -96px -1104px;
}

.ico-direction {
  background-position: -144px -1104px;
}

.ico-brush {
  background-position: -192px -1104px;
}

.ico-pen {
  background-position: -240px -1104px;
}

.ico-zoom-in {
  background-position: -288px -1104px;
}

.ico-zoom-out {
  background-position: -336px -1104px;
}

.ico-pin {
  background-position: -384px -1104px;
}

.ico-riflescope {
  background-position: -432px -1104px;
}

.ico-rotation-lock {
  background-position: -0px -1152px;
}

.ico-flash {
  background-position: -48px -1152px;
}

.ico-google-maps {
  background-position: -96px -1152px;
}

.ico-anchor {
  background-position: -144px -1152px;
}

.ico-conversation {
  background-position: -192px -1152px;
}

.ico-chat {
  background-position: -240px -1152px;
}

.ico-male {
  background-position: -288px -1152px;
}

.ico-female {
  background-position: -336px -1152px;
}

.ico-asterisk {
  background-position: -384px -1152px;
}

.ico-divide {
  background-position: -432px -1152px;
}

.ico-snorkel-diving {
  background-position: -0px -1200px;
}

.ico-scuba-diving {
  background-position: -96px -1200px;
}

.ico-oxygen-vottle {
  background-position: -48px -1200px;
}

.ico-fins {
  background-position: -144px -1200px;
}

.ico-fishes {
  background-position: -192px -1200px;
}

.ico-boat {
  background-position: -240px -1200px;
}

.ico-delete-point {
  background-position: -288px -1200px;
}

.ico-sheriffs-star {
  background-position: -336px -1200px;
}

.ico-qrcode {
  background-position: -384px -1200px;
}

.ico-barcode {
  background-position: -432px -1200px;
}

.ico-pool {
  background-position: -0px -1248px;
}

.ico-buoy {
  background-position: -48px -1248px;
}

.ico-spade {
  background-position: -96px -1248px;
}

.ico-bank {
  background-position: -144px -1248px;
}

.ico-vcard {
  background-position: -192px -1248px;
}

.ico-electircal-plug {
  background-position: -240px -1248px;
}

.ico-flag {
  background-position: -288px -1248px;
}

.ico-credit-card {
  background-position: -336px -1248px;
}

.ico-keyboard-wirelsss {
  background-position: -384px -1248px;
}

.ico-keyboard-wired {
  background-position: -432px -1248px;
}

.ico-shield {
  background-position: -0px -1296px;
}

.ico-ring {
  background-position: -48px -1296px;
}

.ico-cake {
  background-position: -96px -1296px;
}

.ico-drink {
  background-position: -144px -1296px;
}

.ico-beer {
  background-position: -192px -1296px;
}

.ico-fast-food {
  background-position: -240px -1296px;
}

.ico-cutlery {
  background-position: -288px -1296px;
}

.ico-pizza {
  background-position: -336px -1296px;
}

.ico-birthday-cake {
  background-position: -384px -1296px;
}

.ico-tablet {
  background-position: -432px -1296px;
}

.ico-settings {
  background-position: -0px -1344px;
}

.ico-bullets {
  background-position: -48px -1344px;
}

.ico-cardio {
  background-position: -96px -1344px;
}

.ico-t-shirt {
  background-position: -144px -1344px;
}

.ico-pants {
  background-position: -192px -1344px;
}

.ico-sweater {
  background-position: -240px -1344px;
}

.ico-fabric {
  background-position: -288px -1344px;
}

.ico-leather {
  background-position: -336px -1344px;
}

.ico-scissors {
  background-position: -384px -1344px;
}

.ico-podium {
  background-position: -432px -1344px;
}

.ico-skull {
  background-position: -0px -1392px;
}

.ico-celebration {
  background-position: -48px -1392px;
}

.ico-tea-kettle {
  background-position: -96px -1392px;
}

.ico-french-press {
  background-position: -144px -1392px;
}

.ico-coffe-cup {
  background-position: -192px -1392px;
}

.ico-pot {
  background-position: -240px -1392px;
}

.ico-grater {
  background-position: -288px -1392px;
}

.ico-kettle {
  background-position: -336px -1392px;
}

.ico-hospital {
  background-position: -384px -1392px;
}

.ico-hospital-h {
  background-position: -432px -1392px;
}

.ico-microphone {
  background-position: -0px -1440px;
}

.ico-webcam {
  background-position: -48px -1440px;
}

.ico-temple-church {
  background-position: -96px -1440px;
}

.ico-temple-islam {
  background-position: -144px -1440px;
}

.ico-temple-hindu {
  background-position: -192px -1440px;
}

.ico-temple-buddhist {
  background-position: -240px -1440px;
}

.ico-electrical-socket-eu {
  background-position: -288px -1440px;
}

.ico-electrical-socket-us {
  background-position: -336px -1440px;
}

.ico-bomb {
  background-position: -384px -1440px;
}

.ico-comments {
  background-position: -432px -1440px;
}

.ico-flower {
  background-position: -0px -1488px;
}

.ico-baseball {
  background-position: -48px -1488px;
}

.ico-rugby {
  background-position: -96px -1488px;
}

.ico-ax {
  background-position: -144px -1488px;
}

.ico-table-tennis {
  background-position: -192px -1488px;
}

.ico-bowling {
  background-position: -240px -1488px;
}

.ico-tree-conifer {
  background-position: -288px -1488px;
}

.ico-tree-deciduous {
  background-position: -336px -1488px;
}

.ico-more-items {
  background-position: -384px -1488px;
}

.ico-sort {
  background-position: -432px -1488px;
}

.ico-filter {
  background-position: -0px -1536px;
}

.ico-gamepad {
  background-position: -48px -1536px;
}

.ico-playing-dices {
  background-position: -96px -1536px;
}

.ico-calculator {
  background-position: -144px -1536px;
}

.ico-tie {
  background-position: -192px -1536px;
}

.ico-wallet {
  background-position: -240px -1536px;
}

.ico-share {
  background-position: -288px -1536px;
}

.ico-sampler {
  background-position: -336px -1536px;
}

.ico-piano {
  background-position: -384px -1536px;
}

.ico-web-browser {
  background-position: -432px -1536px;
}

.ico-blog {
  background-position: -0px -1584px;
}

.ico-dashboard {
  background-position: -48px -1584px;
}

.ico-certificate {
  background-position: -96px -1584px;
}

.ico-bell {
  background-position: -144px -1584px;
}

.ico-candle {
  background-position: -192px -1584px;
}

.ico-pin-classic {
  background-position: -240px -1584px;
}

.ico-iphone-shake {
  background-position: -288px -1584px;
}

.ico-pin-flag {
  background-position: -336px -1584px;
}

.ico-turtle {
  background-position: -384px -1584px;
}

.ico-rabbit {
  background-position: -432px -1584px;
}

.ico-globe {
  background-position: -0px -1632px;
}

.ico-briefcase {
  background-position: -48px -1632px;
}

.ico-hdd {
  background-position: -96px -1632px;
}

.ico-thumbs-up {
  background-position: -144px -1632px;
}

.ico-thumbs-down {
  background-position: -192px -1632px;
}

.ico-hand-right {
  background-position: -240px -1632px;
}

.ico-hand-left {
  background-position: -288px -1632px;
}

.ico-hand-up {
  background-position: -336px -1632px;
}

.ico-hand-down {
  background-position: -384px -1632px;
}

.ico-fullscreen {
  background-position: -432px -1632px;
}

.ico-shopping-bag {
  background-position: -0px -1680px;
}

.ico-book-open {
  background-position: -48px -1680px;
}

.ico-nameplate {
  background-position: -96px -1680px;
}

.ico-nameplate-alt {
  background-position: -144px -1680px;
}

.ico-vases {
  background-position: -192px -1680px;
}

.ico-announcement {
  background-position: -240px -1680px;
}

.ico-dumbbell {
  background-position: -288px -1680px;
}

.ico-suitcase {
  background-position: -336px -1680px;
}

.ico-file-import {
  background-position: -384px -1680px;
}

.ico-file-export {
  background-position: -432px -1680px;
}

/* Glyphicons Mini
====================================*/
[class^="mini-ico-"],
[class*=" mini-ico-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  *margin-right: .3em;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("../images/icons/glyphicons-mini.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  opacity: 1;
}

[class^="mini-ico-"]:last-child,
[class*=" mini-ico-"]:last-child {
  *margin-left: 0;
}

.mini-white {
  background-image: url("../images/icons/glyphicons-mini-white.png");
  opacity: 1;
}

.mini-ico-glass {
  background-position: 0 0;
}

.mini-ico-music {
  background-position: -24px 0;
}

.mini-ico-search {
  background-position: -48px 0;
}

.mini-ico-envelope {
  background-position: -72px 0;
}

.mini-ico-heart {
  background-position: -96px 0;
}

.mini-ico-star {
  background-position: -120px 0;
}

.mini-ico-star-empty {
  background-position: -144px 0;
}

.mini-ico-user {
  background-position: -168px 0;
}

.mini-ico-film {
  background-position: -192px 0;
}

.mini-ico-th-large {
  background-position: -216px 0;
}

.mini-ico-th {
  background-position: -240px 0;
}

.mini-ico-th-list {
  background-position: -264px 0;
}

.mini-ico-ok {
  background-position: -288px 0;
}

.mini-ico-remove {
  background-position: -312px 0;
}

.mini-ico-zoom-in {
  background-position: -336px 0;
}

.mini-ico-zoom-out {
  background-position: -360px 0;
}

.mini-ico-off {
  background-position: -384px 0;
}

.mini-ico-signal {
  background-position: -408px 0;
}

.mini-ico-cog {
  background-position: -432px 0;
}

.mini-ico-trash {
  background-position: -456px 0;
}

.mini-ico-home {
  background-position: 0 -24px;
}

.mini-ico-file {
  background-position: -24px -24px;
}

.mini-ico-time {
  background-position: -48px -24px;
}

.mini-ico-road {
  background-position: -72px -24px;
}

.mini-ico-download-alt {
  background-position: -96px -24px;
}

.mini-ico-download {
  background-position: -120px -24px;
}

.mini-ico-upload {
  background-position: -144px -24px;
}

.mini-ico-inbox {
  background-position: -168px -24px;
}

.mini-ico-play-circle {
  background-position: -192px -24px;
}

.mini-ico-repeat {
  background-position: -216px -24px;
}

.mini-ico-refresh {
  background-position: -240px -24px;
}

.mini-ico-list-alt {
  background-position: -264px -24px;
}

.mini-ico-lock {
  background-position: -287px -24px;
}

.mini-ico-flag {
  background-position: -312px -24px;
}

.mini-ico-headphones {
  background-position: -336px -24px;
}

.mini-ico-volume-off {
  background-position: -360px -24px;
}

.mini-ico-volume-down {
  background-position: -384px -24px;
}

.mini-ico-volume-up {
  background-position: -408px -24px;
}

.mini-ico-qrcode {
  background-position: -432px -24px;
}

.mini-ico-barcode {
  background-position: -456px -24px;
}

.mini-ico-tag {
  background-position: 0 -48px;
}

.mini-ico-tags {
  background-position: -25px -48px;
}

.mini-ico-book {
  background-position: -48px -48px;
}

.mini-ico-bookmark {
  background-position: -72px -48px;
}

.mini-ico-print {
  background-position: -96px -48px;
}

.mini-ico-camera {
  background-position: -120px -48px;
}

.mini-ico-font {
  background-position: -144px -48px;
}

.mini-ico-bold {
  background-position: -167px -48px;
}

.mini-ico-italic {
  background-position: -192px -48px;
}

.mini-ico-text-height {
  background-position: -216px -48px;
}

.mini-ico-text-width {
  background-position: -240px -48px;
}

.mini-ico-align-left {
  background-position: -264px -48px;
}

.mini-ico-align-center {
  background-position: -288px -48px;
}

.mini-ico-align-right {
  background-position: -312px -48px;
}

.mini-ico-align-justify {
  background-position: -336px -48px;
}

.mini-ico-list {
  background-position: -360px -48px;
}

.mini-ico-indent-left {
  background-position: -384px -48px;
}

.mini-ico-indent-right {
  background-position: -408px -48px;
}

.mini-ico-facetime-video {
  background-position: -432px -48px;
}

.mini-ico-picture {
  background-position: -456px -48px;
}

.mini-ico-pencil {
  background-position: 0 -72px;
}

.mini-ico-map-marker {
  background-position: -24px -72px;
}

.mini-ico-adjust {
  background-position: -48px -72px;
}

.mini-ico-tint {
  background-position: -72px -72px;
}

.mini-ico-edit {
  background-position: -96px -72px;
}

.mini-ico-share {
  background-position: -120px -72px;
}

.mini-ico-check {
  background-position: -144px -72px;
}

.mini-ico-move {
  background-position: -168px -72px;
}

.mini-ico-step-backward {
  background-position: -192px -72px;
}

.mini-ico-fast-backward {
  background-position: -216px -72px;
}

.mini-ico-backward {
  background-position: -240px -72px;
}

.mini-ico-play {
  background-position: -264px -72px;
}

.mini-ico-pause {
  background-position: -288px -72px;
}

.mini-ico-stop {
  background-position: -312px -72px;
}

.mini-ico-forward {
  background-position: -336px -72px;
}

.mini-ico-fast-forward {
  background-position: -360px -72px;
}

.mini-ico-step-forward {
  background-position: -384px -72px;
}

.mini-ico-eject {
  background-position: -408px -72px;
}

.mini-ico-chevron-left {
  background-position: -432px -72px;
}

.mini-ico-chevron-right {
  background-position: -456px -72px;
}

.mini-ico-plus-sign {
  background-position: 0 -96px;
}

.mini-ico-minus-sign {
  background-position: -24px -96px;
}

.mini-ico-remove-sign {
  background-position: -48px -96px;
}

.mini-ico-ok-sign {
  background-position: -72px -96px;
}

.mini-ico-question-sign {
  background-position: -96px -96px;
}

.mini-ico-info-sign {
  background-position: -120px -96px;
}

.mini-ico-screenshot {
  background-position: -144px -96px;
}

.mini-ico-remove-circle {
  background-position: -168px -96px;
}

.mini-ico-ok-circle {
  background-position: -192px -96px;
}

.mini-ico-ban-circle {
  background-position: -216px -96px;
}

.mini-ico-arrow-left {
  background-position: -240px -96px;
}

.mini-ico-arrow-right {
  background-position: -264px -96px;
}

.mini-ico-arrow-up {
  background-position: -289px -96px;
}

.mini-ico-arrow-down {
  background-position: -312px -96px;
}

.mini-ico-share-alt {
  background-position: -336px -96px;
}

.mini-ico-resize-full {
  background-position: -360px -96px;
}

.mini-ico-resize-small {
  background-position: -384px -96px;
}

.mini-ico-plus {
  background-position: -408px -96px;
}

.mini-ico-minus {
  background-position: -433px -96px;
}

.mini-ico-asterisk {
  background-position: -456px -96px;
}

.mini-ico-exclamation-sign {
  background-position: 0 -120px;
}

.mini-ico-gift {
  background-position: -24px -120px;
}

.mini-ico-leaf {
  background-position: -48px -120px;
}

.mini-ico-fire {
  background-position: -72px -120px;
}

.mini-ico-eye-open {
  background-position: -96px -120px;
}

.mini-ico-eye-close {
  background-position: -120px -120px;
}

.mini-ico-warning-sign {
  background-position: -144px -120px;
}

.mini-ico-plane {
  background-position: -168px -120px;
}

.mini-ico-calendar {
  background-position: -192px -120px;
}

.mini-ico-random {
  background-position: -216px -120px;
}

.mini-ico-comment {
  background-position: -240px -120px;
}

.mini-ico-magnet {
  background-position: -264px -120px;
}

.mini-ico-chevron-up {
  background-position: -288px -120px;
}

.mini-ico-chevron-down {
  background-position: -313px -119px;
}

.mini-ico-retweet {
  background-position: -336px -120px;
}

.mini-ico-shopping-cart {
  background-position: -360px -120px;
}

.mini-ico-folder-close {
  background-position: -384px -120px;
}

.mini-ico-folder-open {
  background-position: -408px -120px;
}

.mini-ico-resize-vertical {
  background-position: -432px -119px;
}

.mini-ico-resize-horizontal {
  background-position: -456px -118px;
}

.mini-ico-hdd {
  background-position: 0 -144px;
}

.mini-ico-bullhorn {
  background-position: -24px -144px;
}

.mini-ico-bell {
  background-position: -48px -144px;
}

.mini-ico-certificate {
  background-position: -72px -144px;
}

.mini-ico-thumbs-up {
  background-position: -96px -144px;
}

.mini-ico-thumbs-down {
  background-position: -120px -144px;
}

.mini-ico-hand-right {
  background-position: -144px -144px;
}

.mini-ico-hand-left {
  background-position: -168px -144px;
}

.mini-ico-hand-up {
  background-position: -192px -144px;
}

.mini-ico-hand-down {
  background-position: -216px -144px;
}

.mini-ico-circle-arrow-right {
  background-position: -240px -144px;
}

.mini-ico-circle-arrow-left {
  background-position: -264px -144px;
}

.mini-ico-circle-arrow-up {
  background-position: -288px -144px;
}

.mini-ico-circle-arrow-down {
  background-position: -312px -144px;
}

.mini-ico-globe {
  background-position: -336px -144px;
}

.mini-ico-wrench {
  background-position: -360px -144px;
}

.mini-ico-tasks {
  background-position: -384px -144px;
}

.mini-ico-filter {
  background-position: -408px -144px;
}

.mini-ico-briefcase {
  background-position: -432px -144px;
}

.mini-ico-fullscreen {
  background-position: -456px -144px;
}

#works {
  margin-bottom: 40px;
}

.works, .blog, .clients {
  overflow: hidden;
}

.works-carousel-container, .blog-wrapper, .blog-wrapper1, .logo-wrapper {
  clear: both;
  display: block;
  position: relative;
  margin-left: -31px;
}

.blog-wrapper1 {
  margin-left: 0px;
}

.works-carousel, .blog-carousel, .works-carousel2, .blog-carousel2, .works-carousel3, .logo-carousel {
  position: relative;
  display: block;
}

.works-carousel .columns img, .works-carousel2 .columns img, .works-carousel3 .columns img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.works-carousel .columns, .blog-carousel .columns, .works-carousel2 .columns, .works-carousel3 .columns, .blog-carousel2 .columns,
.logo-carousel .columns {
  margin-left: 21px !important;
  position: relative;
  float: left;
}

.works-carousel2 {
  left: 5px;
}

.works-title {
  text-align: center;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.oldie .works-title {
  background: url(../img/bg-header-ie.png) repeat;
}

.works-title h4 {
  padding: 10px;
  font-size: 14px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}

.works-title h4, .works-title p {
  margin-bottom: 0px;
}

#content .works-title a {
  color: #fff;
}

a.works-more, a.blog-more {
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: 43px;
}

.works-direction-nav, .blog-direction-nav, .logo-direction-nav {
  position: absolute;
  top: -47px;
  right: 0;
}

.logo-direction-nav {
  top: -35px;
}

.works-direction-nav li, .blog-direction-nav li {
  position: relative;
  display: block;
  float: left;
}

.works-direction-nav a.works-prev, a.works-next, .blog-direction-nav a.blog-prev, .blog-direction-nav a.blog-next,
.works-direction-nav a.works2-prev, a.works2-next, .blog-direction-nav a.blog2-prev, .blog-direction-nav a.blog2-next,
.works-direction-nav a.works3-prev, a.works3-next, .logo-direction-nav a.logo-prev, .logo-direction-nav a.logo-next {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  margin-right: 1px;
  background: #818181 url(bg-carousel-nav.png) no-repeat -15px 2px;
  border-radius: 2px;
}

.logo-direction-nav a.logo-prev:hover, .logo-direction-nav a.logo-next:hover {
  border: none !important;
}

.works-direction-nav a.works-prev, .blog-direction-nav a.blog-prev,
.works-direction-nav a.works2-prev, .blog-direction-nav a.blog2-prev,
.works-direction-nav a.works3-prev, .logo-direction-nav a.logo-prev {
  margin-right: 1px;
  background-position: 1px 2px;
}

.works-direction-nav a.works-prev:hover, a.works-next:hover, .blog-direction-nav a.blog-prev:hover, a.blog-next:hover,
.works-direction-nav a.works2-prev:hover, a.works2-next:hover, .blog2-direction-nav a.blog2-prev:hover, a.blog2-next:hover,
.works-direction-nav a.works3-prev:hover, a.works3-next:hover, .logo-direction-nav a.logo-prev:hover, .logo-direction-nav a.logo-next:hover {
  background-color: #49abcf;
}

.works-direction-nav a.disabled, .works-direction-nav a.disabled:hover,
.blog-direction-nav a.disabled, .blog-direction-nav a.disabled:hover,
.works-direction-nav a.disabled, .works2-direction-nav a.disabled:hover,
.blog-direction-nav a.disabled, .blog2-direction-nav a.disabled:hover,
.works3-direction-nav a.disabled:hover, .logo-direction-nav a.light.disabled, .logo-direction-nav a.light.disabled:hover {
  background-color: #efefef !important;
  cursor: default;
}

.logo-direction-nav a.disabled, .logo-direction-nav a.disabled:hover {
  background-color: #4a4a4a;
  cursor: default;
}

.works-thumb {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;
  z-index: 0;
  height: 100%;
  width: 219px;
}

.works-thumb h4 {
  margin-bottom: 5px;
}

.works-thumb img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.works-thumb:hover .overlay {
  opacity: 1;
  visibility: visible;
  top: 0;
  right: 0;
}

.works-thumb2 {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;
  z-index: 0;
  height: 100%;
}

.works-thumb2 h4 {
  margin-bottom: 5px;
}

.works-thumb2 img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.works-thumb2:hover .overlay {
  opacity: 1;
  visibility: visible;
  top: 0;
  right: 0;
}

.overlay {
  color: #fff;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #0AD1E5;
  top: -50%;
  right: -50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.overlay1 {
  top: -50%;
  right: 0%;
}

.overlay2 {
  top: 50%;
  right: 0%;
}

.overlay3 {
  top: 0%;
  right: 50%;
}

.overlay4 {
  top: 0%;
  right: -50%;
}

.overlay5 {
  top: -50%;
  right: 50%;
}

.overlay6 {
  top: -50%;
  right: -50%;
}

.overlay7 {
  top: 50%;
  right: -50%;
}

.overlay8 {
  top: 50%;
  right: 50%;
}

.works-thumb .overlay {
  padding: 15px;
}

.works-thumb2 .overlay {
  padding: 15px;
}

.overlay h4, #content .overlay-icons a {
  color: #fff;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.overlay-icons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: block;
  text-align: center;
}

.overlay-icons:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  display: block;
}

.works-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: #0AD1E5;
  color: white !important;
  padding: 20px 10px;
  font-size: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 3;
}

#content .works-icon:hover {
  color: #fff;
}

.works-thumb:hover a.works-icon {
  top: -42px;
  right: -42px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.works-preview2, .works-link2 {
  display: block;
  width: 32px;
  height: 32px;
  background: #4A4A4A url(../img/bg-icons-overlay.png) no-repeat 0 0;
  bottom: 0;
  right: 33px;
  position: absolute;
}

.works-link2 {
  right: 0;
  background-position: 0px -32px;
}

.works-preview, .works-cat, .works-link {
  display: inline-block;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  opacity: 0.75;
  font-size: 24px;
  padding: 18px 5px 16px;
  margin: 5px 10px 5px 0px;
  position: relative;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
}

#content .overlay-icons a:hover {
  color: #fff;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}

.works-preview {
  margin-right: 0px;
}

.works-link {
  margin-left: 5px;
}

.works-cat {
  float: left;
  opacity: 1 !important;
  margin-left: 10px;
}

.overlay p {
  line-height: 24px;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

/* Fancybox
================================================== */
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  cursor: pointer;
  overflow: hidden;
  z-index: 1104;
  display: none;
}

#fancybox-loading div {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 480px;
  background-image: url("../img/fancybox/fancybox.png");
}

#fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  display: none;
}

#fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  overflow: auto;
  display: none;
}

#fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1101;
  outline: none;
  display: none;
}

#fancybox-outer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}

#fancybox-content {
  width: 0;
  height: 0;
  padding: 0;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 1102;
  border: 0px solid #fff;
}

#fancybox-hide-sel-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1101;
}

#fancybox-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: transparent url("../img/fancybox/fancybox.png") -40px 0px;
  cursor: pointer;
  z-index: 1103;
  display: none;
}

#fancybox-error {
  color: #444;
  font: normal 12px/20px Arial;
  padding: 14px;
  margin: 0;
}

#fancybox-img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 0;
  vertical-align: top;
}

#fancybox-frame {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

#fancybox-left, #fancybox-right {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 35%;
  cursor: pointer;
  outline: none;
  background: transparent url("../img/fancybox/blank.gif");
  z-index: 1102;
  display: none;
}

#fancybox-left {
  left: 0px;
}

#fancybox-right {
  right: 0px;
}

#fancybox-left-ico, #fancybox-right-ico {
  position: absolute;
  top: 50%;
  left: -9999px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  cursor: pointer;
  z-index: 1102;
  display: block;
}

#fancybox-left-ico {
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -30px;
}

#fancybox-right-ico {
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -60px;
}

#fancybox-left:hover, #fancybox-right:hover {
  visibility: visible;
  /* IE6 */
}

#fancybox-left:hover span {
  left: 20px;
}

#fancybox-right:hover span {
  left: auto;
  right: 20px;
}

.fancybox-bg {
  position: absolute;
  padding: 0;
  margin: 0;
  border: 0;
  width: 20px;
  height: 20px;
  z-index: 1001;
}

#fancybox-bg-n {
  top: -20px;
  left: 0;
  width: 100%;
  background-image: url("../img/fancybox/fancybox-x.png");
}

#fancybox-bg-ne {
  top: -20px;
  right: -20px;
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -162px;
}

#fancybox-bg-e {
  top: 0;
  right: -20px;
  height: 100%;
  background-image: url("../img/fancybox/fancybox-y.png");
  background-position: -20px 0px;
}

#fancybox-bg-se {
  bottom: -20px;
  right: -20px;
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -182px;
}

#fancybox-bg-s {
  bottom: -20px;
  left: 0;
  width: 100%;
  background-image: url("../img/fancybox/fancybox-x.png");
  background-position: 0px -20px;
}

#fancybox-bg-sw {
  bottom: -20px;
  left: -20px;
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -142px;
}

#fancybox-bg-w {
  top: 0;
  left: -20px;
  height: 100%;
  background-image: url("../img/fancybox/fancybox-y.png");
}

#fancybox-bg-nw {
  top: -20px;
  left: -20px;
  background-image: url("../img/fancybox/fancybox.png");
  background-position: -40px -122px;
}

#fancybox-title {
  font-family: Helvetica;
  font-size: 12px;
  z-index: 1102;
}

.fancybox-title-inside {
  padding-bottom: 10px;
  text-align: center;
  color: #333;
  background: #fff;
  position: relative;
}

.fancybox-title-outside {
  padding-top: 10px;
  color: #fff;
}

.fancybox-title-over {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #FFF;
  text-align: left;
}

#fancybox-title-over {
  padding: 10px;
  background-image: url("../img/fancybox/fancy_title_over.png");
  display: block;
}

.fancybox-title-float {
  position: absolute;
  left: 0;
  bottom: -20px;
  height: 32px;
}

#fancybox-title-float-wrap {
  border: none;
  border-collapse: collapse;
  width: auto;
}

#fancybox-title-float-wrap td {
  border: none;
  white-space: nowrap;
}

#fancybox-title-float-left {
  padding: 0 0 0 15px;
  background: url("../img/fancybox/fancybox.png") -40px -90px no-repeat;
}

#fancybox-title-float-main {
  color: #FFF;
  line-height: 29px;
  font-weight: bold;
  padding: 0 0 3px 0;
  background: url("../img/fancybox/fancybox-x.png") 0px -40px;
}

#fancybox-title-float-right {
  padding: 0 0 0 15px;
  background: url("../img/fancybox/fancybox.png") -55px -90px no-repeat;
}

/* IE6 */
.fancybox-ie6 #fancybox-close {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_close.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-left-ico {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_nav_left.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-right-ico {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_nav_right.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-title-over {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_over.png', sizingMethod='scale');
  zoom: 1;
}

.fancybox-ie6 #fancybox-title-float-left {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_left.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-title-float-main {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_main.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-title-float-right {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_title_right.png', sizingMethod='scale');
}

.fancybox-ie6 #fancybox-bg-w, .fancybox-ie6 #fancybox-bg-e, .fancybox-ie6 #fancybox-left, .fancybox-ie6 #fancybox-right, #fancybox-hide-sel-frame {
  height: expression(this.parentNode.clientHeight + "px");
}

#fancybox-loading.fancybox-ie6 {
  position: absolute;
  margin-top: 0;
  top: expression( (-20 + (document.documentElement.clientHeight ? document.documentElement.clientHeight/2 : document.body.clientHeight/2 ) + ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop )) + 'px');
}

#fancybox-loading.fancybox-ie6 div {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='fancybox/fancy_loading.png', sizingMethod='scale');
}

/* IE6, IE7, IE8 */
.fancybox-ie .fancybox-bg {
  background: transparent !important;
}

.fancybox-ie #fancybox-bg-n {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_n.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-ne {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_ne.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-e {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_e.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-se {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_se.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-s {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_s.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-sw {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_sw.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-w {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_w.png', sizingMethod='scale');
}

.fancybox-ie #fancybox-bg-nw {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/fancybox/fancybox/fancy_shadow_nw.png', sizingMethod='scale');
}

/* Progress Bar */
.progress-bar h5 {
  margin-bottom: 6px;
}

.progress-bar li {
  list-style: none outside none;
  margin-bottom: 12px;
}

.meter {
  background: none repeat scroll 0 0 #FCFCFC;
  height: 23px;
  position: relative;
}

.meter > span {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.meter > span:after, .animate > span > span {
  bottom: 0;
  content: "";
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.meter > span:after, .animate > span > spans li.current {
  background: none repeat scroll 0 0 #0ad1e5;
}

/* Testimonials */
.testimonial .body {
  background-color: #F5F5F5;
  margin-bottom: 10px;
  padding: 20px 20px 20px 48px;
  position: relative;
}

.testimonial .body:before {
  color: #444444;
  content: "ˮ";
  font-size: 72px;
  left: 16px;
  position: absolute;
  text-align: center;
  top: 40px;
}

.testimonial .author {
  padding-left: 20px;
}

.testimonial .author [class*="ico-"] {
  float: left;
  line-height: 26px;
  margin-top: 2px;
}

.testimonial .author .name {
  margin-left: 24px;
}

/* Tabs */
.htabs {
  border-bottom: 1px solid #EEEEEE;
  height: 27px;
  line-height: 15px;
  margin-right: 4px;
}

.htabs a {
  background: url("grad_menu.png") repeat-x scroll left top #FAFAFA;
  border-left: 1px solid #E4E4E4;
  border-radius: 3px 3px 0 0;
  border-right: 1px solid #E4E4E4;
  border-top: 1px solid #E4E4E4;
  color: #666666;
  float: left;
  font-size: 13px;
  margin-right: 4px;
  padding: 6px 10px 5px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.htabs a.selected {
  background: none repeat scroll 0 0 #FFFFFF;
  border-left: 1px solid #E4E4E4;
  border-right: 1px solid #E4E4E4;
  border-top: 1px solid #E4E4E4;
  padding-bottom: 6px;
}

.tab-content {
  border-bottom: 1px solid #E4E4E4;
  border-left: 1px solid #E4E4E4;
  border-radius: 0 4px 4px 4px;
  border-right: 1px solid #E4E4E4;
  overflow: auto;
  padding: 20px;
  z-index: 2;
}

/* Accordions */
.acc_item {
  padding: 12px 0px;
  margin: 0px 0 0;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
}

.acc_item:hover {
  background: #f8f8f8;
}

.acc_control {
  float: left;
  background: #778185 url("acc_bgr.png") 0px -18px no-repeat;
  width: 18px;
  height: 18px;
  margin: 0 14px 0px 8px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -moz-border-radius: 17px;
  -webkit-border-radius: 17px;
  -khtml-border-radius: 17px;
  border-radius: 17px;
}

.active_acc .acc_control {
  background: #778185 url("acc_bgr.png") 0px 0px no-repeat;
}

.acc_heading {
  display: block;
  margin-left: 40px;
}

.accordion {
  padding: 0px;
  margin: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.accordion.active_acc {
  background: url("acc_bgr.png") 0px 0px no-repeat;
}

.accordion_content {
  display: none;
  padding: 10px 10px 0 40px;
  color: #777;
}

/* Alert Boxes */
.success, .warning, .attention, .information {
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 14px;
  margin-top: 6px;
  padding: 8px 14px;
}

.success a, .warning a, .attention a, .information a {
  color: #444444;
}

.success {
  background: none repeat scroll 0 0 #D6F7E2;
  border: 1px solid #C2EBD1;
}

.warning {
  background: none repeat scroll 0 0 #FFE8E8;
  border: 1px solid #EFD9D9;
}

.attention {
  background: none repeat scroll 0 0 #FFF5CC;
  border: 1px solid #F0E6BF;
}

.information {
  background: none repeat scroll 0 0 #E2F5F7;
  border: 1px solid #D1E9EC;
}

.success .close, .warning .close, .attention .close, .information .close {
  cursor: pointer;
  float: right;
  padding-right: 4px;
  padding-top: 4px;
}

.required {
  color: #FF4242;
  font-weight: bold;
  padding-left: 2px;
}

.error {
  color: #FF4242;
  display: block;
}

.close_img {
  background: url("close.png") no-repeat scroll 0 0 transparent;
  cursor: pointer;
  float: right;
  height: 7px;
  margin: 7px 0 7px 20px;
  width: 7px;
}

/* Styled Lists */
ul.arrowed {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

ul.arrowed li {
  background: url("arrow.png") no-repeat scroll 0 3px transparent;
  margin-bottom: 3px;
  padding: 0 0 0 20px;
}

ul.dotted {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

ul.dotted li {
  background: url("dot.png") no-repeat scroll 0 3px transparent;
  margin-bottom: 3px;
  padding: 0 0 0 20px;
}

ul.checked {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

ul.checked li {
  background: url("check.png") no-repeat scroll 0 3px transparent;
  margin-bottom: 6px;
  padding: 0 0 0 20px;
}

.faq-list h4 {
  font-size: 14px;
}

.faq-search {
  margin-bottom: 2em;
  text-align: right;
}

.faq-search input {
  width: 96%;
  display: block;
  padding: 1%;
  border: 1px solid #D8D8D8;
}

.faq-empty {
  display: none;
}

.faq-toc {
  padding: 1.5em 0;
  margin: 2em 0 0;
  border: 1px dotted #CCC;
  border-right: none;
  border-left: none;
}

.faq-toc ol {
  padding: 0;
  margin: 0;
}

.faq-toc li {
  margin-bottom: .75em;
  list-style: none;
}

.faq-toc a {
  margin-left: .5em;
}

.faq-list {
  padding: 0;
  margin: 3em 0 0;
  list-style: none;
}

.faq-list li {
  display: table;
  margin-bottom: 2em;
}

.faq-icon {
  display: table-cell;
  padding-right: 1.25em;
  vertical-align: top;
}

.faq-text {
  display: table-cell;
  vertical-align: top;
}

.faq-number {
  width: 32px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 32px;
  color: #FFF;
  background: #1a8643;
  border: 3px solid #FFF;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.btn-support-ask {
  display: block;
  font-size: 22px;
  padding: 14px 0;
  font-weight: 600;
  margin-bottom: .75em;
}

.btn-support-contact {
  display: block;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 600;
}

.faq-end {
  margin-bottom: -20px;
}

.header-logo-container {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.header-logo {
  width: 200px;
  display: inline-block;
  margin-right: 30px;
}

.header-logo img {
  max-width: 100%;
}

/*-------------------------------------------------*/
/* =  Header
/*-------------------------------------------------*/
#top-line {
  min-height: 15px;
  width: 100%;
  border-top: 3px solid #764d24;
  position: relative;
}

#top {
  margin: auto;
  width: 940px;
  height: 100%;
  margin-top: 10px;
}

#top #logo {
  float: left;
  margin-bottom: 27px;
}

#top aside {
  float: right;
}

#top aside ul {
  float: right;
  margin: 0;
  margin-top: 3px;
}

#top aside ul li {
  display: inline;
  margin-left: 7px;
}

#top aside ul li:first-child {
  margin-left: 11px;
}

#menu {
  border-top: 0px solid #e5e5e5;
  border-bottom: 0px solid #e5e5e5;
  background: #fff url("../images/bg-white.png") repeat 0 0;
}

#menu nav {
  width: 940px;
  margin: auto;
}

#menu nav ul {
  padding: 0;
  margin: 0;
}

#menu nav ul li {
  display: inline-block;
  margin-right: 25px;
}

#menu nav ul li a {
  padding: 18px 0;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: inline-block;
  color: #202020;
  font-family: 'Economica', 'Lucida Sans Unicode', Arial;
  font-weight: normal;
  transition: color 0.2s linear;
}

#menu nav ul li a:hover {
  color: #1a8643;
}

#menu nav ul .nav-button:hover {
  border-bottom: none !important;
}

#menu nav ul .nav-button a {
  background: #1a8643;
  padding: 10px;
  border-radius: 4px;
  color: white;
}

#menu nav ul .nav-button a:hover {
  color: white;
  background: #22b158;
  border-bottom: none !important;
}

header nav ul li:not(.active):hover {
  border-bottom: 3px solid #1a8643;
}

header nav ul li:hover li {
  border-bottom: 0px solid #1a8643 !important;
}

header nav ul li.active {
  border-top: 3px solid #1a8643 !important;
  border-bottom: 3px solid #1a8643 !important;
}

/* DropDown Menu = Superfish */
.sf-menu ul {
  position: absolute;
  top: -999em;
  width: 180px;
  /* left offset of submenus need to match (see below) */
}

.sf-menu ul li {
  width: 100%;
}

.sf-menu ul li:first-child {
  border-top: 4px solid #0ad1e5;
}

.sf-menu ul li li:first-child {
  border-top: 4px solid #0ad1e5;
}

.sf-menu li:hover {
  visibility: inherit;
  /* fixes IE7 'sticky bug' */
}

li {
  position: relative;
}

.sub-menu {
  border: 1px solid #e8e8e8;
  border-top: none;
}

.sub-menu li {
  border-top: 1px solid #e8e8e8;
}

.sub-menu a {
  display: block;
  position: relative;
  padding: 10px 0px 10px 10px !important;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  left: 0;
  top: 54px;
  /* match top ul list item height */
  z-index: 99;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 180px;
  /* match ul width */
  top: -1px;
  /*margin-left: 2px;*/
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 180px;
  /* match ul width */
  top: -1px;
}

.sf-menu li li {
  background: rgba(255, 255, 255, 0.95);
  padding-right: 0px;
}

.sf-menu li li li {
  background: rgba(255, 255, 255, 0.95);
  padding-right: 0px;
}

.mbtr {
  opacity: 0.5;
}

body {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
}

body.boxed {
  padding: 0 20px;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

ul.normal-list {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 4px;
}

ul.normal-list li {
  list-style: disc;
}

.control-label {
  font-weight: 700;
}

#container {
  background: #fff;
}

body.boxed #container {
  max-width: 980px;
  margin: auto;
  box-shadow: 0px 0px 20px 2px #d0d0d0;
}

/*-------------------------------------------------*/
/* =  FancyBox - v2.0.6
/*-------------------------------------------------*/
.fancybox-tmp iframe, .fancybox-tmp object {
  vertical-align: top;
  padding: 0;
  margin: 0;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  padding: 0;
  margin: 0;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  padding: 0;
  margin: 0;
  position: relative;
  outline: none;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 10px;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../images/fancybox/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8020;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../images/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  background: transparent url("../images/fancybox/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 20px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 20px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  padding: 0;
  overflow: visible;
  visibility: hidden;
}

/* Overlay helper */
#fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: #000;
}

#fancybox-overlay.overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8030;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  margin-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

/* Extras */
.fancybox-type-inline .fancybox-skin {
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
}

.fancybox-type-inline .fancybox-outer {
  background: #fff !important;
}

.fancybox-type-inline .fancybox-inner {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.fancybox-type-inline .fancybox-next {
  right: 15px !important;
}

.fancybox-type-inline .fancybox-prev {
  left: 15px !important;
}

/*-------------------------------------------------*/
/* =  Social Icons
/*-------------------------------------------------*/
.social-icons {
  margin: 0;
  float: right;
}

.social-icons2 {
  margin: 0;
  float: left;
}

.social-icons li {
  display: inline;
  list-style: none;
  text-indent: -9999px;
  margin-left: 5px;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons2 li {
  display: inline;
  list-style: none;
  text-indent: -9999px;
  margin-right: 5px;
  float: right;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons li a {
  background-repeat: no-repeat;
  background-position: 0 0;
  display: block;
  height: 28px;
  width: 28px;
}

.social-icons2 li a {
  background-repeat: no-repeat;
  background-position: 0 -38px;
  display: block;
  height: 28px;
  width: 28px;
}

.social-icons li:hover, .social-icons2 li:hover {
  background-position: 0 -38px;
}

/* Social Widget Icons */
#social a {
  width: 28px;
  height: 28px;
  margin: 0 6px 6px 0;
  display: block;
  float: left;
  text-indent: -9999px;
  background-position: 0 -38px;
}

#social a img {
  border: none;
}

/* Icon List */
.amazon {
  background: url("../images/icons/social/amazon.png") no-repeat;
}

.behance {
  background: url("../images/icons/social/behance.png") no-repeat;
}

.blogger {
  background: url("../imgaes/icons/social/blogger.png") no-repeat;
}

.deviantart {
  background: url("../imgaes/icons/social/deviantart.png") no-repeat;
}

.dribbble {
  background: url("../images/icons/social/dribbble.png") no-repeat;
}

.dropbox {
  background: url("../images/icons/social/dropbox.png") no-repeat;
}

.evernote {
  background: url("../images/icons/social/evernote.png") no-repeat;
}

.facebook {
  background: url("../images/icons/social/facebook.png") no-repeat;
}

.forrst {
  background: url("../images/icons/social/forrst.png") no-repeat;
}

.github {
  background: url("../images/icons/social/github.png") no-repeat;
}

.googleplus {
  background: url("../images/icons/social/googleplus.png") no-repeat;
}

.jolicloud {
  background: url("../images/icons/social/jolicloud.png") no-repeat;
}

.last-fm {
  background: url("../images/icons/social/last-fm.png") no-repeat;
}

.linkedin {
  background: url("../images/icons/social/linkedin.png") no-repeat;
}

.picasa {
  background: url("../images/icons/social/picasa.png") no-repeat;
}

.pintrest {
  background: url("../images/icons/social/pintrest.png") no-repeat;
}

.rss {
  background: url("../images/icons/social/rss.png") no-repeat;
}

.skype {
  background: url("../images/icons/social/skype.png") no-repeat;
}

.spotify {
  background: url("../images/icons/social/spotify.png") no-repeat;
}

.stumbleupon {
  background: url("../images/icons/social/stumbleupon.png") no-repeat;
}

.tumblr {
  background: url("../images/icons/social/tumblr.png") no-repeat;
}

.twitter {
  background: url("../images/icons/social/twitter.png") no-repeat;
}

.vimeo {
  background: url("../images/icons/social/vimeo.png") no-repeat;
}

.wordpress {
  background: url("../images/icons/social/wordpress.png") no-repeat;
}

.xing {
  background: url("../images/icons/social/xing.png") no-repeat;
}

.yahoo {
  background: url("../images/icons/social/yahoo.png") no-repeat;
}

.youtube {
  background: url("../images/icons/social/youtube.png") no-repeat;
}

/*-------------------------------------------------*/
/* =  Mobile Menu - selectbox
/*-------------------------------------------------*/
.sbHolder {
  background: #0ad1e5;
  /*border: 1px solid #e4e4e4;*/
  height: 44px;
  line-height: 44px;
  position: relative;
  margin: auto;
  max-width: 100%;
  z-index: 99;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  display: none;
}

.sbSelector {
  display: block;
  height: 44px;
  left: 0;
  line-height: 44px;
  outline: none;
  overflow: hidden;
  position: absolute;
  text-indent: 10px;
  top: 0;
  width: 100%;
}

.sbSelector:link, .sbSelector:visited, .sbSelector:hover {
  color: #fff;
  outline: none;
  text-decoration: none;
}

.sbToggle {
  background: url("../images/select-icons.png") 0 -116px no-repeat;
  display: block;
  height: 30px;
  outline: none;
  position: absolute;
  right: 5px;
  top: 7px;
  width: 30px;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.sbToggle:hover {
  opacity: 0.8;
  background-position: 0 -167px;
}

.sbToggleOpen {
  background-position: 0 -16px;
}

.sbToggleOpen:hover {
  opacity: 0.8;
  background-position: 0 -166px;
}

.sbOptions {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 1;
  overflow-y: auto;
}

.sbOptions li {
  padding: 0 5px;
  line-height: 10px;
}

.sbOptions a {
  border-bottom: dotted 1px #e4e4e4;
  display: block;
  outline: none;
  padding: 0 0 0 3px;
}

.sbOptions a:link, .sbOptions a:visited {
  color: #646464;
  text-decoration: none;
  padding-left: 20px !important;
}

.sbOptions > li:first-child > a {
  padding-left: 10px;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
  color: #d91c03;
}

.sbOptions li.last a {
  border-bottom: none;
}

.sbOptions .sbGroup {
  border-bottom: dotted 1px #e4e4e4;
  color: #646464;
  display: block;
  font-weight: bold;
  line-height: 40px;
  font-family: 'Economica', 'Lucida Sans Unicode', Arial;
  font-weight: normal;
  font-size: 18px;
}

.sbOptions .sbSub {
  padding-left: 17px !important;
}

/*-------------------------------------------------*/
/* =  Slider
/*-------------------------------------------------*/
section.slider {
  background: #f9f9f9;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
  margin-bottom: 40px;
}

section.slider div {
  margin: auto;
}

.slides li {
  width: 100%;
  overflow: hidden;
}

.slides li img {
  max-width: 100%;
  max-height: 100%;
}

.slides .flex-caption {
  position: absolute;
  color: #ffffff;
  font-size: 13px;
  font-style: italic;
  font-family: georgia;
  bottom: 0px;
  padding: 9px 12px 10px 12px;
  background: rgba(0, 0, 0, 0.5);
  line-height: 18px;
}

#slider1 .slides .flex-caption,
#slider2 .slides .flex-caption {
  right: 76px;
  margin: 0;
}

#slider2 {
  padding: 0 !important;
  bottom: none !important;
}

/*-------------------------------------------------*/
/* =  Content - home
/*-------------------------------------------------*/
#inner-content {
  margin: auto !important;
  padding: 0 20px;
}

#inner-content article {
  position: relative;
  min-height: 90px;
  margin-bottom: 40px;
  margin-left: 0;
  clear: both;
}

.new-location-message {
  border-left: 5px solid #1a8643;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 12px 40px;
}

.new-location-message h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.new-location-message p {
  font-size: 16px;
}

.row-fluid h1 {
  color: #404040;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  padding: 0;
  margin-bottom: 15px;
  overflow: hidden;
  line-height: 35px;
  border-bottom: 3px solid #1a8643;
  display: inline-block;
}

.texter {
  margin-bottom: 30px;
}

.texter2 {
  margin-bottom: 55px;
}

.callout {
  border-left: 5px solid #22b158;
  background-color: #3a3a3a;
  clear: both;
  padding: 12px 40px;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.callout h2 {
  color: #fff;
  text-transform: uppercase;
  margin: 0 125px 0 0;
  font-size: 20px;
}

.callout h2 small {
  display: block;
  font-size: 14px;
  line-height: 1.7;
  text-transform: none;
}

.callout .link {
  position: absolute;
  right: 0;
  top: 0;
  width: 75px;
  background-color: #0ad1e5;
  color: #fff;
  height: 100%;
  text-align: center;
}

.callout .link:before {
  content: '';
  border: 500px solid transparent;
  border-right-color: #0ad1e5;
  position: absolute;
  right: 100%;
}

.callout .link [class*="ico-"] {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
}

#page {
  margin-bottom: 52px;
  min-width: 900px;
}

#page-port {
  margin-bottom: 32px;
}

#inner-content div#articles {
  clear: both;
  margin-bottom: 40px;
}

#inner-content div.span3:first-child {
  margin-left: 0;
}

#inner-content div.span4 h2 {
  line-height: 0;
  margin-bottom: 20px;
}

#inner-content div.span4 h2 img {
  margin-right: 12px;
}

#inner-content div.span4 h2 span {
  font-size: 17px;
  font-weight: bold;
  color: #0ad1e5;
}

#inner-content #articles p {
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 16px;
}

#articles2 div.span3 a,
#articles div.span4 a {
  position: relative;
  color: #1a8643;
  font-size: 11px;
  display: inline-block;
  border: 1px solid #dbdbdb;
  border-right: none;
  margin-bottom: 30px;
  padding: 7px;
  padding-right: 27px;
  line-height: 10px;
}

#articles2 div.span3 a:hover span,
#articles div.span4 a:hover span {
  background: #868686 url("../images/readmore-arrow.png") center center no-repeat;
}

#articles2 div.span3 a span,
#articles div.span4 a span {
  position: absolute;
  width: 20px;
  height: 26px;
  right: 0;
  top: -1px;
  background: #0ad1e5 url("../images/readmore-arrow.png") center center no-repeat;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

.heading h1 {
  border: 0;
  font-size: 16px;
  margin: 0;
  line-height: 35px;
}

.heading h2 {
  border: 0;
  font-size: 15px;
  margin: 0;
  line-height: 35px;
}

.heading h3 {
  border: 0;
  font-size: 14px;
  margin: 0;
  line-height: 35px;
}

.heading h4 {
  border: 0;
  font-size: 13px;
  margin: 0;
  line-height: 35px;
}

.heading h5 {
  border: 0;
  font-size: 12px;
  margin: 0;
  line-height: 35px;
}

.heading h6 {
  border: 0;
  font-size: 11px;
  margin: 0;
  line-height: 35px;
}

blockquote {
  border-left: 5px solid #0ad1e5;
  margin: 0;
  padding: 0 0 0 15px;
}

blockquote p {
  font-size: 12px;
}

blockquote.pull-right {
  border-left: 0 none;
  border-right: 5px solid #0ad1e5;
  float: right;
  margin: 0;
  padding: 0 15px 0 0;
}

.highlight {
  background: none repeat scroll 0 0 #0ad1e5;
  display: inline;
  padding: 2px 3px;
  width: auto;
}

/*-------------------------------------------------*/
/* =  Recent
/*-------------------------------------------------*/
#recent {
  margin-bottom: 40px !important;
  height: 100%;
}

#recent h1 {
  color: #404040;
  font-size: 16px;
  margin: 0;
  margin-bottom: 18px;
  padding: 0;
  font-weight: bold;
  font-family: Calibri;
}

#recent .left {
  margin-bottom: 20px;
  margin-right: 20px;
  float: left;
  width: 23.4%;
  height: auto;
}

#recent .left:last-child {
  margin-right: 0px;
}

#recent .left:hover img {
  text-decoration: none;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

#recent .left img {
  width: 100%;
  height: auto;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

#recent .left p {
  width: 100%;
  height: auto;
  z-index: 10;
  position: relative;
  left: 0;
  color: #fff;
  font-size: 13px;
  background: #515151;
  padding: 15px 0;
  text-align: center;
  margin: 0;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

#recent .left {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#recent .left:hover p {
  background: #0ad1e5;
}

#up,
#down {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#up {
  top: -40px;
  left: -40px;
  background: url("../images/link.png") center center;
}

#down {
  right: -40px;
  bottom: 0px;
  background: url("../images/zoom+.png") center center;
}

#up:hover,
#down:hover {
  opacity: 0.8;
}

/*-------------------------------------------------*/
/* =  our clients
/*-------------------------------------------------*/
#our_clients .images {
  margin-bottom: 59px;
}

.images span {
  float: left;
  width: 19.8%;
  height: auto;
  border: 1px solid #e1e1e1;
  border-right: none;
  text-align: center;
}

.images span:last-child {
  border-right: 1px solid #e1e1e1;
}

.images span:hover {
  opacity: 0.7;
}

/*-------------------------------------------------*/
/* =  Footer
/*-------------------------------------------------*/
footer {
  border-top: 3px solid #22b158;
  margin-top: -7px;
  background: #3a3a3a url("../images/bg3.png") repeat 0 0;
}

.innerfooter {
  margin: auto;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 10px;
}

.innerfooter article {
  margin-bottom: 30px;
  margin-left: 19px;
}

.innerfooter article:first-child {
  margin-left: 0;
}

.innerfooter article h2 {
  font-size: 15px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #1e9b4e;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-width: 5px;
  text-shadow: 1px 0px 1px #000;
  font-weight: bold;
}

article.about p {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #c2c2c2;
  line-height: 18px;
  text-shadow: 1px 0px 1px #000;
}

article.about a {
  color: #fff;
}

article.about a:hover {
  color: #0ad1e5;
}

.footerbottom {
  max-width: 940px;
  margin: auto;
  border-top: 1px solid #c2c2c2;
  text-align: center;
}

.footerbottom p {
  color: #c2c2c2;
  margin: 15px 0 15px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.block_footer_links li {
  padding: 8px 0px 8px;
  display: block;
  overflow: hidden;
}

.block_footer_links li:first-child {
  padding-top: 0px;
}

.block_footer_links li a {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 16px;
}

.block_footer_links li a:hover {
  color: #0ad1e5;
  text-decoration: none;
}

.ulink1 {
  float: left;
  margin-right: 50px;
}

.ulink2 {
  float: left;
}

/*-------------------------------------------------*/
/* =  Content - home
/*-------------------------------------------------*/
.main1 {
  margin-bottom: 10px;
}

.main1 div.span4 {
  margin-bottom: 30px;
}

.main1 .services h2 {
  padding: 18px 0 18px 16px;
  font-size: 13px;
  color: #2f2f2f;
  border-top: 1px solid #e3e3e3;
  cursor: pointer;
}

.services .active {
  border-bottom: 1px solid #e3e3e3;
  border-left: 3px solid #0ad1e5;
  color: #0ad1e5 !important;
}

.main1 .services h2 img {
  margin-right: 12px;
}

.main1 .services p {
  font-size: 13px;
  color: #535353;
  line-height: 20px;
  margin: 5px 0;
}

.hide {
  display: none;
}

.main1 .testimonials blockquote {
  font-size: 13px;
  color: #4b4b4b;
  margin: 0;
  font-family: Georgia;
  line-height: 20px;
  font-style: italic;
  background: #f7f7f7;
  padding: 16px;
  float: left;
}

.main1 .testimonials blockquote span {
  float: right;
  font-style: normal;
  font-weight: bold;
}

.testimonials li {
  display: none;
}

.testimonials li:first-child {
  display: block;
}

.testimonials ul {
  list-style: none;
}

#inner-content #articles div.span3 > img {
  width: 220px;
  margin-bottom: 15px;
}

.articles img,
.articles1 img {
  width: 32px !important;
  height: 32px !important;
}

.articles h2,
.articles1 h2 {
  margin-bottom: 10px !important;
  line-height: 20px !important;
}

.articles div.span4 {
  text-align: center;
  border: 1px solid #dbdbdb;
  padding-top: 15px;
  margin-bottom: 0px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
}

.articles div.span4:hover {
  border: 1px solid #0ad1e5;
}

.articles div.span4 p {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

/*our team*/
#our-team div.span3:first-child {
  margin-left: 0 !important;
}

#our-team div.span3 {
  margin-bottom: 40px;
}

#member1 {
  margin-left: 0 !important;
}

#our-team a {
  display: inline-block;
  position: relative;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
  transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
}

#our-team a:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #0ad1e5;
}

#our-team a img {
  border: 1px solid #cdcdcd;
  width: 218px;
}

#our-team h4 {
  font-size: 14px;
  font-weight: bold;
  color: #6a6a6a;
  margin-bottom: 3px;
}

#our-team span {
  color: #0ad1e5;
}

#our-team p {
  color: #535353;
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-top: 10px;
}

/*-------------------------------------------------*/
/* =  Blog
/*-------------------------------------------------*/
.cont-head {
  background: #3a3a3a url("../images/bg.png") repeat 0 0;
  height: 64px;
  margin-bottom: 25px;
}

.cont-text h1 {
  float: left;
  font-size: 16px;
  color: #fff;
  text-transform: normal;
  margin-top: 25px;
}

.cont-text p {
  float: right;
  font-size: 11px;
  color: #717171;
  margin-top: 20px;
}

.cont-text p a {
  display: inline-block;
  color: #0ad1e5;
  margin-left: 12px;
}

.cont-text p a span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border: 1px solid #e7e7e7;
  background: #fff url("../images/Blog-head-arrow.png") center center no-repeat;
  margin-right: 12px;
  margin-bottom: -9px;
}

#inner-content .span12 {
  margin-left: 0;
}

.blog-post,
.single-post {
  margin-bottom: 37px;
}

.blog-post img,
.single-post img {
  margin-bottom: 20px;
}

.blog-post .frame-scale-with-grid {
  margin-bottom: 20px;
}

.blog-post p {
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  margin-bottom: 15px;
}

.post-title {
  margin-bottom: 20px;
}

.post-title h1 {
  color: #4f4f4f;
  font-size: 14px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
}

.post-title h1 a {
  color: #4f4f4f;
  transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
}

.post-title h1 a:hover {
  color: #0ad1e5;
}

.post-title p span {
  display: inline-block;
  font-size: 11px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #4F4F4F;
  margin-right: 10px;
}

.post-title p span a {
  color: #0ad1e5;
}

#read-more {
  position: relative;
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 11px;
  display: inline-block;
  background: #0ad1e5;
  border-right: none;
  padding: 10px;
  line-height: 10px;
}

.pagination {
  margin: 0 0 52px;
  padding: 0;
}

.pagination a {
  display: inline-block;
  background: #0ad1e5;
  color: #fff;
  padding: 8px 12px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  margin-right: 3px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

.pagination a:hover {
  background: #646464;
}

.pagination a#prev,
.pagination a#next {
  color: #fff;
  background: #0ad1e5;
  transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  padding: 8px 10px;
}

.pagination a#prev:hover,
.pagination a#next:hover {
  background: #646464;
}

/*-------------------------------------------------*/
/* =  Blog with 2,3,4 colums
/*-------------------------------------------------*/
.blog .span6:nth-child(2n+1) {
  margin-left: 0;
}

.blog .span4:nth-child(3n+1) {
  margin-left: 0;
}

.blog .span4 .post-title p {
  clear: both;
}

.blog .span3:nth-child(4n+1) {
  margin-left: 0;
}

.blog .span3 img {
  margin-bottom: 15px;
}

.blog .span3 .post-title h1 {
  margin-bottom: 20px;
}

.blog .span3 .post-title,
.blog .span4 .post-title {
  margin-bottom: 10px;
}

.blog-vers2 .post-title {
  margin-bottom: 20px;
}

.blog-vers2 .span6 p {
  clear: both;
}

/*-------------------------------------------------*/
/* =  portfolio
/*-------------------------------------------------*/
#portfolio-filter {
  margin-bottom: 17px;
}

#portfolio-filter li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

#portfolio-filter li a {
  display: inline-block;
  padding: 7px 10px;
  background: #0ad1e5;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 13px;
  color: #fff;
  transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -webkit-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
}

#portfolio-filter li a:hover {
  background: #646464;
}

#portfolio-filter li a.active4 {
  background: #646464;
}

ul.portfolio {
  display: none;
}

.portfolio .span6:nth-child(2n+1) {
  margin-left: 0;
}

.portfolio .span4:nth-child(3n+1) {
  margin-left: 0;
}

.portfolio-post {
  margin-bottom: 20px;
}

.portfolio-post-end {
  margin-bottom: 0px;
}

.pdesc {
  font-weight: bold;
}

.col4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 220px;
  margin-right: 20px;
}

.col4:nth-child(4n) {
  margin-right: 0;
}

.col3 {
  width: 300px;
  margin-right: 20px;
  display: inline;
}

.col2 {
  width: 460px;
  margin-right: 20px;
  display: inline-block;
}

.col2:nth-child(2n) {
  margin-right: 0;
}

ul.portfolio {
  width: 103%;
  margin-bottom: 45px;
}

/*-------------------------------------------------------*/
/* Isotope filtering
/*-------------------------------------------------------*/
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope, .isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  transition-property: transform, opacity;
}

/*-------------------------------------------------------*/
/* About
/*-------------------------------------------------------*/
#about .span5 {
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 25px;
}

#about .span5 img {
  width: 100%;
}

#about .span7 h1 {
  margin-bottom: 8px;
}

#about .span7 {
  margin-bottom: 25px;
}

#about .span7 p {
  font-size: 13px;
  color: #7d7d7d;
  line-height: 20px;
}

#about .span6 {
  margin-bottom: 25px;
}

#about .images ul li {
  float: left;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-right: none;
  border-bottom: none;
  width: 32.8%;
}

#about .images ul li:nth-child(3n) {
  border-right: 1px solid #e1e1e1;
}

#about .images ul li:last-child {
  border-right: 1px solid #e1e1e1;
}

#about .images ul li:nth-last-child(-n+3) {
  border-bottom: 1px solid #e1e1e1;
}

.skills h4 {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}

/*-------------------------------------------------------*/
/* sidebar
/*-------------------------------------------------------*/
#sidebar ul li.widget {
  background: url("../images/bg-white.png");
  display: block;
  padding: 10px;
  margin-bottom: 22px;
}

ul.tab-links li {
  float: left;
  background: #f8f8f8;
  padding: 12px 0;
  width: 32.8%;
  text-align: center;
  border: 1px solid #ebebeb;
  border-left: none;
}

ul.tab-links li:first-child {
  border-left: 1px solid #ebebeb;
}

ul.tab-links li a {
  color: #5a5a5a;
}

ul.tab-content li {
  background: #fff;
  display: none;
}

ul.tab-links li.active {
  background: #fff;
  border-bottom: none;
  border-top: 2px solid #0ad1e5;
}

ul.tab-links li.active a {
  color: #0ad1e5;
}

ul.tab-content li:first-child {
  display: block;
}

ul.tab-content li div {
  overflow: hidden;
  padding: 15px 10px;
  border: 1px solid #ebebeb;
  border-top: none;
}

ul.tab-content li div img {
  float: left;
  margin-right: 12px;
}

ul.tab-content li div h3 {
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 7px;
  max-height: 36px;
  overflow: hidden;
}

ul.tab-content li div h3 a {
  color: #828282;
}

ul.tab-content li div span {
  font-size: 10px;
  color: #999999;
}

ul.tab-content li div p {
  font-size: 11px;
  color: #999999;
  line-height: 18px;
}

ul.tab-content li div span img {
  margin-right: 8px;
}

.widget h2 {
  font-size: 13px;
  color: #0ad1e5;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
}

.cat-widget div {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-bottom: none;
  margin: 0;
  width: 99%;
  max-height: 320px;
}

.cat-widget div h3 {
  font-size: 13px;
  color: #2f2f2f;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
}

.cat-widget div img {
  margin-right: 12px;
  margin-top: -5px;
}

.cat-widget div p {
  font-size: 13px;
  color: #535353;
  line-height: 20px;
  padding: 0 17px 15px;
  border-bottom: 1px solid #ebebeb;
}

div.cat-item h3 a {
  color: #404040;
}

div.cat-item h3 a:hover {
  color: #0ad1e5;
}

li.widget p {
  font-size: 12px;
  line-height: 20px;
}

li.tag-cloud-widget a {
  display: inline-block;
  color: #fff;
  padding: 5px;
  background: #666666;
  margin: 0 4px 6px 0;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

li.tag-cloud-widget a:hover {
  background: #0ad1e5;
}

li.subscribe-widget input[type="text"] {
  float: left;
  width: 90%;
  height: 20px;
  font-size: 11px;
  color: #6a6a6a;
  border: 1px solid #d6d6d6;
}

li.subscribe-widget input[type="submit"] {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #0ad1e5;
  width: 25%;
  padding: 0px 0px;
  height: 30px;
  border: none;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

li.subscribe-widget input[type="submit"]:hover {
  opacity: 0.9;
}

li.flicker-widget a {
  display: inline-block;
  width: 30%;
  height: 30%;
  margin-right: 3.4%;
  margin-bottom: 3.4%;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

li.flicker-widget a:hover {
  opacity: 0.8;
}

li.flicker-widget a:nth-child(3n+1) {
  margin-right: 0;
}

li.flicker-widget a:nth-last-child(-n+3) {
  margin-bottom: 0;
}

li.flicker-widget a img {
  width: 100%;
}

li.tag-widget p {
  color: #3c3c3c;
  font-size: 13px;
  line-height: 16px;
  width: 90%;
  float: right;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 15px !important;
}

li.tag-widget div p img {
  margin-left: -30px;
  margin-right: 5px;
}

li.tag-widget div p a {
  color: #357d9d;
}

li.tag-widget div span {
  color: #676767;
}

/*-------------------------------------------------------*/
/* Singlepost
/*-------------------------------------------------------*/
.single-post p {
  line-height: 20px;
  margin-bottom: 18px;
}

.single-post blockquote {
  color: #828282;
  font-size: 16px;
  font-style: italic;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  line-height: 25px;
  padding: 0 24px;
  border-left: 2px solid #619d21;
  border-right: 2px solid #619d21;
  margin-bottom: 20px;
}

.social-media ul li {
  display: inline-block;
  width: 80px;
  margin-right: 5px;
}

#author,
#comment-leave {
  margin-bottom: 57px;
}

#comments {
  margin-top: -18px;
}

#author h1,
#comments h1,
#comment-leave h1 {
  font-weight: bold;
}

ul.member-post li {
  float: left;
  background: #f8f8f8;
  text-align: center;
  border: 1px solid #ebebeb;
  border-left: none;
  border-bottom: none;
  margin-bottom: -1px;
  z-index: 100;
}

ul.member-post li:first-child {
  border-left: 1px solid #ebebeb;
}

ul.member-post li a {
  display: inline-block;
  color: #373737;
  height: 12px;
  padding: 12px 15px;
}

ul.member-post li a img {
  margin-right: 5px;
}

ul.member-post li.active {
  background: #fff;
  border-bottom: 1px #fff;
  border-top: 2px solid #0ad1e5;
}

ul.member-data li {
  min-height: 100px;
  display: none;
}

ul.member-data li:first-child {
  display: block;
}

ul.member-data li {
  padding: 15px;
  border: 1px solid #ebebeb;
}

ul.member-data li img {
  float: left;
  margin-right: 12px;
}

ul.member-data li h4 {
  color: #101110;
  font-size: 14px;
  margin-bottom: 5px;
}

ul.member-data li a {
  display: inline-block;
  color: #0ad1e5;
  font-size: 11px;
  font-style: italic;
  font-family: georgia;
  margin-bottom: 7px;
}

ul.member-data li p,
#comments li div p {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}

#comments ul li {
  clear: both;
  margin-bottom: 50px;
}

#comments ul li.depth1 ul {
  margin-top: 50px;
}

#comments ul li.depth1 ul li {
  margin-left: 12%;
  margin-bottom: 50px;
}

#comments ul li img {
  float: left;
  overflow: hidden;
}

#comments ul li div {
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  padding: 0 20px 20px;
  margin-top: 20px;
  margin-left: 70px;
}

#comments li div h5 {
  color: #101110;
  font-size: 13px;
  margin-bottom: 6px;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
}

#comments li div span {
  display: inline-block;
  color: #7d7d7d;
  font-size: 11px;
  margin-bottom: 7px;
}

#comments li div a {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 11px;
  padding: 5px 7px;
  background: #696969;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

#comments li div a:hover {
  background: #0ad1e5;
}

/*-------------------------------------------------------*/
/* contact
/*-------------------------------------------------------*/
#contact h1 {
  margin-bottom: 10px;
}

#contact p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
}

div#map {
  height: 220px;
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 30px;
}

#contact-form {
  margin-bottom: 58px;
  display: inline-block;
}

#contact input[type="text"],
#contact input[type="email"],
#contact-form2 input[type="text"] {
  float: left;
  border: 1px solid #d8d8d8;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #878787;
  padding: 12px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: block;
  height: 20px;
  width: 28.1%;
  background: rgba(255, 255, 255, 0.2);
}

#contact label,
#contact-form2 label {
  float: left;
  min-width: 36px;
  height: 36px;
  cursor: pointer;
}

#contact textarea,
#contact-form2 textarea {
  clear: both;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  color: #878787;
  width: 96%;
  height: 140px;
}

#contact-form,
#contact-form2 {
  width: 100%;
}

#contact input[type="submit"],
#contact-form2 input[type="submit"] {
  float: left;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-right: 10px;
  font-size: 12px;
  background: #0ad1e5;
  color: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
  padding: 10px 25px 8px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

#contact input[type="reset"],
#contact-form2 input[type="reset"] {
  float: left;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  margin-right: 10px;
  font-size: 12px;
  background: #0ad1e5;
  color: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border: none;
  padding: 10px 25px 8px;
  transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

#contact input[type="submit"]:hover,
#contact-form2 input[type="submit"]:hover {
  background: #646464;
}

#contact input[type="reset"]:hover,
#contact-form2 input[type="reset"]:hover {
  background: #646464;
}

#contact .message,
#contact-form2 .message {
  float: left;
  height: 30px;
  font-size: 12px;
}

#contact .message.error,
#contact-form2 .message.error {
  color: #eb2c06;
}

#contact .message.success,
#contact-form2 .message.success {
  color: #0ad1e5;
}

#contact .span4 p {
  margin-bottom: 15px;
}

#contact .span4 div {
  margin-bottom: 12px;
}

#contact .span4 div p {
  font-size: 12px;
  margin-bottom: 8px;
}

#contact .span4 div img {
  margin-right: 10px;
}

#contact .span4 ul {
  margin-bottom: 40px;
}

#contact .span4 ul li {
  float: left;
  margin-right: 8px;
}

#contact .span4 ul li a {
  display: inline-block;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

#contact .span4 ul li a:hover {
  opacity: 0.8;
}

/*-------------------------------------------------------*/
/* shortcodes
/*-------------------------------------------------------*/
#shortcodes > h1 {
  font-size: 18px;
  color: #0ad1e5;
  padding-bottom: 14px !important;
  margin-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.headings h1,
.headings h2,
.headings h3,
.headings h4,
.headings h5,
.headings h6,
#shortcodes h5 {
  color: #404040;
  font-family: Calibri;
  margin-bottom: 10px;
}

.headings h1 {
  font-size: 30px;
}

.headings h2 {
  font-size: 26px;
}

.headings h3 {
  font-size: 22px;
}

.headings h4 {
  font-size: 18px;
}

.headings h5,
#shortcodes h5 {
  font-size: 16px;
}

.headings h6 {
  font-size: 12px;
}

.headings p,
.text p,
.left-img,
.right-img,
.dropcaps p {
  font-size: 12px;
  line-height: 20px;
}

.headings,
.text {
  margin-bottom: 50px;
}

.left-img {
  margin-bottom: 20px;
}

.right-img {
  margin-bottom: 70px;
}

.left-img img,
.right-img img {
  width: 180px;
  height: 132px;
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
}

#shortcodes
.left-img img {
  float: left;
  margin-right: 15px;
}

.right-img img {
  float: right;
  margin-left: 15px;
}

#shortcodes .icons,
#shortcodes .buttons {
  margin-bottom: 70px;
}

#shortcodes .icons p {
  margin-bottom: 15px;
  color: #7d7d7d;
  font-size: 12px;
}

#shortcodes .icons img {
  margin-right: 12px;
}

.buttons a {
  display: inline-block;
  width: 68px;
  height: 22px;
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: #343434;
  margin-right: 4px;
  margin-bottom: 10px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.buttons a:hover {
  opacity: 0.8;
}

#btn1 {
  background: #fff;
  color: #5b5b5b;
  border: 1px solid #c1c1c1;
}

#btn2 {
  background: #5d8aa8;
  border: 1px solid #5d8aa8;
}

#btn3 {
  background: #b7b7b7;
  color: #323232;
  border: 1px solid #b7b7b7;
}

#btn4 {
  background: #faebd7;
  color: #5b5b5b;
  border: 1px solid #c1c1c1;
}

#btn5 {
  background: #7fffd4;
  color: #5b5b5b;
  border: 1px solid #7fffd4;
}

#btn6 {
  background: #838b8b;
  border: 1px solid #838b8b;
}

#btn7 {
  background: #8b7d6b;
  border: 1px solid #8b7d6b;
}

#btn8 {
  background: #3099de;
  border: 1px solid #3099de;
}

#btn9 {
  background: #a52a2a;
  border: 1px solid #a52a2a;
}

#btn10 {
  background: #deb887;
  color: #333333;
  border: 1px solid #deb887;
}

#btn11 {
  background: #5f9ea0;
  border: 1px solid #5f9ea0;
}

#btn12 {
  background: #66cd00;
  border: 1px solid #66cd00;
}

#btn13 {
  background: #d2691e;
  border: 1px solid #d2691e;
}

#btn14 {
  background: #ee6a50;
  border: 1px solid #ee6a50;
}

#btn15 {
  background: #8b8878;
  border: 1px solid #8b8878;
}

#btn16 {
  background: #cdc8b1;
  color: #323232;
  border: 1px solid #c1c1c1;
}

#btn17 {
  background: #00cdcd;
  border: 1px solid #c1c1c1;
}

#btn18 {
  background: #ffb90f;
  border: 1px solid #ffb90f;
}

#btn19 {
  background: #006400;
  border: 1px solid #006400;
}

#btn20 {
  background: #bdb76b;
  border: 1px solid #bdb76b;
}

#btn21 {
  background: #556b2f;
  border: 1px solid #556b2f;
}

#btn22 {
  background: #7fffd4;
  color: #5b5b5b;
  border: 1px solid #c1c1c1;
}

#btn23 {
  background: #a2cd5a;
  border: 1px solid #a2cd5a;
}

#btn24 {
  background: #ff8c00;
  border: 1px solid #ff8c00;
}

#btn25 {
  background: #bf3eff;
  border: 1px solid #bf3eff;
}

#btn26 {
  background: #e9967a;
  border: 1px solid #e9967a;
}

#btn27 {
  background: #8fbc8f;
  border: 1px solid #8fbc8f;
}

#btn28 {
  background: #483d8b;
  border: 1px solid #483d8b;
}

#btn29 {
  background: #2f4f4f;
  border: 1px solid #2f4f4f;
}

#btn30 {
  background: #97ffff;
  color: #404140;
  border: 1px solid #97ffff;
}

#btn31 {
  background: #ff1493;
  border: 1px solid #ff1493;
}

#btn32 {
  background: #00bfff;
  border: 1px solid #00bfff;
}

#btn33 {
  background: #696969;
  border: 1px solid #696969;
}

#btn34 {
  background: #ee2c2c;
  border: 1px solid #ee2c2c;
}

#btn35 {
  background: #228b22;
  border: 1px solid #228b22;
}

#btn36 {
  background: #dcdcdc;
  color: #3e3f3e;
  border: 1px solid #dcdcdc;
}

#btn37 {
  background: #ffd700;
  color: #3e3f3e;
  border: 1px solid #ffd700;
}

#btn38 {
  background: #8b7500;
  border: 1px solid #8b7500;
}

#btn39 {
  background: #030303;
  border: 1px solid #030303;
}

#btn40 {
  background: #ff69b4;
  border: 1px solid #ff69b4;
}

#btn41 {
  background: #8b3a3a;
  border: 1px solid #8b3a3a;
}

#btn42 {
  background: #7cfc00;
  color: #323332;
  border: 1px solid #7cfc00;
}

#btn43 {
  background: #eedc82;
  color: #393a39;
  border: 1px solid #eedc82;
}

#btn44 {
  background: #ff0f0f;
  border: 1px solid #ff0f0f;
}

#btn45 {
  background: #ffb6c1;
  color: #454645;
  border: 1px solid #ffb6c1;
}

#btn46 {
  background: #8470ff;
  border: 1px solid #8470ff;
}

#btn47 {
  background: #b03060;
  border: 1px solid #b03060;
}

#btn48 {
  background: #191970;
  border: 1px solid #191970;
}

#btn1, #btn2, #btn3, #btn4, #btn5, #btn6, #btn7, #btn8, #btn9, #btn10,
#btn11, #btn12, #btn13, #btn14, #btn15, #btn16, #btn17, #btn18, #btn19, #btn20,
#btn21, #btn22, #btn23, #btn24, #btn25, #btn26, #btn27, #btn28, #btn29, #btn30,
#btn31, #btn32, #btn33, #btn34, #btn35, #btn36, #btn37, #btn38, #btn39, #btn40,
#btn41, #btn42, #btn43, #btn44, #btn45, #btn46, #btn47, #btn48 {
  background-image: -webkit-gradient(linear, center top, center bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
}

.social_icons {
  margin-bottom: 80px;
}

.social_icons a {
  display: inline-block;
  margin-right: 3.5px;
  margin-bottom: 7px;
  transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.social_icons a:hover {
  opacity: 0.8;
}

.social_icons a:last-child {
  margin-right: 0px;
}

.dropcaps span {
  float: left;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  background: #1a8643;
  color: #fff;
  font-weight: bold;
  margin-right: 15px;
}

#blockquote2 {
  color: #7d7d7d;
  font-size: 28px;
  font-family: Georgia;
  font-style: italic;
  line-height: 36px;
  padding: 0 50px;
  margin-bottom: 70px;
  position: relative;
  clear: both;
}

#blockquote2:after {
  content: '"';
  position: absolute;
  right: 0px;
  bottom: -20px;
  font-size: 78px;
  font-family: georgia;
  color: #0ad1e5;
}

#blockquote2:before {
  content: '"';
  position: absolute;
  font-family: georgia;
  left: 0;
  top: 20px;
  font-size: 78px;
  color: #0ad1e5;
}

#shortcodes #map {
  margin-bottom: 85px;
}

#shortcodes #contact {
  margin-bottom: 40px;
}

#shortcodes .serv-widget {
  margin-bottom: 85px;
}

ul.tab-list li {
  float: left;
  background: #357d9d;
  text-align: center;
  margin-right: 1px;
  z-index: 100;
}

ul.tab-list li a {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  height: 12px;
  padding: 12px 15px;
  font-size: 11px;
}

ul.tab-list li a img {
  margin-right: 5px;
}

ul.tab-list li.active {
  background: #f5f5f5;
}

ul.tab-list li.active a {
  color: #454545;
}

ul.tab-cont li {
  min-height: 100px;
  display: none;
}

ul.tab-cont li:first-child {
  display: block;
}

ul.tab-cont li {
  padding: 15px;
  background: #f5f5f5;
}

ul.tab-cont li img {
  float: left;
  margin-right: 12px;
}

ul.tab-cont li p {
  color: #828282;
  line-height: 20px;
}

ul.tab-cont {
  margin-bottom: 85px;
}

#shortcodes #recent {
  margin-bottom: 65px !important;
}

#serv3 {
  margin-bottom: 50px !important;
}

#shortcodes .articles {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 30px !important;
  margin-bottom: 45px !important;
}

#shortcodes #articles {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  margin-bottom: 45px;
}

#shortcodes #our_clients {
  margin-bottom: 80px;
}

/*-------------------------------------------------------*/
/* single post
/*-------------------------------------------------------*/
.single-post .slides img {
  width: 100%;
  height: auto;
  border: none;
  padding: 0;
  margin: 0;
}

.single-post .slides p {
  margin-bottom: 0;
}

/* =================================================================== */
/* Pricing Tables
====================================================================== */
.pricing-table {
  float: left;
  margin: 1px 0 0 1px;
}

.pricing-table h3 {
  font-size: 16px;
  text-align: center;
  color: #fff;
  padding: 6px 0;
  margin: 0;
}

.pricing-table li {
  color: #888;
  background: #fdfdfd;
  text-align: center;
  padding: 11px 0;
  border-bottom: 1px solid #fff;
}

.pricing-table li:nth-child(2n) {
  background: #f4f4f4;
}

.pricing-table h4 {
  padding: 0 0 10px;
}

a.sign-up {
  text-align: center;
  display: block;
  color: #fff;
  padding: 12px 0;
  font-weight: bold;
  font-size: 14px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

a.sign-up:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

/* Table
====================================*/
.pricing-table h3,
.pricing-table h4 {
  color: #fff;
  margin: 0;
}

.pricing-table h4 {
  padding: 15px 0;
}

.price,
.time {
  display: block;
  text-align: center;
  line-height: 24px;
}

.price {
  font-size: 24px;
  font-weight: normal;
}

.time {
  font-size: 12px;
  font-weight: normal;
}

/* Color Schemes
====================================*/
/* Color - 1 */
.pricing-table .color-1 h3, .color-1 .sign-up {
  background-color: #808080;
}

.pricing-table .color-1 h4 {
  background-color: #909090;
}

/* Color - 2 */
.pricing-table .color-2 h3, .color-2 .sign-up {
  background-color: #404040;
}

.pricing-table .color-2 h4 {
  background-color: #4c4c4c;
}

/* Color - 3 */
.pricing-table .color-3 h3, .color-3 .sign-up {
  background-color: #0ad1e5;
}

.pricing-table .color-3 h4 {
  background-color: #0CEAFF;
}

/*-------------------------------------------------------*/
/* slider4 - revolution slider
/*-------------------------------------------------------*/
#slider4 {
  background: #fff url(../images/bg3.png);
  padding: 25px 20px;
  margin-bottom: 20px;
}

#slider4_2 {
  padding: 25px 20px;
  margin-bottom: 20px;
}

.bannercontainer {
  padding: 5px;
  background-color: #fff;
  max-width: 940px;
  position: relative;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  overflow: hidden;
}

.banner {
  width: 100%;
  max-height: 380px;
  position: relative;
  overflow: hidden;
}

.bannercontainer .tp-bullets.tp-thumbs .tp-mask {
  width: 100% !important;
  height: 130px !important;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet {
  position: relative;
  width: 168.5px !important;
  height: 100px !important;
  margin: 15px 15px 15px 0;
}

.bannercontainer .tp-bullets.tp-thumbs img {
  width: 100%;
  height: 100px;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet:before {
  position: absolute;
  content: '';
  border: 5px solid rgba(0, 0, 0, 0.29);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.bannercontainer .tp-bullets.tp-thumbs .bullet.selected:before {
  position: absolute;
  content: '';
  border: 5px solid #0ad1e5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.bannercontainer .big_title {
  background: #1a8643;
  max-width: 455px !important;
  padding: 10px;
  white-space: normal !important;
}

.bannercontainer .big_title h2 {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
}

.bannercontainer .big_black {
  background: rgba(0, 0, 0, 0.75);
  max-width: 455px !important;
  padding: 10px;
  white-space: normal !important;
}

.bannercontainer .big_black h2 {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 20px;
}

.bannercontainer .big_black p {
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: normal;
  max-width: 400px !important;
  line-height: 1.5em;
}

.bannercontainer .big_black {
  background: rgba(0, 0, 0, 0.75);
  max-width: 455px !important;
  padding: 20px;
  white-space: normal !important;
}

.bannercontainer .big_black h2 {
  font-size: 0.45em;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

.bannercontainer .big_black p {
  font-size: 0.35em;
  font-family: 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: normal;
  max-width: 400px !important;
  line-height: 1.5em;
}

.form-horizontal .control-label {
  width: 220px;
}
